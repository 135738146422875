import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { ResponsableComponent } from '../../mainPageDirectory/responsable/responsable.component';
import * as responsable from '../../mainPageDirectory/responsable/responsable.component';
@Component({
  selector: 'app-rapport-page-blagnac',
  templateUrl: './rapport-page.component.html',
  styleUrls: ['./rapport-page.component.scss']
})
export class RapportPageComponentBlagnac implements OnInit {
 camera:string = "";
 probleme:string = "";
 tickets:any = [];
 @Input() cameraForDisplay;
 @Input() date = "";
 @Input() inputTickets;
 @Input() getAllTicketsForMomentTab = [];
 @Input() checkedValue = "nouveau";

 isAuthorized:boolean = true;
 @Input() dateForChart = "";

addCamera(newItem: string) {
    this.cameraForDisplay = newItem;
    console.log("this.camera", this.cameraForDisplay);
    //this.probleme = "";
  }
  addProbleme(newItem: string) {
     this.probleme = newItem;
     //this.camera = "";
     console.log("this.probleme", this.probleme);
   }

   getDate(newDate:string)
   {
   console.log("newDate", newDate);
    this.date = newDate;
   }
  getTickets(allTicketsOutput:string)
     {
     console.log("allTickets", allTicketsOutput);
      this.inputTickets = allTicketsOutput;
  }

  getCheckedValue(checked:string)
  {
  this.checkedValue = checked;
  console.log("checked value : ", this.checkedValue);

  }

  getAllTicketsForMoment(newTab : any)
  {
  setTimeout(() => {
      this.getAllTicketsForMomentTab = newTab;
      });

  }

  getDateValue(newDate:string)
  {
      this.dateForChart = newDate;
  }

  ngOnInit(): void {
  //this.isAuthorized = responsable.authorized
  console.log("authorized", responsable.authorized);
    }
}
