import { Component, OnInit } from '@angular/core';
import { Output, EventEmitter, Input } from '@angular/core';
import { SearchCamAndProbComponentOld } from '../search-cam-and-prob-old/search-cam-and-prob-old.component';

@Component({
  selector: 'app-search-ticket-old',
  templateUrl: './search-ticket-old.component.html',
  styleUrls: ['./search-ticket-old.component.scss']
})


export class SearchTicketComponentOld {
 camera:string = "";
 probleme:string = "";
 responsible:string = "";

@Output() newItemEventCamera = new EventEmitter<string>();
@Output() newItemEventProblem = new EventEmitter<string>();
@Output() newItemEventResponsible = new EventEmitter<string>();
@Output() newItemEventInput = new EventEmitter<string>();
@Output() newAnnulerTout = new EventEmitter<string>();
@Output() newCreateTicketEvent = new EventEmitter<string>();
@Input() poste = "";

 addCamera(newItem: string) {
  console.log("new are console")
    this.camera = newItem;
    this.newItemEventCamera.emit(newItem);
  }
  addProbleme(newItem: string) {
    console.log("add problem is called")
     this.probleme = newItem;
     this.newItemEventProblem.emit(newItem);

   }

   addResponsible(newItem: string){
    console.log("changing Responsible man")
    this.responsible = newItem;
    this.newItemEventResponsible.emit(newItem);
   }

  getOpenInput(newItem: string) {
     this.newItemEventInput.emit(newItem);

   }
  getAnnulerTout(newItem: string) {
     this.newAnnulerTout.emit(newItem);

   }
getCreateTicket(newEvent: string) {
     this.newCreateTicketEvent.emit(newEvent);

   }


  constructor(private camProb:SearchCamAndProbComponentOld) { }


}
