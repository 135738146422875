<div class="container">

  <div class="containerButton">
    <button (click)="sendDisplayValue()" [(ngModel)]="valueButton"  aria-label="Font Style" class="buttonLink">← Retour</button>
    <mat-button-toggle-group [(ngModel)]="valueButton"  aria-label="Font Style">
      <mat-button-toggle  value="TOUS" (click)="getValue('TOUS')">TOUS</mat-button-toggle>
      <mat-button-toggle class="colorSnef" value="SNEF" (click)="getValue('snef')">SNEF</mat-button-toggle>
      <mat-button-toggle class="colorSippro" value="SIPPRO" (click)="getValue('sippro')">SIPPRO</mat-button-toggle>
      <mat-button-toggle class="colorMairie" value="Mairie" (click)="getValue('mairie')">Mairie</mat-button-toggle>
    </mat-button-toggle-group>

  <div class="compteur">
    <p>Toutes les pannes : <span>{{problemTous}}</span></p>
    <p class="colorSippro" >Pannes affectées Sippro : <span>{{problemSippro}}</span></p>
    <p class="colorSnef" >Pannes affectées Snef : <span>{{problemSnef}}</span></p>
    <p class="colorMairie" >Pannes affectées Mairie : <span>{{problemMairie}}</span></p>
  </div>
  </div>
  <div *ngIf="poste != 'operateur'">
  <!-- <button class="buttonLink" (click)="changeRoute()" routerLink="/rapportBlagnac" routerLinkActive="active">Rapports</button>
  <button class="buttonLink" (click)="changeRoute()" routerLink="/oldUrlB" routerLinkActive="active">Pannes Clôturées</button> -->
  </div>
</div>

<img class="image "src="../../../assets/Logo_Blagnac.png">
