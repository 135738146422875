import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router'; // import router from angular router
import { Output, EventEmitter } from '@angular/core';
import { GuardService, GuardServiceAdmin } from '../guard.service';

var token: any;
@Component({
  selector: 'app-page-connexion',
  templateUrl: './page-connexion.component.html',
  styleUrls: ['./page-connexion.component.scss'],
})
export class PageConnexionComponent implements OnInit {
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  constructor(
    private http: HttpClient,
    private route: Router,
    private guard: GuardService,
    private guardAdmin: GuardServiceAdmin,
    private activatedRoute: ActivatedRoute
  ) {}
  url: any;
  msg = '';
  display: any = true;
  pwd: string = '';
  email: string = '';
  rootURL = 'http://44.207.236.32:8000';
  error: string = '';
  problemeServer: string = 'no';
  account: string = '';
  sendConnectionStatus = new EventEmitter();
  @Output() eventSendDisplay = new EventEmitter<string>();

  getAccountName() {
    console.log('lsdshlsdhlsdjkl : ', this.account);
    return this.account;
  }

  connexion() {
    console.log('emailFormControl', this.email);
    console.log('pwd', this.pwd);

    let register = {
      login: this.email,
      pwd: this.pwd,
    };
    const subscription = this.http
      .post('https://sippro-gmao.fr:8443/login', register)
      .subscribe(
        (result) => {
          console.log('result ', result);
          this.display = result;

          console.log('this.accountmain : ', this.account);
          console.log('this.ville : ', this.display.ville);

          if (
            this.display.display == 'admin' ||
            this.display.display == 'refTech' ||
            this.display.display == 'technicien'
          ) {
            console.log('display.admin ', this.display.display);
            this.guard.setAccountValue(this.display.display);
            this.account = this.display.display;
            this.guard.setAuthorizationTrue();
            this.guardAdmin.setAuthorizationTrueAdmin();
            localStorage.setItem('username', this.email);
            localStorage.setItem('pwd', this.pwd);
            localStorage.setItem('role', this.display.display);

            if (this.display.ville == 'Toulouse') {
              this.route.navigate(['/mainToulouse']);
            }
            if (this.display.ville == 'Blagnac') {
              this.route.navigate(['/mainBlagnac']);
            }
            if (this.display.ville == 'Miramas') {
              console.log('on passe par miramas');

              this.route.navigate(['/mainMiramas']);
            }
          } else if (this.display.display == 'operateur') {
            this.guard.setAccountValue(this.display.display);
            this.guard.setAuthorizationTrue();
            //localStorage.setItem('username', this.email);
            //localStorage.setItem('pwd', this.pwd);
            localStorage.setItem('role', this.display.display);
            this.guardAdmin.setAuthorizationFalseAdmin();
            localStorage.setItem('username', this.email);
            localStorage.setItem('pwd', this.pwd);
            localStorage.setItem('role', this.display.display);
            this.account = this.display.display;
            console.log('display.admin ', this.display.display);

            if (this.display.ville == 'Toulouse') {
              this.route.navigate(['/mainOperatorToulouse']);
            }
            if (this.display.ville == 'Blagnac') {
              this.route.navigate(['/mainOperatorBlagnac']);
            }
            if (this.display.ville == 'Miramas') {
              this.route.navigate(['/mainOperatorMiramas']);
            }
          }

          if (this.display.display == '') {
            this.guard.setAuthorizationFalse();
            this.guardAdmin.setAuthorizationFalseAdmin();
            this.error = 'error';
          }

          this.eventSendDisplay.emit(this.display.display);
        },
        (error) => {
          console.log('oops', error);
          this.problemeServer = 'yes';
        }
      );
  }

  ngOnInit() {
    var idConnexion =
      this.activatedRoute.snapshot.queryParamMap.get('idConnexion');
    var pwdConnexion =
      this.activatedRoute.snapshot.queryParamMap.get('pwdConnexion');
    console.log('idConnexion : ', idConnexion);
    console.log('pwdConnexion : ', pwdConnexion);
    if (idConnexion != null && pwdConnexion != null) {
      localStorage.setItem('username', idConnexion);
      localStorage.setItem('pwd', pwdConnexion);
    }
    if (localStorage.getItem('username') != null) {
      this.email = localStorage.getItem('username') || '{}';
      console.log('this.email', this.email);
    }

    if (localStorage.getItem('pwd') != null) {
      this.pwd = localStorage.getItem('pwd') || '{}';
    }
    console.log('this.email', this.email);
    console.log('this.pwd', this.pwd);
    if (this.email != '' && this.pwd != '') {
      this.connexion();
    }
  }
}
