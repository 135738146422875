import { Component, OnInit } from '@angular/core';
import { Output, EventEmitter, Input } from '@angular/core';
import { SearchCamAndProbComponent } from '../search-cam-and-prob/search-cam-and-prob.component';

@Component({
  selector: 'app-search-ticket',
  templateUrl: './search-ticket.component.html',
  styleUrls: ['./search-ticket.component.scss'],
})
export class SearchTicketComponent {
  camera: string = '';
  probleme: string = '';
  status: string = '';
  priority: string = '';

  @Output() newItemEventCamera = new EventEmitter<string>();
  @Output() newItemEventProblem = new EventEmitter<string>();
  @Output() newItemEventStatus = new EventEmitter<string>();
  @Output() newItemEventPriority = new EventEmitter<string>();
  @Output() newItemEventInput = new EventEmitter<string>();
  @Output() newAnnulerTout = new EventEmitter<string>();
  @Output() newCreateTicketEvent = new EventEmitter<string>();
  @Input() poste = '';

  addCamera(newItem: string) {
    console.log('new are console');
    this.camera = newItem;
    this.newItemEventCamera.emit(newItem);
  }
  addProbleme(newItem: string) {
    console.log('add problem is called');
    this.probleme = newItem;
    this.newItemEventProblem.emit(newItem);
  }

  addStatus(newItem: string) {
    console.log('changing status man');
    this.status = newItem;
    this.newItemEventStatus.emit(newItem);
  }

  addPriority(newItem: string) {
    console.log('changing priority from search ticket component');
    this.priority = newItem;
    this.newItemEventPriority.emit(newItem);
  }

  getOpenInput(newItem: string) {
    this.newItemEventInput.emit(newItem);
  }
  getAnnulerTout(newItem: string) {
    this.newAnnulerTout.emit(newItem);
  }
  getCreateTicket(newEvent: string) {
    this.newCreateTicketEvent.emit(newEvent);
  }

  constructor(private camProb: SearchCamAndProbComponent) {}
}
