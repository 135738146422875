<div *ngIf="poste != 'operateur'">
  <div class="container">
    <div
      class="warningText"
      *ngIf="testif == true && colorText == 'orange'"
      style="color: orange"
    >
      ⚠ Attention une panne existe deja avec cette caméra ⚠
    </div>
    <div
      class="warningText"
      *ngIf="testif == true && colorText == 'red'"
      style="color: red"
    >
      ⚠ Attention une panne existe déjà avec cet équipement et ce problème.
      Création de ticket impossible. Cliquez sur annuler. ⚠
    </div>

    <mat-card id="allTheCard" class="zone-card">
      <div>
        <mat-card-title id="titleWarning" class="title"
          >Panne active</mat-card-title
        >
      </div>
      <button (click)="downloadCSV()" class="csvButton">Générer CSV</button>
      <div id="idTexte" class="champTexte">
        <p class="texte">Equip. concerné</p>
        <p class="texte">Type de panne</p>
        <p class="texte">Clé de la panne</p>
        <p class="texte">Statut panne</p>
        <p class="texte">date de création</p>
        <p class="texte">Temps Ecoulé</p>
        <p class="texte">Responsable</p>
      </div>
      <div *ngIf="problemeServer == 'yes'" class="probleme">
        Impossible de récupérer les données et d'afficher les pannes
        <br />{{ causePanne }}
      </div>
      <div class="allTickets">
        <mat-card
          id="matCardColor"
          class="ticketMatCard"
          *ngFor="let ticket of tickets"
        >
          <div id="popup2{{ ticket.compteur }}">
            <button
              mat-raised-button
              (click)="closePopup2(ticket.compteur)"
              class="buttonClose"
            >
              X
            </button>

            <div class="nom"></div>
            <p class="cameraTexte">caméra concernée : {{ ticket.camera }}</p>
            <p class="panneTexte">Type de panne : {{ ticket.probleme }}</p>
            <p class="statutTexte">Statut du ticket : {{ ticket.status }}</p>
            <p
              class="statutTexte1"
              [ngStyle]="
                ticket.ResponsableTimeelapsed == 'NA'
                  ? { 'background-color': 'white' }
                  : ticket.timeOfFirstResponsebreach
                  ? { 'background-color': 'red' }
                  : { 'background-color': 'green' }
              "
            >
              Temps de prise en compte : {{ ticket.timeOfFirstResponseelapsed }}
            </p>
            <p class="nomTexte">Nom du ticket : {{ ticket.key }}</p>
            <p class="ResponsableTexte2">
              Responsable du ticket : {{ ticket.reporter }}
            </p>
            <p
              class="ResponsableTexte3"
              [ngStyle]="
                ticket.ResponsableTimeelapsed == 'NA'
                  ? { 'background-color': 'white' }
                  : ticket.ResponsableTimebreach
                  ? { 'background-color': 'red' }
                  : { 'background-color': 'green' }
              "
            >
              Temps Ecoulé : {{ ticket.ResponsableTimeelapsed }}
            </p>

            <div class="description">
              <p>Description</p>
              <p class="texteDescritpion">{{ ticket.description }}</p>
            </div>

            <p style="color: white; font-size: 20px; margin: 3px">
              Dernière Note
            </p>
            <div style="height: 300px; margin: 2px; overflow-y: scroll">
              <div style="padding: 5px" *ngFor="let comment of ticket.comments">
                <div class="note">
                  <div style="padding: 5px">
                    <div style="display: flex">
                      <p style="margin-right: 5px">
                        {{ comment.author.displayName }}
                      </p>
                      <p style="margin-right: 5px">{{ comment.created }}</p>
                    </div>
                    <div>
                      <p>{{ comment.body }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            (click)="displayDetails2(ticket.compteur)"
            id="enableBlur{{ ticket.compteur }}"
            class="champTexte2"
          >
            <p
              class="texte2"
              [ngStyle]="
                ticket.prority == 'Haute'
                  ? { 'background-color': 'white' }
                  : { 'background-color': '#FF474C' }
              "
            >
              {{ ticket.camera }}
            </p>
            <p
              class="texte2"
              [ngStyle]="
                ticket.prority == 'Haute'
                  ? { 'background-color': 'white' }
                  : { 'background-color': '#FF474C' }
              "
            >
              {{ ticket.probleme }}
            </p>
            <p
              class="texte2"
              [ngStyle]="
                ticket.prority == 'Haute'
                  ? { 'background-color': 'white' }
                  : { 'background-color': '#FF474C' }
              "
            >
              {{ ticket.key }}
            </p>
            <p
              class="texte2"
              [ngStyle]="
                ticket.prority == 'Haute'
                  ? { 'background-color': 'white' }
                  : { 'background-color': '#FF474C' }
              "
            >
              {{ ticket.status }}
            </p>
            <p
              class="texte2"
              [ngStyle]="
                ticket.prority == 'Haute'
                  ? { 'background-color': 'white' }
                  : { 'background-color': '#FF474C' }
              "
            >
              {{ ticket.date }}
            </p>
            <p
              class="texte2"
              [ngStyle]="
                ticket.ResponsableTimeelapsed == 'NA'
                  ? { 'background-color': 'white' }
                  : ticket.ResponsableTimebreach
                  ? { 'background-color': 'red' }
                  : { 'background-color': 'green' }
              "
            >
              {{ ticket.ResponsableTimeelapsed }}
            </p>
            <p
              class="texte2"
              [ngStyle]="
                ticket.prority == 'Haute'
                  ? { 'background-color': 'white' }
                  : { 'background-color': '#FF474C' }
              "
            >
              {{ ticket.reporter }}
            </p>
          </div>
        </mat-card>
      </div>
    </mat-card>
  </div>
</div>

<div *ngIf="poste == 'operateur'">
  <div class="container1">
    <div
      class="warningText"
      *ngIf="testif == true && colorText == 'orange'"
      style="color: orange"
    >
      ⚠ Attention une panne existe deja avec cette caméra ⚠
    </div>
    <div
      class="warningText"
      *ngIf="testif == true && colorText == 'red'"
      style="color: red"
    >
      ⚠ Attention une panne existe déjà avec cet équipement et ce problème.
      Création de ticket impossible. Cliquez sur annuler. ⚠
    </div>

    <mat-card id="allTheCard" class="zone-card">
      <div>
        <mat-card-title id="titleWarning" class="title"
          >Panne active</mat-card-title
        >
      </div>
      <button (click)="downloadCSV()" class="csvButton">Générer CSV</button>
      <div id="idTexte" class="champTexte">
        <p class="texte">Equip. concerné</p>
        <p class="texte">Type de panne</p>
        <p class="texte">Clé de la panne</p>
        <p class="texte">Statut panne</p>
        <p class="texte">date de création</p>
        <p class="texte">Temps Ecoulé</p>
        <p class="texte">Responsable</p>
      </div>
      <div *ngIf="problemeServer == 'yes'" class="probleme">
        Impossible de récupérer les données et d'afficher les pannes
        <br />{{ causePanne }}
      </div>
      <div class="allTickets">
        <mat-card
          id="matCardColor"
          class="ticketMatCard"
          *ngFor="let ticket of tickets"
        >
          <div id="popup2{{ ticket.compteur }}">
            <button
              mat-raised-button
              (click)="closePopup2(ticket.compteur)"
              class="buttonClose"
            >
              X
            </button>

            <div class="nom"></div>
            <p class="cameraTexte">caméra concernée : {{ ticket.camera }}</p>
            <p class="panneTexte">Type de panne : {{ ticket.probleme }}</p>
            <p class="statutTexte">Statut du ticket : {{ ticket.status }}</p>
            <p
              class="statutTexte1"
              [ngStyle]="
                ticket.ResponsableTimeelapsed == 'NA'
                  ? { 'background-color': 'white' }
                  : ticket.timeOfFirstResponsebreach
                  ? { 'background-color': 'red' }
                  : { 'background-color': 'green' }
              "
            >
              Temps de prise en compte : {{ ticket.timeOfFirstResponseelapsed }}
            </p>
            <p class="nomTexte">Nom du ticket : {{ ticket.key }}</p>
            <p class="ResponsableTexte2">
              Responsable du ticket : {{ ticket.reporter }}
            </p>
            <p
              class="ResponsableTexte3"
              [ngStyle]="
                ticket.ResponsableTimeelapsed == 'NA'
                  ? { 'background-color': 'white' }
                  : ticket.ResponsableTimebreach
                  ? { 'background-color': 'red' }
                  : { 'background-color': 'green' }
              "
            >
              Temps Ecoulé : {{ ticket.ResponsableTimeelapsed }}
            </p>

            <div class="description">
              <p>Description</p>
              <p class="texteDescritpion">{{ ticket.description }}</p>
            </div>

            <p style="color: white; font-size: 20px; margin: 3px">
              Dernière Note
            </p>
            <div style="height: 300px; margin: 2px; overflow-y: scroll">
              <div style="padding: 5px" *ngFor="let comment of ticket.comments">
                <div class="note">
                  <div style="padding: 5px">
                    <div style="display: flex">
                      <p style="margin-right: 5px">
                        {{ comment.author.displayName }}
                      </p>
                      <p style="margin-right: 5px">{{ comment.created }}</p>
                    </div>
                    <div>
                      <p>{{ comment.body }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            (click)="displayDetails2(ticket.compteur)"
            id="enableBlur{{ ticket.compteur }}"
            class="champTexte3"
          >
            <p
              class="texte3"
              [ngStyle]="
                ticket.prority == 'Haute'
                  ? { 'background-color': 'white' }
                  : { 'background-color': '#FF474C' }
              "
            >
              {{ ticket.camera }}
            </p>
            <p
              class="texte3"
              [ngStyle]="
                ticket.prority == 'Haute'
                  ? { 'background-color': 'white' }
                  : { 'background-color': '#FF474C' }
              "
            >
              {{ ticket.probleme }}
            </p>
            <p
              class="texte3"
              [ngStyle]="
                ticket.prority == 'Haute'
                  ? { 'background-color': 'white' }
                  : { 'background-color': '#FF474C' }
              "
            >
              {{ ticket.key }}
            </p>
            <p
              class="texte3"
              [ngStyle]="
                ticket.prority == 'Haute'
                  ? { 'background-color': 'white' }
                  : { 'background-color': '#FF474C' }
              "
            >
              {{ ticket.status }}
            </p>
            <p
              class="texte3"
              [ngStyle]="
                ticket.prority == 'Haute'
                  ? { 'background-color': 'white' }
                  : { 'background-color': '#FF474C' }
              "
            >
              {{ ticket.date }}
            </p>
            <p
              class="texte3"
              [ngStyle]="
                ticket.ResponsableTimeelapsed == 'NA'
                  ? { 'background-color': 'white' }
                  : ticket.ResponsableTimebreach
                  ? { 'background-color': 'red' }
                  : { 'background-color': 'green' }
              "
            >
              {{ ticket.ResponsableTimeelapsed }}
            </p>
            <p
              class="texte3"
              [ngStyle]="
                ticket.prority == 'Haute'
                  ? { 'background-color': 'white' }
                  : { 'background-color': '#FF474C' }
              "
            >
              {{ ticket.reporter }}
            </p>
          </div>
        </mat-card>
      </div>
    </mat-card>
  </div>
</div>
