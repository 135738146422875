import { Component, Input, OnInit } from '@angular/core';
import {Router} from '@angular/router'; // import router from angular router
import { DataService } from './data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
@Input() item = '';
@Input() annulerTout = '';
@Input() createTicket = '';

responsable:string = "TOUS";
camera:string="";
problem:string="";
problemTous:string = "";
problemSippro:string = "";
problemMaintenanceZefil:string = "";
problemSnef:string = "";
problemMairie:string = "";
allResponsable:string = "";
title = 'JiraFrontProject';
display:boolean = true;
constructor(private route:Router, private list: DataService){}
   addItem(newItem: string) {
      this.responsable = newItem;
    }

    addCamera(newItem: string) {
       this.camera = newItem;
     }
     addProbleme(newItem: string) {
        this.problem = newItem;

      }

    eventInput(newItem: string)
    {
    this.allResponsable = this.generateRandomString(4);
    }

   getProblemSippro(newItem: string) {
    this.problemSippro = newItem;
  }

   getProblemSnef(newItem: string) {
      this.problemSnef = newItem;
    }

  getProblemMaintenanceZefil(newItem:string) {
    this.problemMaintenanceZefil = newItem;
  }

    getProblemMairie(newItem: string) {
       this.problemMairie = newItem;
     }
    getProblemTous(newItem: string) {
       this.problemTous = newItem;

}
   displayValue(newItem: string) {
    this.display = JSON.parse(newItem);
  }

  eventAnnuler(newEvent:string)
  {
  this.annulerTout = this.generateRandomString(4);
  }

  eventCreateTickets(newEvent:string)
  {
  this.createTicket = this.generateRandomString(4);
  }
public generateRandomString(length:number)
{
    var result = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() *
    charactersLength));
   }
  return result;
}

}
