import { Component, OnInit, ViewChild } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { DisplayTicketOldComponentBlagnac } from '../display-ticket-old/display-ticket-old.component';

@Component({
  selector: 'app-search-cam-and-prob-blagnac-old',
  templateUrl: './search-cam-and-prob-old.component.html',
  styleUrls: ['./search-cam-and-prob-old.component.scss'],
})
export class SearchCamAndProbComponentBlagnacOld implements OnInit {
  camera: string = '';
  probleme: string = '';
  responsible: string = '';
  listCamera: Array<string> = [];
  listProbleme: Array<string> = [
    'Problème réseau',
    'Problème système',
    'Problème matériel',
    'Problème de manipulation',
    'Problème de manipulation',
    'Problème EP Mairie',
    'Page Web Inaccessible',
    'Autre',
  ];
  listResponsible: Array<string> = ['snef', 'sippro', 'mairie'];
  panneEtCamera: any = [];
  problemeServer: string = 'no;';
  causePanne: string = '';
  missData: string = '';
  errorValue: any = [];
  finalError: string = '';
  @ViewChild('autocomplete', { static: true }) public autocomplete: any;
  @ViewChild('autocomplete2', { static: true }) public autocomplete2: any;
  @ViewChild('autocomplete3', { static: true }) public autocomplete3: any;
  getItemAndPanne() {
    this.http
      .get('https://sippro-gmao.fr:8443/displayFieldsValuesBlagnac')
      .subscribe(
        (data) => {
          console.log('I am getting horrible data here', data);
          this.panneEtCamera = data;
          this.listCamera = this.panneEtCamera.camera;
          // this.listProbleme = this.panneEtCamera.panne;
        },
        (error) => {
          this.problemeServer = 'yes';
          this.causePanne = error.message;
        }
      );
  }

  constructor(
    private http: HttpClient,
    private displayComponent: DisplayTicketOldComponentBlagnac
  ) {}
  @Output() eventOpenInput = new EventEmitter<string>();
  @Output() eventAnnuler = new EventEmitter<string>();
  @Output() eventCreateTicket = new EventEmitter<string>();

  annulerTout() {
    this.eventAnnuler.emit('Annuler');
    this.finalError = '';
  }
  openInput() {
    this.autocomplete.toggle(true);
    this.eventOpenInput.emit('TOUS');
    this.finalError = '';
  }

  openInput2() {
    this.autocomplete2.toggle(true);
    this.eventOpenInput.emit('TOUS');
    this.finalError = '';
  }

  openInput3() {
    this.autocomplete3.toggle(true);
    this.eventOpenInput.emit('TOUS');
    this.finalError = '';
  }

  @Output() newItemEventCamera = new EventEmitter<string>();
  @Output() newItemEventProblem = new EventEmitter<string>();
  @Output() newItemEventResponsible = new EventEmitter<string>();
  @Output() creationTickets = new EventEmitter<string>();

  async addNewItemCamera() {
    this.missData = '';
    this.finalError = '';
    this.camera = this.autocomplete.value;
    this.probleme = this.autocomplete2.value;
    if (this.camera == '' && this.probleme == '') {
      this.missData = 'toute';
    } else {
      if (this.camera == '') {
        this.missData = 'camera';
      }
      if (this.probleme == '') {
        this.missData = 'probleme';
      }
    }

    console.log('this.camera', this.camera);
    console.log('this.probleme', this.probleme);
    let data = {
      camera: this.camera,
      probleme: this.probleme,
      description: this.descriptionName,
    };

    if (this.missData == '') {
      //this.annulerTicket()
      let subscription = this.http
        .post('https://sippro-gmao.fr:8443/dataBlagnac', data)
        .subscribe(
          (result) => {
            console.log('result ', result);
            this.errorValue = result;
            console.log('test', this.errorValue.erreur);
            const words = this.errorValue.erreur.split('{');
            this.finalError = words[2];
            console.log(words[3]);
          },
          (error) => {
            this.problemeServer = 'yes';
            console.log('error : ', error);
            this.causePanne = error.message;
          }
        );

      let loading2 = document.getElementById('loading-mask2');
      if (this.missData == '') {
        loading2!.style.display = 'block';
      }

      this.annulerTicket();
      console.log('on est la');
      await this.sleep(7000).then(() => {
        console.log('après sleep');
        //loading2!.style.display = "none";

        let details = document.getElementById('popupCreation');

        //this.newItemEventCamera.emit("");
        //this.newItemEventProblem.emit("");
      });

      await this.sleep(1000).then(() => {
        let newTicket = document.getElementById('newTicket');
        //newTicket!.style.display = "block";
        this.missData = '';
        // this.reloadCurrentPage();

        //this.problemName="";
        //this.cameraName="";
        //this.displayComponent.getValueTicketFilterAfterTest();

        loading2!.style.display = 'none';
        let details = document.getElementById('popupCreation');
        subscription.unsubscribe();

        //this.newItemEventCamera.emit("");
        //this.newItemEventProblem.emit("");
      });

      //this.eventCreateTicket.emit("eventCreateTicket");
    }
  }

  reloadCurrentPage() {
    window.location.reload();
  }

  onChangeCamera(value: string) {
    this.newItemEventCamera.emit(value);
    this.missData = '';
  }

  onChangeProblem(value: string) {
    this.newItemEventProblem.emit(value);
    this.missData = '';
  }

  onChangeResponsible(value: string) {
    this.newItemEventResponsible.emit(value);
    this.missData = '';
  }

  descriptionName: string = '';
  problemName: string = '';
  cameraName: string = '';
  responsibleName: string = '';
  annulerTicket() {
    this.newItemEventCamera.emit('');
    this.newItemEventProblem.emit('');
    this.newItemEventResponsible.emit('');
    this.descriptionName = '';
    this.problemName = '';
    this.cameraName = '';
    this.responsibleName = '';
  }

  reinitialiseFilter() {
    console.log('boubouboub');
    //this.camProb.annulerTicket();
    let newTicket = document.getElementById('newTicket');
    //this.annulerTicket()
    newTicket!.style.display = 'none';
  }

  justeAnnulerErreur() {
    this.missData = '';
  }

  public ngOnInit(): void {
    this.getItemAndPanne();
  }
  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
