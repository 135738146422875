import { Component, Input, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import {Router} from '@angular/router'; // import router from angular router
import { DataBlagnacService } from '../../../data-blagnac.service';

import * as header from '../../rapportPageDirectory/header-rapport/header-rapport.component';
import { Output, EventEmitter} from '@angular/core';


@Component({
  selector: 'app-main-page-miramas',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss']
})
export class MainPageComponentMiramas implements OnInit {
@Input() item = '';
@Input() annulerTout = '';
@Input() createTicket = '';


responsable:string = "TOUS";
camera:string="";
problem:string="";
problemTous:string = "";
problemSippro:string = "";
problemBBTP:string = "";
problemMairie:string = "";
allResponsable:string = "";
title = 'JiraFrontProject';
display:string = "";
constructor(private route:Router,  private list: DataBlagnacService)
{

}



   addItem(newItem: string) {
      //console.log("newItem" , newItem);
      this.responsable = newItem;
    }

    addCamera(newItem: string) {
       this.camera = newItem;
       //console.log("camera : ", newItem);
     }
     addProbleme(newItem: string) {
        this.problem = newItem;
        //console.log("probleme : ", newItem);

      }

    eventInput(newItem: string)
    {
    this.allResponsable = this.generateRandomString(4);
    }

   getProblemSippro(newItem: string) {
    //console.log("getProblemSippro", newItem);
    this.problemSippro = newItem;
  }

   getProblemBBTP(newItem: string) {
     //console.log("getProblemSnef", newItem);
      this.problemBBTP = newItem;
    }

    getProblemMairie(newItem: string) {
       //console.log("getProblemMairie", newItem);
       this.problemMairie = newItem;
     }
    getProblemTous(newItem: string) {
       //console.log("getProblemTous", newItem);
       this.problemTous = newItem;

}


  eventAnnuler(newEvent:string)
  {
  this.annulerTout = this.generateRandomString(4);
  }

  eventCreateTickets(newEvent:string)
  {
  this.createTicket = this.generateRandomString(4);
  }
public generateRandomString(length:number)
{
    var result = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() *
    charactersLength));
   }
  return result;
}
ngOnInit()
{


}


}
