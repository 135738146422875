<div class="bandeauTitre">
  <div>
  <img class="image "src="../../../assets/LogoBlancSippro.png" alt="Logo Toulouse" style="width:12%; margin-top:-4.5vh;">
  </div>

    <h1>Espace GMAO Blagnac</h1>

  <h3>compte : {{accountName}}</h3>

    <button class="buttonDeconnexion" (click)="test()">Deconnexion</button>

</div>
