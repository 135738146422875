<div class="container">
  <div class="containerButton">
    <mat-button-toggle-group [(ngModel)]="valueButton" aria-label="Font Style">
      <mat-button-toggle value="TOUS" (click)="getValue('TOUS')"
        >TOUS</mat-button-toggle
      >
      <mat-button-toggle value="SNEF" (click)="getValue('snef')"
        >SNEF</mat-button-toggle
      >
      <mat-button-toggle value="SIPPRO" (click)="getValue('sippro')"
        >SIPPRO</mat-button-toggle
      >
      <mat-button-toggle
        value="Mairie"
        (click)="getValue('dep-depannage@mairie-toulouse.fr')"
        >DEP</mat-button-toggle
      >
      <mat-button-toggle
        value="Maintenance Zefil"
        (click)="getValue('Maintenance Zefil')"
        >Maintenance Zefil</mat-button-toggle
      >
    </mat-button-toggle-group>

    <div class="compteur">
      <p>
        Toutes les pannes : <span>{{ problemTous }}</span>
      </p>
      <p>
        Pannes affectées Sippro : <span>{{ problemSippro }}</span>
      </p>
      <p>
        Pannes affectées Snef : <span>{{ problemSnef }}</span>
      </p>
      <p>
        Pannes affectées DEP : <span>{{ problemMairie }}</span>
      </p>
      <p>
        Pannes affectées Maintenance Zefil :
        <span>{{ problemMaintenanceZefil }}</span>
      </p>
    </div>
  </div>
  <div>
    <div style="display: flex; flex-direction: row">
      <button class="buttonLink" (click)="gotoGmao()" routerLinkActive="active">
        Rapports
      </button>
      <button
        class="buttonLink"
        (click)="changeRoute()"
        routerLink="/oldUrl"
        routerLinkActive="active"
      >
        Pannes Clôturées
      </button>
      <button
        class="buttonLink"
        (click)="gotoAnomalies()"
        routerLinkActive="active"
      >
        Anomalies
      </button>
    </div>
  </div>
</div>
<br />
<img class="image" src="../../../assets/Logo_Toulouse.png" />
