<!--<div *ngIf="poste == 'operateur'">

<div class="container1">

<mat-card class="zone-card"  >

  <mat-card-header >
    <mat-card-title class="title" >Filtres / Création d'une panne</mat-card-title>

  </mat-card-header>
  <div class="searchCamAndProb">
    <app-search-cam-and-prob-blagnac (newItemEventCamera)="addCamera($event)" (newItemEventProblem)="addProbleme($event)" (eventOpenInput)="getOpenInput($event)" (eventAnnuler)="getAnnulerTout($event)" (eventCreateTicket)="getCreateTicket($event)"></app-search-cam-and-prob-blagnac>
  </div>

</mat-card>
  </div>
</div>-->

<div>
  <div class="container">
    <mat-card class="zone-card">
      <mat-card-header>
        <mat-card-title class="title">Filtres de liste</mat-card-title>
      </mat-card-header>
      <div class="searchCamAndProb">
        <app-search-cam-and-prob-blagnac-old
          (newItemEventCamera)="addCamera($event)"
          (newItemEventProblem)="addProbleme($event)"
          (newItemEventResponsible)="addResponsible($event)"
          (eventOpenInput)="getOpenInput($event)"
          (eventAnnuler)="getAnnulerTout($event)"
          (eventCreateTicket)="getCreateTicket($event)"
        ></app-search-cam-and-prob-blagnac-old>
      </div>
    </mat-card>
  </div>
</div>
