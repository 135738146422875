<div class="mainContainer">
<div class="container">

  <p>Camera Sélectionnée : {{camera}}</p>
  <p>Panne Sélectionnée : {{panne}}</p>
  <div class="compteur">

  <p *ngIf="horsLigne != 0" >Hors Ligne : {{horsLigne}}</p>
  <p *ngIf="imageDegrade != 0" >Image dégradée : {{imageDegrade}}</p>
  <p *ngIf="defautEnregistrement != 0" >Défaut enregistrement : {{defautEnregistrement}}</p>
  <p *ngIf="nonManipulable != 0" >Non manipulable : {{nonManipulable}}</p>
  <p *ngIf="affichageTuile != 0" >Défaut affichage Tuile : {{affichageTuile}}</p>
  <p *ngIf="affichageMur != 0" >Affichage mur d’image : {{affichageMur}}</p>
  <p *ngIf="vandalisme != 0" >Caméras vandalisées : {{vandalisme}}</p>
  <p *ngIf="vibration != 0" >Capteur Vibration : {{vibration}}</p>
  <p *ngIf="sonore != 0" >Défaut Haut-parleur : {{sonore}}</p>
  <p *ngIf="poste != 0" >Défaut poste opérateur : {{poste}}</p>
  <p *ngIf="intrusion != 0" >alarme local technique : {{intrusion}}</p>
  <p *ngIf="cartographie != 0" >Cartographie : {{cartographie}}</p>
  <p *ngIf="allNew != 0" >Total des pannes : {{allNew}}</p>


    </div>

  <button class="buttonLink" *ngIf="activateButton == true" (click)="displayChart()">Mode Distribution</button>
  <button class="buttonLink" *ngIf="activateButton == false" (click)="removeChart()">Mode Rapport</button>
  <button class="buttonLink" *ngIf="activateSla == true" (click)="addSla()" >Activer Sla</button>
  <button class="buttonLink" *ngIf="activateSla == false" (click)="removeSla()">Mode Rapport</button>

  <button class="buttonLink" *ngIf="activateList == true" (click)="displayInstallation()">Liste Caméra</button>
  <button class="buttonLink" *ngIf="activateList == false" (click)="removeInstallation()">Enlever la liste</button>
</div>

<div id="container2"></div>
<div id="container3"></div>

</div>
<img class="image "src="../../assets/Logo_Blagnac.png" alt="Logo Blagnac">
