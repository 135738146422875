import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Output, EventEmitter, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import { DataMiramasService } from '../../../data-miramas.service';

declare var require: any;
const More = require('highcharts/highcharts-more');
More(Highcharts);

const Exporting = require('highcharts/modules/exporting');
Exporting(Highcharts);

const ExportData = require('highcharts/modules/export-data');
ExportData(Highcharts);

const Accessibility = require('highcharts/modules/accessibility');
Accessibility(Highcharts);


@Component({
  selector: 'app-display-compteur-miramas',
  templateUrl: './display-compteur.component.html',
  styleUrls: ['./display-compteur.component.scss']
})
export class DisplayCompteurComponentMiramas implements OnInit {
tickets:any = [];
ticketsCameraForChart:any = [];
ticketsChanged:any = [];
@Input() camera = "";
@Input() panne = "";
@Input() checkedValue = "nouveau";
@Input() dateForChart = "";
@Output() allTickets = new EventEmitter<any>();
@Input() getAllTicketsForMomentTab = []
activateSla:boolean = true;

options: any = {};

horsLigne:number = 0;
imageDegrade:number = 0;
defautEnregistrement:number = 0;
nonManipulable:number = 0;
affichageTuile:number = 0;
affichageMur:number = 0
vandalisme:number = 0;
vibration:number = 0;
cartographie:number = 0;
sonore:number = 0
poste:number = 0;
intrusion:number = 0;
allNew:number = 0;
nonAssigne:number = 0;

autre:number = 0;
pbReseau:number = 0;
pbSysteme:number = 0;
pbManipulation:number = 0;
pbMateriel:number = 0
pbEPMairie:number = 0;
aucunePanne:number = 0;
allOld:number = 0;
activateButton:boolean = true;
private _serviceSubscription;



   ngOnChanges() {
   console.log("on a tout les tickets du moment", this.getAllTicketsForMomentTab);
    console.log("dateForChart", this.dateForChart);
       this.getCompteur(this.camera);

     }

   getCompteur(camera:string)
   {
   if(this.camera == "" || this.camera == undefined)
   {
      console.log("on rentre dans les caméras");
      this.ticketsChanged = this.getAllTicketsForMomentTab;
        if(this.panne == "" || this.panne == undefined)
        {
        }
        else
        {
        if(this.checkedValue == "nouveau")
         {
            this.ticketsChanged = this.getAllTicketsForMomentTab.filter((ticket: any) => ticket.probleme == this.panne);
         }
         else
         {
            this.ticketsChanged = this.getAllTicketsForMomentTab.filter((ticket: any) => ticket.problemeOld == this.panne);
         }
        }
   }
   else
   {
   this.ticketsChanged = this.getAllTicketsForMomentTab.filter((ticket: any) => ticket.camera == this.camera);
   if(this.panne == "" || this.panne == undefined)
           {
           }
           else
           {
           if(this.checkedValue == "nouveau")
                    {
                       this.ticketsChanged = this.getAllTicketsForMomentTab.filter((ticket: any) => ticket.probleme == this.panne);
                    }
                    else
                    {
                       this.ticketsChanged = this.getAllTicketsForMomentTab.filter((ticket: any) => ticket.problemeOld == this.panne);
                    }
           }
   }

   if(this.camera == undefined)
   {
    this.camera = "";
   }
   console.log("this.ticketsChanged", this.ticketsChanged);
    /*this.autre = 0;
    this.pbReseau = 0;
    this.pbSysteme = 0;
    this.pbManipulation = 0;
    this.pbMateriel = 0;
    this.pbEPMairie = 0;
    this.aucunePanne = 0;*/

   if(this.checkedValue == "nouveau")
   {

   this.autre = 0;
   this.pbReseau = 0;
   this.pbSysteme = 0;
   this.pbManipulation = 0;
   this.pbMateriel = 0
   this.pbEPMairie = 0;
   this.aucunePanne = 0;
   this.allOld = 0;

   this.horsLigne = this.ticketsChanged.filter((ticket: any) => ticket.probleme == "Hors ligne").length;
   this.imageDegrade = this.ticketsChanged.filter((ticket: any) => ticket.probleme == "Image dégradée / intermittente").length;
   this.defautEnregistrement = this.ticketsChanged.filter((ticket: any) => ticket.probleme == "Défaut enregistrement").length;
   this.nonManipulable = this.ticketsChanged.filter((ticket: any) => ticket.probleme == "Difficilement / Non manipulable").length;
   this.affichageTuile = this.ticketsChanged.filter((ticket: any) => ticket.probleme == "Défaut affichage Tuile / Tache").length;
   this.affichageMur = this.ticketsChanged.filter((ticket: any) => ticket.probleme == "Défaut affichage mur d’image").length;
   this.vandalisme = this.ticketsChanged.filter((ticket: any) => ticket.probleme == "Caméra Vandalisée").length;
   this.cartographie = this.ticketsChanged.filter((ticket: any) => ticket.probleme == "Cartographie").length;
   this.vibration = this.ticketsChanged.filter((ticket: any) => ticket.probleme == "Défaut Capteur Vibration").length;
   this.sonore = this.ticketsChanged.filter((ticket: any) => ticket.probleme == "Défaut Micro / Haut-parleur").length;
   this.poste = this.ticketsChanged.filter((ticket: any) => ticket.probleme == "Défaut poste opérateur").length;
   this.intrusion = this.ticketsChanged.filter((ticket: any) => ticket.probleme == "Défaut alarme intrusion local technique").length;
   this.nonAssigne = this.ticketsChanged.filter((ticket: any) => ticket.probleme == "Non Classifié").length;
   this.allNew = this.horsLigne + this.imageDegrade + this.defautEnregistrement + this.nonManipulable + this.affichageTuile +
                 this.affichageMur + this.vandalisme + this.cartographie + this.vibration + this.sonore + this.poste + this.intrusion + this.nonAssigne;
   }
   else
   {
   console.log("on passe bien par la c'est nouveau");
   this.horsLigne = 0;
   this.imageDegrade = 0;
   this.defautEnregistrement = 0;
   this.nonManipulable = 0;
   this.affichageTuile = 0;
   this.affichageMur = 0;
   this.vandalisme = 0;
   this.cartographie = 0;
   this.vibration = 0;
   this.sonore = 0;
   this.poste = 0;
   this.intrusion = 0;
   this.allNew = 0;
   this.nonAssigne = 0;

        this.autre = this.ticketsChanged.filter((ticket: any) => ticket.problemeOld == "Autre").length;
       this.pbReseau = this.ticketsChanged.filter((ticket: any) => ticket.problemeOld == "Problème réseau").length;
       this.pbSysteme = this.ticketsChanged.filter((ticket: any) => ticket.problemeOld == "Problème système").length;
       this.pbManipulation = this.ticketsChanged.filter((ticket: any) => ticket.problemeOld == "Problème de manipulation").length;
       this.pbMateriel = this.ticketsChanged.filter((ticket: any) => ticket.problemeOld == "Problème matériel").length;
       this.pbEPMairie = this.ticketsChanged.filter((ticket: any) => ticket.problemeOld == "Problème EP Mairie").length;
       this.aucunePanne = this.ticketsChanged.filter((ticket: any) => ticket.problemeOld == "ancien Problème Non Assigné").length;
       this.allOld = this.autre + this.pbReseau + this.pbSysteme + this.pbManipulation + this.pbMateriel + this.pbEPMairie + this.aucunePanne;
   }

if(this.checkedValue == "nouveau")
{
    var allData:number = this.horsLigne + this.imageDegrade + this.defautEnregistrement + this.nonManipulable + this.affichageTuile +
    this.affichageMur + this.vandalisme + this.sonore + this.poste + this.intrusion;
   var horsLigneChart:any = this.horsLigne / allData;
   var imageDegradeChart:any = this.imageDegrade / allData;
   var defautEnregistrementChart:any = this.defautEnregistrement / allData;
   var nonManipulableChart:any = this.nonManipulable / allData;
   var affichageTuileChart:any = this.affichageTuile / allData;
   var affichageMurChart:any = this.affichageMur / allData;
   var vandalismeChart:any = this.vandalisme / allData;
   var cartographieChart:any = this.cartographie / allData;
   var vibrationChart:any = this.vibration / allData;
   var sonoreChart:any = this.sonore / allData;
   var posteChart:any = this.poste / allData;
   var intrusionChart:any = this.intrusion / allData;


   if(horsLigneChart == 0)
        {
           horsLigneChart = null;
        }
        if(imageDegradeChart == 0)
        {
           imageDegradeChart = null;
        }
     if(defautEnregistrementChart == 0)
        {
           defautEnregistrementChart = null;
        }
     if(nonManipulableChart == 0)
        {
           nonManipulableChart = null;
        }
        if(affichageTuileChart == 0)
        {
           affichageTuileChart = null;
        }
        if(affichageMurChart == 0)
        {
           affichageMurChart = null;
        }
        if(vandalismeChart == 0)
        {
           vandalismeChart = null;
        }
     if(cartographieChart == 0)
        {
           cartographieChart = null;
        }
     if(vibrationChart == 0)
             {
                vibrationChart = null;
             }
     if(sonoreChart == 0)
        {
           sonoreChart = null;
        }
     if(posteChart == 0)
        {
           posteChart = null;
        }
     if(intrusionChart == 0)
        {
           intrusionChart = null;
        }
   var numberAllCamera:any = 0;

     if(this.panne != "")
     {
      this.ticketsCameraForChart = this.ticketsChanged;
     //console.log("ticketsCameraForChart", this.ticketsCameraForChart);
     var result: any = [];
     	this.ticketsCameraForChart.forEach(function(elem){
     	elem = elem.camera;
     		if(elem in result){
     			result[elem] = ++result[elem];
     			numberAllCamera++
     		}
     		else{
     			result[elem] = 1;
     			numberAllCamera++
     		}
     	});
     	//console.log("result", result)
   var valuePourcent:number[] = [];
   var valueKey:string[] = [];
   var tabTest:any = []
   var i=0;
   //

   for (const [key, value] of Object.entries(result)) {
     //console.log(`${key}: ${value}`);
     var test = value as number;
     valueKey[i] = key;
     valuePourcent[i] =  test / numberAllCamera;
           		i++;

     tabTest.push([key + " Nb : " + value + " pourcentage", value]);
   }



     	console.log("numberAllCamera", numberAllCamera)
     	console.log("tabTest", tabTest)


     	 this.options = {
           chart: {
               plotBackgroundColor: null,
               plotBorderWidth: null,
               plotShadow: true,
               backgroundColor:'lightsteelblue',
               type: 'pie',
               width:null,
               height:null,
               title : 'panne de la camera'
           },
           title: {
                   text: 'Nombre de panne par cameras pour la panne : ' + this.panne + " , " + this.dateForChart
               },

           tooltip: {
               pointFormat: '{series.name}: {point.percentage:.1f}%'
           },
           accessibility: {
               point: {
                   valueSuffix: '%'
               }
           },
           plotOptions: {
               pie: {
                   allowPointSelect: true,
                   cursor: 'pointer',
                   dataLabels: {
                       enabled: true,
                       format: '{point.name}: {point.percentage:.1f} % '
                   }
               }
           },

           series: [{
           colorByPoint: true,
               data: tabTest
           }]

         }


     }

   if(this.panne == "")
   {
   this.options = {
       chart: {
           plotBackgroundColor: null,
           plotBorderWidth: null,
           plotShadow: true,
           backgroundColor:'lightsteelblue',
           type: 'pie',
           width:null,
           height:null,
           title : 'panne de la camera'
       },
       title:
       {
           text: 'Répartition des types de pannes' + " , " + this.dateForChart
       },

       tooltip: {
           pointFormat: '{series.name}: {point.percentage:.1f}%'
       },
       accessibility: {
           point: {
               valueSuffix: '%'
           }
       },
       plotOptions: {
           pie: {
               allowPointSelect: true,
               cursor: 'pointer',
               dataLabels: {
                   enabled: true,
                   format: '{point.name}: {point.percentage:.1f} % '
               }
           }
       },

       series: [{
           name: 'Panne',
           colorByPoint: true,
           data: [{
               name: "pb Hors Ligne " + this.horsLigne + '</br>' + 'pourcentage ' + "nb Panne " ,
               y: horsLigneChart,
           }, {
               name: "Image Degradée " + this.imageDegrade + '</br>'+ 'pourcentage ',
               y: imageDegradeChart
           }, {
               name: "Défaut Enregistrement " + this.defautEnregistrement + '</br>'+ 'pourcentage ',
               y: defautEnregistrementChart
           }, {
               name: "pb Manipulation " + this.nonManipulable + '</br>'+ 'pourcentage ',
               y: nonManipulableChart
           }, {
               name: "Défaut affichage Tuile / Tache " + this.affichageTuile + '</br>'+ 'pourcentage ',
               y: affichageTuileChart
           }, {
               name: "Défaut affichage mur d’image " + this.affichageMur + '</br>'+ 'pourcentage ',
               y: affichageMurChart

           },
            {
                name: "Défaut alarme vandalisme " + this.vandalisme + '</br>'+ 'pourcentage ',
                y: vandalismeChart
            }, {
                name: "Defaut Cartographie " + this.cartographie + '</br>'+ 'pourcentage ',
                y: cartographieChart
            },
            {
                name: "Défaut Capteur Vibration " + this.vibration + '</br>'+ 'pourcentage ',
                y: vibrationChart
            },
            {
                name: "Défaut diffusion sonore " + this.sonore + '</br>'+ 'pourcentage ',
                y: sonoreChart
            }, {
                name: "Défaut Poste Opérateur " + this.poste + '</br>'+ 'pourcentage ',
                y: posteChart
            }, {
                name: "Défaut alarme intrusion local technique " + this.intrusion + '</br>'+ 'pourcentage ',
                y: intrusionChart

            }

           ]
       }]
     }
     }

     if(this.camera != "")
     {
     this.options = {
         chart: {
             plotBackgroundColor: null,
             plotBorderWidth: null,
             plotShadow: true,
             backgroundColor:'lightsteelblue',
             type: 'pie',
             width:null,
             height:null,
             title : 'panne de la camera'
         },
         title:
         {
             text: 'Répartition des types de pannes pour la caméra : ' + this.camera  + " , " + this.dateForChart
         },

         tooltip: {
             pointFormat: '{series.name}: {point.percentage:.1f}%'
         },
         accessibility: {
             point: {
                 valueSuffix: '%'
             }
         },
         plotOptions: {
             pie: {
                 allowPointSelect: true,
                 cursor: 'pointer',
                 dataLabels: {
                     enabled: true,
                     format: '{point.name}: {point.percentage:.1f} % '
                 }
             }
         },

         series: [{
             name: 'Panne',
             colorByPoint: true,
             data: [{
                        name: "pb Hors Ligne " + this.horsLigne + '</br>' + 'pourcentage ' + "nb Panne " ,
                        y: horsLigneChart,
                    }, {
                        name: "Image Degradée " + this.imageDegrade + '</br>'+ 'pourcentage ',
                        y: imageDegradeChart
                    }, {
                        name: "Défaut Enregistrement " + this.defautEnregistrement + '</br>'+ 'pourcentage ',
                        y: defautEnregistrementChart
                    }, {
                        name: "pb Manipulation " + this.nonManipulable + '</br>'+ 'pourcentage ',
                        y: nonManipulableChart
                    }, {
                        name: "Défaut affichage Tuile / Tache " + this.affichageTuile + '</br>'+ 'pourcentage ',
                        y: affichageTuileChart
                    }, {
                        name: "Défaut affichage mur d’image " + this.affichageMur + '</br>'+ 'pourcentage ',
                        y: affichageMurChart

                    },
                     {
                         name: "Défaut alarme vandalisme " + this.vandalisme + '</br>'+ 'pourcentage ',
                         y: vandalismeChart
                     },
                     {
                         name: "Defaut Cartographie " + this.cartographie + '</br>'+ 'pourcentage ',
                         y: cartographieChart
                     },
                     {
                         name: "Défaut Capteur Vibration " + this.vibration + '</br>'+ 'pourcentage ',
                         y: vibrationChart
                     },
                                    {
                         name: "Défaut Poste Opérateur " + this.poste + '</br>'+ 'pourcentage ',
                         y: posteChart
                     }, {
                         name: "Défaut alarme intrusion local technique " + this.intrusion + '</br>'+ 'pourcentage ',
                         y: intrusionChart

                     }

                    ]
         }]
       }
       }

     if(this.camera != "" && this.panne != "")
       {
       this.options = {
           chart: {
               plotBackgroundColor: null,
               plotBorderWidth: null,
               plotShadow: true,
               backgroundColor:'lightsteelblue',
               type: 'pie',
               width:null,
               height:null,
               title : 'panne de la camera'
           },
           title:
           {
               text: 'Répartition des types de pannes pour la caméra ' + this.camera + ' avec la panne : ' + this.panne  + " , " + this.dateForChart
           },

           tooltip: {
               pointFormat: '{series.name}: {point.percentage:.1f}%'
           },
           accessibility: {
               point: {
                   valueSuffix: '%'
               }
           },
           plotOptions: {
               pie: {
                   allowPointSelect: true,
                   cursor: 'pointer',
                   dataLabels: {
                       enabled: true,
                       format: '{point.name}: {point.percentage:.1f} % '
                   }
               }
           },

           series: [{
               name: 'Panne',
               colorByPoint: true,
               data: [{
                          name: "pb Hors Ligne " + this.horsLigne + '</br>' + 'pourcentage ' + "nb Panne " ,
                          y: horsLigneChart,
                      }, {
                          name: "Image Degradée " + this.imageDegrade + '</br>'+ 'pourcentage ',
                          y: imageDegradeChart
                      }, {
                          name: "Défaut Enregistrement " + this.defautEnregistrement + '</br>'+ 'pourcentage ',
                          y: defautEnregistrementChart
                      }, {
                          name: "pb Manipulation " + this.nonManipulable + '</br>'+ 'pourcentage ',
                          y: nonManipulableChart
                      }, {
                          name: "Défaut affichage Tuile / Tache " + this.affichageTuile + '</br>'+ 'pourcentage ',
                          y: affichageTuileChart
                      }, {
                          name: "Défaut affichage mur d’image " + this.affichageMur + '</br>'+ 'pourcentage ',
                          y: affichageMurChart

                      },
                       {
                           name: "Défaut alarme vandalisme " + this.vandalisme + '</br>'+ 'pourcentage ',
                           y: vandalismeChart
                       },
                       {
                           name: "Defaut Cartographie " + this.cartographie + '</br>'+ 'pourcentage ',
                           y: cartographieChart
                       },
                       {
                           name: "Défaut Capteur Vibration " + this.vibration + '</br>'+ 'pourcentage ',
                           y: vibrationChart
                       },
                                     {
                           name: "Défaut diffusion sonore " + this.sonore + '</br>'+ 'pourcentage ',
                           y: sonoreChart
                       }, {
                           name: "Défaut Poste Opérateur " + this.poste + '</br>'+ 'pourcentage ',
                           y: posteChart
                       }, {
                           name: "Défaut alarme intrusion local technique " + this.intrusion + '</br>'+ 'pourcentage ',
                           y: intrusionChart

                       }

                      ]
           }]
         }
         }

     Highcharts.chart('container2', this.options);


























}
else
{

var allData:number = this.autre + this.pbReseau + this.pbSysteme + this.pbManipulation + this.pbMateriel + this.pbEPMairie + this.aucunePanne;
   var autreChart:any = this.autre / allData;
   console.log("autreChart", autreChart);
   var pbReseauChart:any = this.pbReseau / allData;
   var pbSystemeChart:any = this.pbSysteme / allData;
   var pbManipulationChart:any = this.pbManipulation / allData;
   var pbMaterielChart:any = this.pbMateriel / allData;
   var aucunePanneChart:any = this.aucunePanne / allData;

     if(autreChart == 0)
     {
        autreChart = null;
     }
     if(pbReseauChart == 0)
     {
        pbReseauChart = null;
     }
  if(pbSystemeChart == 0)
     {
        pbSystemeChart = null;
     }
  if(pbManipulationChart == 0)
     {
        pbManipulationChart = null;
     }
  if(pbMaterielChart == 0)
     {
        pbMaterielChart = null;
     }
  if(aucunePanneChart == 0)
     {
        aucunePanneChart = null;
     }
var numberAllCamera:any = 0;

  if(this.panne != "")
  {
   this.ticketsCameraForChart = this.ticketsChanged;
  //console.log("ticketsCameraForChart", this.ticketsCameraForChart);
  var result: any = [];
  	this.ticketsCameraForChart.forEach(function(elem){
  	elem = elem.camera;
  		if(elem in result){
  			result[elem] = ++result[elem];
  			numberAllCamera++
  		}
  		else{
  			result[elem] = 1;
  			numberAllCamera++
  		}
  	});
  	//console.log("result", result)
var valuePourcent:number[] = [];
var valueKey:string[] = [];
var tabTest:any = []
var i=0;
//

for (const [key, value] of Object.entries(result)) {
  //console.log(`${key}: ${value}`);
  var test = value as number;
  valueKey[i] = key;
  valuePourcent[i] =  test / numberAllCamera;
        		i++;

  tabTest.push([key + " Nb : " + value + " pourcentage", value]);
}



  	console.log("numberAllCamera", numberAllCamera)
  	console.log("tabTest", tabTest)


  	 this.options = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: true,
            backgroundColor:'lightsteelblue',
            type: 'pie',
            width:null,
            height:null,
            title : 'panne de la camera'
        },
        title: {
                text: 'Nombre de panne par cameras pour la panne : ' + this.panne + " , " + this.dateForChart
            },

        tooltip: {
            pointFormat: '{series.name}: {point.percentage:.1f}%'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '{point.name}: {point.percentage:.1f} % '
                }
            }
        },

        series: [{
        colorByPoint: true,
            data: tabTest
        }]

      }


  }

if(this.panne == "")
{
this.options = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: true,
        backgroundColor:'lightsteelblue',
        type: 'pie',
        width:null,
        height:null,
        title : 'panne de la camera'
    },
    title:
    {
        text: 'Répartition des types de pannes' + " , " + this.dateForChart
    },

    tooltip: {
        pointFormat: '{series.name}: {point.percentage:.1f}%'
    },
    accessibility: {
        point: {
            valueSuffix: '%'
        }
    },
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: true,
                format: '{point.name}: {point.percentage:.1f} % '
            }
        }
    },

    series: [{
        name: 'Panne',
        colorByPoint: true,
        data: [{
            name: "pb Autre " + this.autre + '</br>' + 'pourcentage ' + "nb Panne " ,
            y: autreChart,
        }, {
            name: "pb Reseau " + this.pbReseau + '</br>'+ 'pourcentage ',
            y: pbReseauChart
        }, {
            name: "pb Système " + this.pbSysteme + '</br>'+ 'pourcentage ',
            y: pbSystemeChart
        }, {
            name: "pb Manipulation " + this.pbManipulation + '</br>'+ 'pourcentage ',
            y: pbManipulationChart
        }, {
            name: "pb Materiel " + this.pbMateriel + '</br>'+ 'pourcentage ',
            y: pbMaterielChart
        }, {
            name: "Panne non classifiée " + this.aucunePanne + '</br>'+ 'pourcentage ',
            y: aucunePanneChart

        }]
    }]
  }
  }

  if(this.camera != "")
  {
  this.options = {
      chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: true,
          backgroundColor:'lightsteelblue',
          type: 'pie',
          width:null,
          height:null,
          title : 'panne de la camera'
      },
      title:
      {
          text: 'Répartition des types de pannes pour la caméra : ' + this.camera  + " , " + this.dateForChart
      },

      tooltip: {
          pointFormat: '{series.name}: {point.percentage:.1f}%'
      },
      accessibility: {
          point: {
              valueSuffix: '%'
          }
      },
      plotOptions: {
          pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                  enabled: true,
                  format: '{point.name}: {point.percentage:.1f} % '
              }
          }
      },

      series: [{
          name: 'Panne',
          colorByPoint: true,
          data: [{
              name: "pb Autre " + this.autre + '</br>' + 'pourcentage ',
              y: autreChart,
          }, {
              name: "pb Reseau " + this.pbReseau + '</br>'+ 'pourcentage ',
              y: pbReseauChart
          }, {
              name: "pb Système " + this.pbSysteme + '</br>'+ 'pourcentage ',
              y: pbSystemeChart
          }, {
              name: "pb Manipulation " + this.pbManipulation + '</br>'+ 'pourcentage ',
              y: pbManipulationChart
          }, {
              name: "pb Materiel " + this.pbMateriel + '</br>'+ 'pourcentage ',
              y: pbMaterielChart
          }, {
              name: "pb Aucune Panne " + this.aucunePanne + '</br>'+ 'pourcentage ',
              y: aucunePanneChart

          }]
      }]
    }
    }

  if(this.camera != "" && this.panne != "")
    {
    this.options = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: true,
            backgroundColor:'lightsteelblue',
            type: 'pie',
            width:null,
            height:null,
            title : 'panne de la camera'
        },
        title:
        {
            text: 'Répartition des types de pannes pour la caméra ' + this.camera + ' avec la panne : ' + this.panne  + " , " + this.dateForChart
        },

        tooltip: {
            pointFormat: '{series.name}: {point.percentage:.1f}%'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '{point.name}: {point.percentage:.1f} % '
                }
            }
        },

        series: [{
            name: 'Panne',
            colorByPoint: true,
            data: [{
                name: "pb Autre " + this.autre + '</br>' + 'pourcentage ',
                y: autreChart,
            }, {
                name: "pb Reseau " + this.pbReseau + '</br>'+ 'pourcentage ',
                y: pbReseauChart
            }, {
                name: "pb Système " + this.pbSysteme + '</br>'+ 'pourcentage ',
                y: pbSystemeChart
            }, {
                name: "pb Manipulation " + this.pbManipulation + '</br>'+ 'pourcentage ',
                y: pbManipulationChart
            }, {
                name: "pb Materiel " + this.pbMateriel + '</br>'+ 'pourcentage ',
                y: pbMaterielChart
            }, {
                name: "pb Aucune Panne " + this.aucunePanne + '</br>'+ 'pourcentage ',
                y: aucunePanneChart

            }]
        }]
      }
      }

  Highcharts.chart('container2', this.options);
       }


}


displayChart()
  {
  let container2 = document.getElementById('container2');
      container2!.style.display = "block";
      this.activateButton = false;
  }

removeChart()
{
let container2 = document.getElementById('container2');
      container2!.style.display = "none";
      this.activateButton = true;
}

addSla()
{
let container3 = document.getElementById('container3');
      container3!.style.display = "block";
      this.activateSla = false;
}

removeSla()
{
let container3 = document.getElementById('container3');
      container3!.style.display = "none";
      this.activateSla = true;
}


  constructor(private http: HttpClient, private list: DataMiramasService) {
var test;
list.getAllTicketsMiramas();

  test = this.list.serviceUpdatedMiramas.subscribe({
              next: (test) => {
                  console.log(`test j'ai bien recu`, test);
                  this.tickets = test;
                  this.allTickets.emit(this.tickets);
                  this.hideloader();
              }
          })



  }

  ngOnInit(): void {
  console.log("camera " , this.camera);
  if(this.tickets.length == 0)
  {
      this.tickets = this.list.getTicketsValueMiramas();
  }
  if(this.tickets.length != 0)
  {
  console.log("on est bien la bébé");
  this.allTickets.emit(this.tickets);
  this.hideloader();

  }
  }

          hideloader() {

              let loading = document.getElementById('loading-mask');
                    loading!.style.display = "none";
          }

}
