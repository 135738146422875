<app-bandeau-titre></app-bandeau-titre>

<div>
  <app-search-ticket
    (newItemEventCamera)="addCamera($event)"
    (newItemEventProblem)="addProbleme($event)"
    (newItemEventStatus)="addStatus($event)"
    (newItemEventPriority)="addPriority($event)"
    (newItemEventInput)="eventInput($event)"
    (newAnnulerTout)="eventAnnuler($event)"
    (newCreateTicketEvent)="eventCreateTickets($event)"
  ></app-search-ticket>
</div>
<div>
  <app-display-ticket
    [poste]="display"
    [item]="responsable"
    [camera]="camera"
    [problem]="problem"
    [status]="status"
    [priority]="priority"
    [LaunchDisplayTicket]="createTicket"
    (problemeSipproEvent)="getProblemSippro($event)"
    (problemeSnefEvent)="getProblemSnef($event)"
    (problemeMaintenanceZefilEvent)="getProblemMaintenanceZefil($event)"
    (problemeMairieEvent)="getProblemMairie($event)"
    (problemeTousEvent)="getProblemTous($event)"
  ></app-display-ticket>
</div>
<app-responsable
  (newItemEvent)="addItem($event)"
  [annulerTOUS]="annulerTout"
  [allResponsableInput]="allResponsable"
  [problemTous]="problemTous"
  [problemSippro]="problemSippro"
  [problemSnef]="problemSnef"
  [problemMaintenanceZefil]="problemMaintenanceZefil"
  [problemMairie]="problemMairie"
></app-responsable>

<div id="loading-maskTickets">
  <p class="textCreation">Mise a jour des pannes</p>
  <div class="d-flex justify-content-center">
    <div class="spinner-border" role="status">
      <span class="sr-only" id="loading"></span>
    </div>
  </div>
</div>
