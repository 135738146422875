<div *ngIf="isAuthorized == true">
<app-header-rapport (EventValueDate)="getDate($event)" (newItemEventCamera)="addCamera($event)"
                    (newItemEventProblem)="addProbleme($event)" (EventChecked)="getCheckedValue($event)" >

</app-header-rapport>

<app-display-compteur (allTickets)="getTickets($event)" [camera]="cameraForDisplay" [panne]="probleme"
                      [dateForChart]="dateForChart" [getAllTicketsForMomentTab]="getAllTicketsForMomentTab" [checkedValue]="checkedValue">

</app-display-compteur>

<app-display-rapports [inputTickets]="inputTickets" [date]="date" [camera]="cameraForDisplay"
                      [panne]="probleme" (sendTableTickets)="getAllTicketsForMoment($event)"
                      (senddateForChart)="getDateValue($event)" [checkedValue]="checkedValue">

</app-display-rapports>
</div>
