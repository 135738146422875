<div *ngIf="poste == 'operateur'">
  <div class="container1">
    <mat-card class="zone-card">
      <mat-card-header>
        <mat-card-title class="title"
          >Filtres / Création d'une panne</mat-card-title
        >
      </mat-card-header>
      <div class="searchCamAndProb">
        <app-search-cam-and-prob
          (newItemEventCamera)="addCamera($event)"
          (newItemEventProblem)="addProbleme($event)"
          (newItemEventStatus)="addStatus($event)"
          (newItemEventPriority)="addPriority($event)"
          (eventOpenInput)="getOpenInput($event)"
          (eventAnnuler)="getAnnulerTout($event)"
          (eventCreateTicket)="getCreateTicket($event)"
        ></app-search-cam-and-prob>
      </div>
    </mat-card>
  </div>
</div>

<div *ngIf="poste != 'operateur'">
  <div class="container">
    <mat-card class="zone-card">
      <mat-card-header>
        <mat-card-title class="title"
          >Filtres / Création d'une panne</mat-card-title
        >
      </mat-card-header>
      <div class="searchCamAndProb">
        <app-search-cam-and-prob
          (newItemEventCamera)="addCamera($event)"
          (newItemEventProblem)="addProbleme($event)"
          (newItemEventStatus)="addStatus($event)"
          (newItemEventPriority)="addPriority($event)"
          (eventOpenInput)="getOpenInput($event)"
          (eventAnnuler)="getAnnulerTout($event)"
          (eventCreateTicket)="getCreateTicket($event)"
        ></app-search-cam-and-prob>
      </div>
    </mat-card>
  </div>
</div>
