import { Component, Input, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { Router } from '@angular/router'; // import router from angular router
import { DataService } from '../../../data.service';

import * as header from '../../rapportPageDirectory/header-rapport/header-rapport.component';
import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
})
export class MainPageComponent implements OnInit {
  @Input() item = '';
  @Input() annulerTout = '';
  @Input() createTicket = '';

  responsable: string = 'TOUS';
  camera: string = '';
  problem: string = '';
  status: string = '';
  priority: string = '';
  problemTous: string = '';
  problemSippro: string = '';
  problemSnef: string = '';
  problemMaintenanceZefil: string = '';
  problemMairie: string = '';
  allResponsable: string = '';
  title = 'JiraFrontProject';
  display: string = '';
  constructor(private route: Router, private list: DataService) {}

  addItem(newItem: string) {
    //console.log("newItem" , newItem);
    this.responsable = newItem;
  }

  addCamera(newItem: string) {
    this.camera = newItem;
    console.log('From main camera : ', newItem);
  }
  addProbleme(newItem: string) {
    this.problem = newItem;
    //console.log("probleme : ", newItem);
  }

  addStatus(newItem: string) {
    console.log('from main status value: ', newItem);
    this.status = newItem;
  }

  addPriority(newItem: string) {
    console.log('from main priority value: ', newItem);
    this.priority = newItem;
  }

  eventInput(newItem: string) {
    this.allResponsable = this.generateRandomString(4);
  }

  getProblemSippro(newItem: string) {
    //console.log("getProblemSippro", newItem);
    this.problemSippro = newItem;
  }

  getProblemSnef(newItem: string) {
    //console.log("getProblemSnef", newItem);
    this.problemSnef = newItem;
  }

  getProblemMaintenanceZefil(newItem: string) {
    this.problemMaintenanceZefil = newItem;
  }

  getProblemMairie(newItem: string) {
    //console.log("getProblemMairie", newItem);
    this.problemMairie = newItem;
  }
  getProblemTous(newItem: string) {
    //console.log("getProblemTous", newItem);
    this.problemTous = newItem;
  }

  eventAnnuler(newEvent: string) {
    this.annulerTout = this.generateRandomString(4);
  }

  eventCreateTickets(newEvent: string) {
    this.createTicket = this.generateRandomString(4);
  }
  public generateRandomString(length: number) {
    var result = '';
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  ngOnInit() {}
}
