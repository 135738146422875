import { Component, OnInit, Input } from '@angular/core';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { Output, EventEmitter } from '@angular/core';
import { Router, RouterModule,NavigationEnd } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataService } from 'src/app/data.service';


export var authorized = false;
@Component({
  selector: 'app-responsable',
  templateUrl: './responsable.component.html',
  styleUrls: ['./responsable.component.scss']
})
export class ResponsableComponent implements OnInit {
  

@Output() newItemEvent = new EventEmitter<string>();
constructor(private http: HttpClient, private list: DataService, private router: Router) {
  
  var messageData;


   messageData = this.list.serviceUpdatedMessage.subscribe({
                next: (messageData) => {
                    console.log(`test j'ai bien recu`, messageData);
                    this.completeData = messageData;
                }
            })
  console.log("messageData", messageData);
  }
@Input() problemTous = "";
@Input() problemSippro = "";
@Input() problemSnef = "";
@Input() problemMairie = "";
@Input() problemMaintenanceZefil = "";
@Input() allResponsableInput = "";
@Input() annulerTOUS = "";
completeData:string = "";

valueButton:string = "";
public value:string = "";
  ngOnInit(): void {

  }

  

getValue(value:string)
{
this.newItemEvent.emit(value);
console.log("value : ", value);
}

ngOnChanges() {
    this.testButton(this.allResponsableInput);
  }

testButton(value:string)
{
this.valueButton = "TOUS";
this.newItemEvent.emit("TOUS");
}

changeRoute()
{
authorized = true;
}

sendDisplayValue()
  {
  console.log("j'ai cliqué");
  this.list.sendIsConnected()
  this.router.navigate(['/mainToulouse']);
  }

  gotoGmao() {
    window.open('https://dashboard.sippro-gmao.fr', '_blank');
  }
  gotoAnomalies() {
    window.open('https://toulanomalies.sippro-gmao.fr', '_blank');
  }

}

