import { Component, OnInit, Input } from '@angular/core';
import { Output, EventEmitter} from '@angular/core';

import { DatePipe } from '@angular/common';

import * as Highcharts from 'highcharts';
declare var require: any;
@Component({
    selector: 'app-display-rapports-miramas',
    templateUrl: './display-rapports.component.html',
    styleUrls: ['./display-rapports.component.scss', '../../../../assets/scss/fleches.scss' ]
})
export class DisplayRapportsComponentMiramas implements OnInit {
     @Input() date;
     @Input() inputTickets;
     @Input() camera = "";
     @Input() panne = "";
     @Input() checkedValue = "nouveau";
     @Output() sendTableTickets = new EventEmitter<any>();
     @Output() senddateForChart = new EventEmitter<any>();
     tabDate: any = [];
     newDate: Date = new Date();
     options: any = {};
     tableAllTicketsForMoment : any = [];
     dateForChart:string = "";
     tableau1SemaineXDay:string[] = ["lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi", "dimanche"];

     manipulation:string = '';
     systeme:string = '';
     reseau:string = '';
     autre:string=  '';
     materiel:string = '';
     nonClassifieTexte:string = '';
     EPMairie:string = '';

     horsLigne:string = '';
     imageDegrade:string = '';
     defautEnregistrement:string = '';
     nonManipulable:string=  '';
     affichageTuile:string = '';
     affichageMur:string = '';
     vandalisme:string = '';
     vibration:string = '';
     sonore:string=  '';
     poste:string = '';
     intrusion:string = '';


     dateToDisplay:string = '';
     toutProbleme:string = "tous les problèmes";


     firstConnexion:boolean = false;
     filterActivated:boolean = false;

     filterManipulation:boolean = false;
     filterSysteme:boolean = false;
     filterReseau:boolean = false;
     filterAutre:boolean = false
     filterMateriel:boolean = false;
     filterEPMairie = false;
     nonClassifie:boolean = false;

     filterHorsLigne:boolean = false;
     filterImageDegrade:boolean = false;
     filterDefautEnregistrement:boolean = false;
     filterNonManipulable:boolean = false
     filterAffichageTuile:boolean = false;
     filterAffichageMur:boolean = false;
     filterVandalisme:boolean = false;
     filterVibration:boolean = false;
     filterSonore:boolean = false
     filterPoste:boolean = false;
     filterIntrusion:boolean = false;



     dateTickets: any = []
     ticketsTrasformedDate: any = [];
     tabReference: any = [];
     tab6Mois: any = [];
     tabDay: any = [];
     tab6MoisValue: any = [];
     tab1YearReferenceValue: any = [];
     tableauTest: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tab1YearValue: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauYearX: any = [];



     tableau1SemaineX:any = [0, 0, 0, 0, 0, 0, 0]
     tableau1SemaineAll:any = [0, 0, 0, 0, 0, 0, 0]
     tableauManipulation1Semaine = [0, 0, 0, 0, 0, 0, 0];
     tableauSysteme1Semaine = [0, 0, 0, 0, 0, 0, 0];
     tableauReseau1Semaine = [0, 0, 0, 0, 0, 0, 0];
     tableauAutre1Semaine = [0, 0, 0, 0, 0, 0, 0];
     tableauMateriel1Semaine = [0, 0, 0, 0, 0, 0, 0];
     tableauNonClassifie1Semaine = [0, 0, 0, 0, 0, 0, 0];
     tableauEPMairie1Semaine = [0, 0, 0, 0, 0, 0, 0];

     tableauHorsLigne1Semaine = [0, 0, 0, 0, 0, 0, 0];
     tableauImageDegrade1Semaine = [0, 0, 0, 0, 0, 0, 0];
     tableauDefautEnregistrement1Semaine = [0, 0, 0, 0, 0, 0, 0];
     tableauNonManipulable1Semaine = [0, 0, 0, 0, 0, 0, 0];
     tableauDefautAffichageTuile1Semaine = [0, 0, 0, 0, 0, 0, 0];
     tableauDefautAffichageMur1Semaine = [0, 0, 0, 0, 0, 0, 0];
     tableauDefautVandalisme1Semaine = [0, 0, 0, 0, 0, 0, 0];
     tableauDefautVibration1Semaine = [0, 0, 0, 0, 0, 0, 0];
     tableauDefautSonore1Semaine = [0, 0, 0, 0, 0, 0, 0];
     tableauDefautPoste1Semaine = [0, 0, 0, 0, 0, 0, 0];
     tableauDefautIntrusion1Semaine = [0, 0, 0, 0, 0, 0, 0];

     tableauSlaWeek = [0, 0, 0, 0, 0, 0, 0];
     tableauManipulation1SemaineSla = [0, 0, 0, 0, 0, 0, 0];
     tableauSysteme1SemaineSla = [0, 0, 0, 0, 0, 0, 0];
     tableauReseau1SemaineSla = [0, 0, 0, 0, 0, 0, 0];
     tableauAutre1SemaineSla = [0, 0, 0, 0, 0, 0, 0];
     tableauMateriel1SemaineSla = [0, 0, 0, 0, 0, 0, 0];
     tableauNonClassifie1SemaineSla = [0, 0, 0, 0, 0, 0, 0];
     tableauEPMairie1SemaineSla = [0, 0, 0, 0, 0, 0, 0];

     tableauHorsLigne1SemaineSla = [0, 0, 0, 0, 0, 0, 0];
     tableauImageDegrade1SemaineSla = [0, 0, 0, 0, 0, 0, 0];
     tableauDefautEnregistrement1SemaineSla = [0, 0, 0, 0, 0, 0, 0];
     tableauNonManipulable1SemaineSla = [0, 0, 0, 0, 0, 0, 0];
     tableauDefautAffichageTuile1SemaineSla = [0, 0, 0, 0, 0, 0, 0];
     tableauDefautAffichageMur1SemaineSla = [0, 0, 0, 0, 0, 0, 0];
     tableauDefautVandalisme1SemaineSla = [0, 0, 0, 0, 0, 0, 0];
     tableauDefautVibration1SemaineSla = [0, 0, 0, 0, 0, 0, 0];
     tableauDefautSonore1SemaineSla = [0, 0, 0, 0, 0, 0, 0];
     tableauDefautPoste1SemaineSla = [0, 0, 0, 0, 0, 0, 0];
     tableauDefautIntrusion1SemaineSla = [0, 0, 0, 0, 0, 0, 0];




     tableauManipulation: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauSysteme: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauReseau: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauAutre: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauMateriel: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauNonClassifie: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauEPMairie: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

     tableauHorsLigne: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauImageDegrade: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauDefautEnregistrement: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauNonManipulable: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauDefautAffichageTuile: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauDefautAffichageMur: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauDefautVandalisme: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauDefautVibration: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauDefautSonore: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauDefautPoste: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauDefautIntrusion: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

     tableauManipulationSla: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauSystemeSla: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauReseauSla: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauAutreSla: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauMaterielSla: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauNonClassifieSla: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauEPMairieSla: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

     tableauHorsLigneSla: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauImageDegradeSla: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauDefautEnregistrementSla: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauNonManipulableSla: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauDefautAffichageTuileSla: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauDefautAffichageMurSla: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauDefautVandalismeSla: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauDefautVibrationSla: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauDefautSonoreSla: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauDefautPosteSla: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauDefautIntrusionSla: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];



     tableauManipulation6Mois: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauSysteme6Mois: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauReseau6Mois: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauAutre6Mois: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauMateriel6Mois: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauNonClassifie6Mois: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauEPMairie6Mois : any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];


     tableauHorsLigne6Mois: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauImageDegrade6Mois: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauDefautEnregistrement6Mois: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauNonManipulable6Mois: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauDefautAffichageTuile6Mois: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauDefautAffichageMur6Mois: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauDefautVandalisme6Mois: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauDefautVibration6Mois: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauDefautSonore6Mois: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauDefautPoste6Mois: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauDefautIntrusion6Mois: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];


     tableauManipulation6MoisSla: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauSysteme6MoisSla: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauReseau6MoisSla: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauAutre6MoisSla: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauMateriel6MoisSla: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauNonClassifie6MoisSla: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauEPMairie6MoisSla : any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];


     tableauHorsLigne6MoisSla: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauImageDegrade6MoisSla: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauDefautEnregistrement6MoisSla: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauNonManipulable6MoisSla: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauDefautAffichageTuile6MoisSla: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauDefautAffichageMur6MoisSla: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauDefautVandalisme6MoisSla: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauDefautVibration6MoisSla: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauDefautSonore6MoisSla: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauDefautPoste6MoisSla: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     tableauDefautIntrusion6MoisSla: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];


     tabAll1Mois: any = [];
     tableauManipulation1Mois: any = [];
     tableauSysteme1Mois: any = [];
     tableauReseau1Mois: any = [];
     tableauAutre1Mois: any = [];
     tableauMateriel1Mois: any = [];
     tableauNonClassifie1Mois: any = [];
     tableauEPMairie1Mois: any = [];

     tableauHorsLigne1Mois: any = [];
     tableauImageDegrade1Mois: any = [];
     tableauDefautEnregistrement1Mois: any = [];
     tableauNonManipulable1Mois: any = [];
     tableauDefautAffichageTuile1Mois: any = [];
     tableauDefautAffichageMur1Mois: any = [];
     tableauDefautVandalisme1Mois: any = [];
     tableauDefautVibration1Mois: any = [];
     tableauDefautSonore1Mois: any = [];
     tableauDefautPoste1Mois: any = [];
     tableauDefautIntrusion1Mois: any = [];


     tableauManipulation1MoisSla: any = [];
     tableauSysteme1MoisSla: any = [];
     tableauReseau1MoisSla: any = [];
     tableauAutre1MoisSla: any = [];
     tableauMateriel1MoisSla: any = [];
     tableauNonClassifie1MoisSla: any = [];
     tableauEPMairie1MoisSla: any = [];

     tableauHorsLigne1MoisSla: any = [];
     tableauImageDegrade1MoisSla: any = [];
     tableauDefautEnregistrement1MoisSla: any = [];
     tableauNonManipulable1MoisSla: any = [];
     tableauDefautAffichageTuile1MoisSla: any = [];
     tableauDefautAffichageMur1MoisSla: any = [];
     tableauDefautVandalisme1MoisSla: any = [];
     tableauDefautVibration1MoisSla: any = [];
     tableauDefautSonore1MoisSla: any = [];
     tableauDefautPoste1MoisSla: any = [];
     tableauDefautIntrusion1MoisSla: any = [];





     datePipeString: string | null;

     numberClick = 0;
     tabSla:any = [];




     compteurClickArrowLeft()
     {
         this.numberClick++;
         console.log("this.numberClick", this.numberClick);
             if(this.checkedValue == "nouveau")
             {
                     this.generateChart();
             }
             else
             {
                     this.generateChartOld();
             }
     }

     compteurClickArrowRight()
     {
         if(this.numberClick > 0)
         {
           this.numberClick--;
           console.log("this.numberClick", this.numberClick);
               if(this.checkedValue == "nouveau")
               {
                       this.generateChart();
               }
               else
               {
                       this.generateChartOld();
               }
         }
     }

     RebootCompteur()
     {
         this.numberClick = 0;
             if(this.checkedValue == "nouveau")
             {
                     this.generateChart();
             }
             else
             {
                     this.generateChartOld();
             }
     }


     getStringDate(date: Date) {
         let referenceDay = date.getDate();
         let referenceMonth = date.getMonth() + 1;
         let referenceYear = date.getFullYear();
         let referenceDate = referenceDay + "/" + referenceMonth + "/" + referenceYear;
         return referenceDate;
     }

     constructor(public datepipe: DatePipe) {

         //for 6 month
         this.datePipeString = datepipe.transform(Date.now(), 'w');





         console.log("tab6MoisValue", this.tab6MoisValue);
         ////console.log("lastday", lastday2);

     }
     getPanneForReinitialisationOld()
     {
     if(this.panne != "")
     {

          this.tableauTest = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          this.tab1YearValue = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
     console.log("on passe dans les panne" ,this.panne);

         //Ancien problèmes
         //remise a 0 des tableaux de la panne et de la sla : Problème de manipulation
         if(this.panne != "Problème de manipulation")
         {
         this.tableauManipulation1Semaine = [];
         this.tableauManipulation1Mois = [];
         this.tableauManipulation6Mois = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauManipulation = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

         this.tableauManipulation1SemaineSla = [];
         this.tableauManipulation1MoisSla = [];
         this.tableauManipulation6MoisSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauManipulationSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

         }

         //remise a 0 des tableaux de la panne et de la sla : Problème système
         if(this.panne != "Problème système")
         {
         this.tableauSysteme1Semaine = [];
         this.tableauSysteme1Mois = [];
         this.tableauSysteme6Mois = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauSysteme = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

         this.tableauSysteme1SemaineSla = [];
         this.tableauSysteme1MoisSla = [];
         this.tableauSysteme6MoisSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauSystemeSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

         }

         //remise a 0 des tableaux de la panne et de la sla : Problème réseau
         if(this.panne != "Problème réseau")
         {
         this.tableauReseau1Semaine = [];
         this.tableauReseau1Mois = [];
         this.tableauReseau6Mois = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauReseau = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

         this.tableauReseau1SemaineSla = [];
         this.tableauReseau1MoisSla = [];
         this.tableauReseau6MoisSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauReseauSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

         }

         //remise a 0 des tableaux de la panne et de la sla : Autre
         if(this.panne != "Autre")
         {
          this.tableauAutre1Semaine = [];
          this.tableauAutre1Mois = [];
          this.tableauAutre6Mois = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          this.tableauAutre = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

          this.tableauAutre1SemaineSla = [];
          this.tableauAutre1MoisSla = [];
          this.tableauAutre6MoisSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          this.tableauAutreSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];


         }

         //remise a 0 des tableaux de la panne et de la sla : Problème matériel
         if(this.panne != "Problème matériel")
         {
          this.tableauMateriel1Semaine = [];
          this.tableauMateriel1Mois = [];
          this.tableauMateriel6Mois = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          this.tableauMateriel = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

          this.tableauMateriel1SemaineSla = [];
          this.tableauMateriel1MoisSla = [];
          this.tableauMateriel6MoisSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          this.tableauMaterielSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

         }

         //remise a 0 des tableaux de la panne et de la sla : Non Classifié
         if(this.panne != "Non Classifié")
         {
          this.tableauNonClassifie1Semaine = [];
          this.tableauNonClassifie1Mois = [];
          this.tableauNonClassifie6Mois = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          this.tableauNonClassifie = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

          this.tableauNonClassifie1SemaineSla = [];
          this.tableauNonClassifie1MoisSla = [];
          this.tableauNonClassifie6MoisSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          this.tableauNonClassifieSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];


         }
           //remise a 0 des tableaux de la panne et de la sla : Problème EP Mairie
          if(this.panne != "Problème EP Mairie")
           {
            this.tableauEPMairie1Semaine = [];
            this.tableauEPMairie1Mois = [];
            this.tableauEPMairie6Mois = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            this.tableauEPMairie = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

            this.tableauEPMairie1SemaineSla = [];
            this.tableauEPMairie1MoisSla = [];
            this.tableauEPMairie6MoisSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            this.tableauEPMairieSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

           }

     }
     else
     {

     }
     }

         getPanneForReinitialisation()
         {
         if(this.panne != "")
         {

              this.tableauTest = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
              this.tab1YearValue = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         console.log("on passe dans les panne" ,this.panne);

             //Nouveaux problèmes
             //remise a 0 des tableaux de la panne et de la sla : Hors ligne
             if(this.panne != "Hors ligne")
             {
             this.tableauHorsLigne1Semaine = [];
             this.tableauHorsLigne1Mois = [];
             this.tableauHorsLigne6Mois = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
             this.tableauHorsLigne = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

             this.tableauHorsLigne1SemaineSla = [];
             this.tableauHorsLigne1MoisSla = [];
             this.tableauHorsLigne6MoisSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
             this.tableauHorsLigneSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

             }

             //remise a 0 des tableaux de la panne et de la sla : Image dégradée / intermittente
             if(this.panne != "Image dégradée / intermittente")
             {
             this.tableauImageDegrade1Semaine = [];
             this.tableauImageDegrade1Mois = [];
             this.tableauImageDegrade6Mois = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
             this.tableauImageDegrade = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

             this.tableauImageDegrade1SemaineSla = [];
             this.tableauImageDegrade1MoisSla = [];
             this.tableauImageDegrade6MoisSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
             this.tableauImageDegradeSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

             }

             //remise a 0 des tableaux de la panne et de la sla : Défaut enregistrement
             if(this.panne != "Défaut enregistrement")
             {
             this.tableauDefautEnregistrement1Semaine = [];
             this.tableauDefautEnregistrement1Mois = [];
             this.tableauImageDegrade6Mois = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
             this.tableauDefautEnregistrement = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

             this.tableauDefautEnregistrement1SemaineSla = [];
             this.tableauDefautEnregistrement1MoisSla = [];
             this.tableauImageDegrade6MoisSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
             this.tableauDefautEnregistrementSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

             }

             //remise a 0 des tableaux de la panne et de la sla : Difficilement / Non manipulable"
             if(this.panne != "Difficilement / Non manipulable")
             {
              this.tableauNonManipulable1Semaine = [];
              this.tableauNonManipulable1Mois = [];
              this.tableauDefautEnregistrement6Mois = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
              this.tableauNonManipulable = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

              this.tableauNonManipulable1SemaineSla = [];
              this.tableauNonManipulable1MoisSla = [];
              this.tableauDefautEnregistrement6MoisSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
              this.tableauNonManipulableSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];


             }
             //remise a 0 des tableaux de la panne et de la sla : Défaut affichage Tuile / Tache
             if(this.panne != "Défaut affichage Tuile / Tache")
             {
              this.tableauDefautAffichageTuile1Semaine = [];
              this.tableauDefautAffichageTuile1Mois = [];
              this.tableauDefautAffichageTuile6Mois = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
              this.tableauDefautAffichageTuile = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

              this.tableauDefautAffichageTuile1SemaineSla = [];
              this.tableauDefautAffichageTuile1MoisSla = [];
              this.tableauDefautAffichageTuile6MoisSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
              this.tableauDefautAffichageTuileSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
             }

             //remise a 0 des tableaux de la panne et de la sla : Défaut affichage mur d’image
             if(this.panne != "Défaut affichage mur d’image")
             {
              this.tableauDefautAffichageMur1Semaine = [];
              this.tableauDefautAffichageMur1Mois = [];
              this.tableauDefautAffichageMur6Mois = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
              this.tableauDefautAffichageMur = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

              this.tableauDefautAffichageMur1SemaineSla = [];
              this.tableauDefautAffichageMur1MoisSla = [];
              this.tableauDefautAffichageMur6MoisSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
              this.tableauDefautAffichageMurSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

             }
             //remise a 0 des tableaux de la panne et de la sla : Caméra Vandalisée
             if(this.panne != "Caméra Vandalisée")
             {
             this.tableauDefautVandalisme1Semaine = [];
             this.tableauDefautVandalisme1Mois = [];
             this.tableauDefautVandalisme6Mois = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
             this.tableauDefautVandalisme = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

             this.tableauDefautVandalisme1SemaineSla = [];
             this.tableauDefautVandalisme1MoisSla = [];
             this.tableauDefautVandalisme6MoisSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
             this.tableauDefautVandalismeSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

             }
             //remise a 0 des tableaux de la panne et de la sla : Défaut Capteur Vibration
             if(this.panne != "Défaut Capteur Vibration")
             {
             this.tableauDefautVibration1Semaine = [];
             this.tableauDefautVibration1Mois = [];
             this.tableauDefautVibration6Mois = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
             this.tableauDefautVibration = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

              this.tableauDefautVibration1SemaineSla = [];
              this.tableauDefautVibration1MoisSla = [];
              this.tableauDefautVibration6MoisSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
              this.tableauDefautVibrationSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

             }
             //remise a 0 des tableaux de la panne et de la sla : Défaut Micro / Haut-parleur
             if(this.panne != "Défaut Micro / Haut-parleur")
             {
              this.tableauDefautSonore1Semaine = [];
              this.tableauDefautSonore1Mois = [];
              this.tableauDefautSonore6Mois = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
              this.tableauDefautSonore = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

              this.tableauDefautSonore1SemaineSla = [];
              this.tableauDefautSonore1MoisSla = [];
              this.tableauDefautSonore6MoisSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
              this.tableauDefautSonoreSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
             }
             //remise a 0 des tableaux de la panne et de la sla : Défaut poste opérateur
             if(this.panne != "Défaut poste opérateur")
             {
              this.tableauDefautPoste1Semaine = [];
              this.tableauDefautPoste1Mois = [];
              this.tableauDefautPoste6Mois = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
              this.tableauDefautPoste = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

              this.tableauDefautPoste1SemaineSla = [];
              this.tableauDefautPoste1MoisSla = [];
              this.tableauDefautPoste6MoisSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
              this.tableauDefautPosteSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
             }
             //remise a 0 des tableaux de la panne et de la sla : Défaut alarme intrusion local technique
             if(this.panne != "Défaut alarme intrusion local technique")
             {
              this.tableauDefautIntrusion1Semaine = [];
              this.tableauDefautIntrusion1Mois = [];
              this.tableauDefautIntrusion6Mois = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
              this.tableauDefautIntrusion = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

              this.tableauDefautIntrusion1SemaineSla = [];
              this.tableauDefautIntrusion1MoisSla = [];
              this.tableauDefautIntrusion6MoisSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
              this.tableauDefautIntrusionSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

             }

         }
         }

     isFirstConnexionOld()
     {
     console.log("this.camera", this.camera);
     if(this.camera == undefined)
     {
       this.camera = "";
     }
     console.log("this.panne", this.panne);
         if(this.camera == "" && this.panne == "")
         {
           console.log("lolilol pas de filtres");
           this.filterManipulation = false;
           this.filterSysteme = false;
           this.filterReseau = false;
           this.filterAutre = false;
           this.filterMateriel = false;
           this.filterEPMairie = false;
           this.nonClassifie = false;
           this.filterActivated = true;
         }
         else
         {
         console.log("lolilol filtres");
             this.manipulation = "Problème de manipulation";
             this.systeme = "Problème Système";
             this.reseau = "Problème Réseau";
             this.autre =  'Autre';
             this.materiel = 'Problème Matériel';
             this.nonClassifieTexte = 'Non Classifié';

             this.filterManipulation = true;
             this.filterSysteme = true;
             this.filterReseau = true;
             this.filterAutre = true;
             this.filterMateriel = true;
             this.nonClassifie = true;
             this.filterEPMairie = true;
             this.filterActivated = false;

             if(this.panne == "Problème de manipulation")
             {
                 this.filterManipulation = true;
                 this.filterSysteme = false;
                 this.filterReseau = false;
                 this.filterAutre = false;
                 this.filterMateriel = false;
                 this.nonClassifie = false;
                 this.filterEPMairie = false;
             }
             if(this.panne == "Problème système")
             {
                 this.filterSysteme = true;
                 this.filterManipulation = false;
                 this.filterReseau = false;
                 this.filterAutre = false;
                 this.filterMateriel = false;
                 this.nonClassifie = false;
                 this.filterEPMairie = false;
             }
             if(this.panne == "Problème réseau")
             {
                 this.filterReseau = true;
                 this.filterSysteme = false;
                 this.filterManipulation = false;
                 this.filterAutre = false;
                 this.filterMateriel = false;
                 this.nonClassifie = false;
                 this.filterEPMairie = false;

             }
             if(this.panne == "Autre")
             {
                 this.filterAutre = true;
                 this.filterSysteme = false;
                 this.filterManipulation = false;
                 this.filterReseau = false;
                 this.filterMateriel = false;
                 this.nonClassifie = false;
                 this.filterEPMairie = false;
             }
             if(this.panne == "Problème matériel")
             {
                 this.filterMateriel = true;
                 this.filterSysteme = false;
                 this.filterManipulation = false;
                 this.filterReseau = false;
                 this.filterAutre = false;
                 this.nonClassifie = false;
                 this.filterEPMairie = false;
             }
             if(this.panne == "Non Classifié")
             {
                 this.nonClassifie = true;
                 this.filterMateriel = false;
                 this.filterSysteme = false;
                 this.filterManipulation = false;
                 this.filterReseau = false;
                 this.filterAutre = false;
                 this.filterEPMairie = false;

             }
             if(this.panne == "Problème EP Mairie")
             {
                 this.filterEPMairie = true;
                 this.nonClassifie = false;
                 this.filterMateriel = false;
                 this.filterSysteme = false;
                 this.filterManipulation = false;
                 this.filterReseau = false;
                 this.filterAutre = false;


             }


         }

     }

     isFirstConnexion()
         {
         console.log("this.camera", this.camera);
         if(this.camera == undefined)
         {
           this.camera = "";
         }
         console.log("this.panne", this.panne);
             if(this.camera == "" && this.panne == "")
             {
               console.log("lolilol pas de filtres");
               this.filterHorsLigne = false;
               this.filterImageDegrade = false;
               this.filterDefautEnregistrement = false;
               this.filterNonManipulable = false
               this.filterAffichageTuile = false;
               this.filterAffichageMur = false;
               this.filterVandalisme = false;
               this.filterVibration = false;
               this.filterSonore = false
               this.filterPoste = false;
               this.filterIntrusion = false;
               this.filterActivated = true;
             }
             else
             {
             console.log("lolilol filtres");
                 this.horsLigne = "Hors ligne";
                 this.imageDegrade = "Image dégradée / intermittente";
                 this.defautEnregistrement = "Défaut enregistrement";
                 this.nonManipulable =  'Difficilement / Non manipulable';
                 this.affichageTuile = 'Défaut affichage Tuile / Tache';
                 this.affichageMur = 'Défaut affichage mur d’image';
                 this.vandalisme = "Caméra Vandalisée";
                 this.vibration = "Défaut Capteur Vibration";
                 this.sonore =  'Défaut Micro / Haut-parleur';
                 this.poste = 'Défaut poste opérateur';
                 this.intrusion = 'Défaut alarme intrusion local technique';

                 this.filterHorsLigne = true;
                 this.filterImageDegrade = true;
                 this.filterDefautEnregistrement = true;
                 this.filterNonManipulable = true
                 this.filterAffichageTuile = true;
                 this.filterAffichageMur = true;
                 this.filterVandalisme = true;
                 this.filterVibration = true;
                 this.filterSonore = true
                 this.filterPoste = true;
                 this.filterIntrusion = true;

                 this.filterActivated = false;

                 if(this.panne == "Hors ligne")
                 {
                     this.filterHorsLigne = true;
                     this.filterImageDegrade = false;
                     this.filterDefautEnregistrement = false;
                     this.filterNonManipulable = false
                     this.filterAffichageTuile = false;
                     this.filterAffichageMur = false;
                     this.filterVandalisme = false;
                     this.filterVibration = false;
                     this.filterSonore = false
                     this.filterPoste = false;
                     this.filterIntrusion = false;
                 }
                 if(this.panne == "Image dégradée / intermittente")
                 {
                     this.filterHorsLigne = false;
                     this.filterImageDegrade = true;
                     this.filterDefautEnregistrement = false;
                     this.filterNonManipulable = false
                     this.filterAffichageTuile = false;
                     this.filterAffichageMur = false;
                     this.filterVandalisme = false;
                     this.filterVibration = false;
                     this.filterSonore = false
                     this.filterPoste = false;
                     this.filterIntrusion = false;
                 }
                 if(this.panne == "Défaut enregistrement")
                 {
                     this.filterHorsLigne = false;
                     this.filterImageDegrade = false;
                     this.filterDefautEnregistrement = true;
                     this.filterNonManipulable = false
                     this.filterAffichageTuile = false;
                     this.filterAffichageMur = false;
                     this.filterVandalisme = false;
                     this.filterVibration = false;
                     this.filterSonore = false
                     this.filterPoste = false;
                     this.filterIntrusion = false;
                 }
                 if(this.panne == "Difficilement / Non manipulable")
                 {
                     this.filterHorsLigne = false;
                     this.filterImageDegrade = false;
                     this.filterDefautEnregistrement = false;
                     this.filterNonManipulable = true
                     this.filterAffichageTuile = false;
                     this.filterAffichageMur = false;
                     this.filterVandalisme = false;
                     this.filterVibration = false;
                     this.filterSonore = false
                     this.filterPoste = false;
                     this.filterIntrusion = false;
                 }
                 if(this.panne == "Défaut affichage Tuile / Tache")
                 {
                     this.filterHorsLigne = false;
                     this.filterImageDegrade = false;
                     this.filterDefautEnregistrement = false;
                     this.filterNonManipulable = false
                     this.filterAffichageTuile = true;
                     this.filterAffichageMur = false;
                     this.filterVandalisme = false;
                     this.filterVibration = false;
                     this.filterSonore = false
                     this.filterPoste = false;
                     this.filterIntrusion = false;
                 }
                 if(this.panne == "Défaut affichage mur d’image")
                 {
                     this.filterHorsLigne = false;
                     this.filterImageDegrade = false;
                     this.filterDefautEnregistrement = false;
                     this.filterNonManipulable = false
                     this.filterAffichageTuile = false;
                     this.filterAffichageMur = true;
                     this.filterVandalisme = false;
                     this.filterVibration = false;
                     this.filterSonore = false
                     this.filterPoste = false;
                     this.filterIntrusion = false;
                 }
                 if(this.panne == "Caméra Vandalisée")
                 {
                     this.filterHorsLigne = false;
                     this.filterImageDegrade = false;
                     this.filterDefautEnregistrement = false;
                     this.filterNonManipulable = false
                     this.filterAffichageTuile = false;
                     this.filterAffichageMur = false;
                     this.filterVandalisme = true;
                     this.filterVibration = false;
                     this.filterSonore = false
                     this.filterPoste = false;
                     this.filterIntrusion = false;
                 }
                 if(this.panne == "Défaut Capteur Vibration")
                 {
                     this.filterHorsLigne = false;
                     this.filterImageDegrade = false;
                     this.filterDefautEnregistrement = false;
                     this.filterNonManipulable = false
                     this.filterAffichageTuile = false;
                     this.filterAffichageMur = false;
                     this.filterVandalisme = false;
                     this.filterVibration = true;
                     this.filterSonore = false
                     this.filterPoste = false;
                     this.filterIntrusion = false;
                 }

                 if(this.panne == "Défaut Micro / Haut-parleur")
                 {
                     this.filterHorsLigne = false;
                     this.filterImageDegrade = false;
                     this.filterDefautEnregistrement = false;
                     this.filterNonManipulable = false
                     this.filterAffichageTuile = false;
                     this.filterAffichageMur = false;
                     this.filterVandalisme = false;
                     this.filterVibration = false;
                     this.filterSonore = true
                     this.filterPoste = false;
                     this.filterIntrusion = false;
                 }
                 if(this.panne == "Défaut poste opérateur")
                 {
                     this.filterHorsLigne = false;
                     this.filterImageDegrade = false;
                     this.filterDefautEnregistrement = false;
                     this.filterNonManipulable = false
                     this.filterAffichageTuile = false;
                     this.filterAffichageMur = false;
                     this.filterVandalisme = false;
                     this.filterVibration = false;
                     this.filterSonore = false
                     this.filterPoste = true;
                     this.filterIntrusion = false;
                 }
                 if(this.panne == "Défaut alarme intrusion local technique")
                 {
                     this.filterHorsLigne = false;
                     this.filterImageDegrade = false;
                     this.filterDefautEnregistrement = false;
                     this.filterNonManipulable = false
                     this.filterAffichageTuile = false;
                     this.filterAffichageMur = false;
                     this.filterVandalisme = false;
                     this.filterVibration = false;
                     this.filterSonore = false
                     this.filterPoste = false;
                     this.filterIntrusion = true;
                 }


             }

         }



     generateChartOld() {
         this.tableAllTicketsForMoment = [];
         // console.log("this.dateTickets",this.dateTickets);
         var numberWeekDate: number = +this.datePipeString!;
         var getCurrentSemaine = numberWeekDate - this.numberClick;
         if(getCurrentSemaine <= 0)
         {
         getCurrentSemaine+=52;
         }

         if (this.dateTickets != undefined) {

           if (this.date == "1Semaine") {
           this.tableau1SemaineAll = [0, 0, 0, 0, 0, 0, 0];
           this.tableauManipulation1Semaine = [0, 0, 0, 0, 0, 0, 0];
           this.tableauSysteme1Semaine = [0, 0, 0, 0, 0, 0, 0];
           this.tableauReseau1Semaine = [0, 0, 0, 0, 0, 0, 0];
           this.tableauAutre1Semaine = [0, 0, 0, 0, 0, 0, 0];
           this.tableauMateriel1Semaine = [0, 0, 0, 0, 0, 0, 0];
           this.tableauEPMairie1Semaine = [0, 0, 0, 0, 0, 0, 0];
           this.tableauNonClassifie1Semaine  = [0, 0, 0, 0, 0, 0, 0];

           this.tableauSlaWeek  = [0, 0, 0, 0, 0, 0, 0];
           this.tableauManipulation1SemaineSla = [0, 0, 0, 0, 0, 0, 0];
           this.tableauSysteme1SemaineSla = [0, 0, 0, 0, 0, 0, 0];
           this.tableauReseau1SemaineSla = [0, 0, 0, 0, 0, 0, 0];
           this.tableauAutre1SemaineSla = [0, 0, 0, 0, 0, 0, 0];
           this.tableauMateriel1SemaineSla = [0, 0, 0, 0, 0, 0, 0];
           this.tableauEPMairie1SemaineSla = [0, 0, 0, 0, 0, 0, 0];
           this.tableauNonClassifie1SemaineSla  = [0, 0, 0, 0, 0, 0, 0];
           var curr = new Date; // get current date
           var first = curr.getDate() + 1 - curr.getDay(); // First day is the day of the month - the day of the week
           console.log("firstDay", first);
           var last = first + 6; // last day is the first day + 6
           console.log("lastDay", last);
           var b = 0;
           var tempDate;
           var testYear;
           for(var k = first; k <= last; k++)
           {

           tempDate = new Date();
           tempDate.setDate(k - this.numberClick * 7)
           //console.log("tempDate", tempDate);
           if(k == first)
           {
           testYear = tempDate.getFullYear();
           }
           //.setDate()
           var dateString = this.getStringDate(tempDate);
           this.tableau1SemaineX[b] = dateString;
           b++
           }
           this.dateForChart = "semaine : " + getCurrentSemaine + " année " + testYear;
           //this.tableau1SemaineX = [first, first + 1, first + 2,first + 3, first + 4, first + 5, first + 6]
           console.log("tableau1SemaineX", this.tableau1SemaineX);
           this.senddateForChart.emit(this.dateForChart);


           this.isFirstConnexionOld();
           this.tableau1SemaineXDay = ["lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi", "dimanche"];
           this.dateTickets.forEach((dateTransform, index) => {
           var dateTickets = dateTransform.date;
           var dateTicketsString = this.getStringDate(new Date(dateTickets));
           for(var i=0; i< this.tableau1SemaineX.length; i++)
           {
               if(dateTicketsString == this.tableau1SemaineX[i])
               {
               this.getSlaValue(dateTransform.sla.slaDate.day, dateTransform.sla.slaDate.hour, i)
                this.tableAllTicketsForMoment.push(dateTransform);
               this.tableau1SemaineAll[i]++;
               this.retrieveDataOldWeek(dateTransform.problemeOld, i)




               }


           }

           });
           this.calculateSlaValue(this.tableau1SemaineX.length, this.tableau1SemaineAll);
 this.getPanneForReinitialisationOld()
           this.options = {
                               Chart: {
                                   type: 'area',
                                   height: 700
                               },
                               title: {
                                   text: 'Evolution des Pannes'
                               },
                               credits: {
                                   enabled: false
                               },
                               yAxis: {
                                   title: {
                                       enabled: true,
                                       text: "Nombre de panne"

                                   }
                               },
                               xAxis: {
                                   categories: this.tableau1SemaineXDay,
                                   tickmarkPlacement: 'on',
                                   title: {
                                       enabled: true,
                                       text: this.dateForChart

                                   }
                               },
                               series: [{
                                   name: this.toutProbleme,
                                   visible: this.filterActivated,
                                   data: [this.tableau1SemaineAll[0],this.tableau1SemaineAll[1],this.tableau1SemaineAll[2],this.tableau1SemaineAll[3],
                                   this.tableau1SemaineAll[4],this.tableau1SemaineAll[5],this.tableau1SemaineAll[6]]
                               }, {
                                   name: this.manipulation,
                                   visible: this.filterManipulation,
                                                         data: [
                                   this.tableauManipulation1Semaine[0], this.tableauManipulation1Semaine[1], this.tableauManipulation1Semaine[2],
                                   this.tableauManipulation1Semaine[3], this.tableauManipulation1Semaine[4], this.tableauManipulation1Semaine[5],
                                   this.tableauManipulation1Semaine[6],
                                                         ]
                                                     },
                                                     {
                                   name: this.systeme,
                                   visible: this.filterSysteme,
                                   data: [
                                   this.tableauSysteme1Semaine[0], this.tableauSysteme1Semaine[1], this.tableauSysteme1Semaine[2],
                                   this.tableauSysteme1Semaine[3], this.tableauSysteme1Semaine[4], this.tableauSysteme1Semaine[5],
                                   this.tableauSysteme1Semaine[6], ]
                               },
                               {
                                   name: this.reseau,
                                   visible: this.filterReseau,
                                   data: [
                                   this.tableauReseau1Semaine[0], this.tableauReseau1Semaine[1], this.tableauReseau1Semaine[2], this.tableauReseau1Semaine[3],
                                   this.tableauReseau1Semaine[4], this.tableauReseau1Semaine[5], this.tableauReseau1Semaine[6]]
                               }, {
                                   name: this.autre,
                                   visible: this.filterAutre,
                                   data: [
                                   this.tableauAutre1Semaine[0], this.tableauAutre1Semaine[1], this.tableauAutre1Semaine[2], this.tableauAutre1Semaine[3],
                                   this.tableauAutre1Semaine[4], this.tableauAutre1Semaine[5], this.tableauAutre1Semaine[6]

                                   ]
                               },
                               {
                                   name: this.materiel,
                                   visible: this.filterMateriel,
                                   data: [
                                    this.tableauMateriel1Semaine[0], this.tableauMateriel1Semaine[1],this.tableauMateriel1Semaine[2],
                                    this.tableauMateriel1Semaine[3], this.tableauMateriel1Semaine[4], this.tableauMateriel1Semaine[5],
                                    this.tableauMateriel1Semaine[6]]
                           },
                           {
                                   name: this.EPMairie,
                                   visible: this.filterEPMairie,
                                   data: [
                                    this.tableauEPMairie1Semaine[0], this.tableauEPMairie1Semaine[1],this.tableauEPMairie1Semaine[2],
                                    this.tableauEPMairie1Semaine[3], this.tableauEPMairie1Semaine[4], this.tableauEPMairie1Semaine[5],
                                    this.tableauEPMairie1Semaine[6]]
                           },
                            {
                                     name: this.nonClassifieTexte,
                                     visible: this.nonClassifie,
                                     data: [
                                      this.tableauNonClassifie1Semaine [0], this.tableauNonClassifie1Semaine [1],this.tableauNonClassifie1Semaine [2],
                                      this.tableauNonClassifie1Semaine [3], this.tableauNonClassifie1Semaine [4], this.tableauNonClassifie1Semaine [5],
                                      this.tableauNonClassifie1Semaine [6]]
                             }

                           ]}

                           Highcharts.chart('container', this.options);
 }


             if (this.date == "1Mois") {

                 this.tableauManipulation1Mois = [];
                 this.tableauSysteme1Mois = [];
                 this.tableauReseau1Mois = [];
                 this.tableauAutre1Mois = [];
                 this.tableauMateriel1Mois = [];
                 this.tableauNonClassifie1Mois = [];
                 this.tableauEPMairie1Mois = [];

                 this.tableauSlaWeek = [];
                 this.tableauManipulation1MoisSla = [];
                 this.tableauSysteme1MoisSla = [];
                 this.tableauReseau1MoisSla = [];
                 this.tableauAutre1MoisSla = [];
                 this.tableauMateriel1MoisSla = [];
                 this.tableauNonClassifie1MoisSla = [];
                 this.tableauEPMairie1MoisSla = [];

                 this.getAllDayForOneMonthOld(this.dateTickets);
                 this.getPanneForReinitialisationOld();
                 console.log("this.reseau",  this.tableauReseau1Mois);
                 this.tabDate.filter(n => n)
                 this.tabDate = this.tabDay;
                 console.log("this.tabDate", this.tabDate);
                 let modifiedDate = new Date()
                 let oneMonthBeforeDate = modifiedDate.setMonth(modifiedDate.getMonth() - 1);
                 console.log("this.this.tabAll1Mois", this.tabAll1Mois);
                 this.tabAll1Mois.reverse();
                 this.tableauManipulation1Mois.reverse();
                 this.tableauSysteme1Mois.reverse();
                 this.tableauReseau1Mois.reverse();
                 this.tableauAutre1Mois.reverse();
                 this.tableauMateriel1Mois.reverse();
                 this.tableauEPMairie1Mois.reverse();
                 this.tableauNonClassifie1Mois.reverse();


                 this.tableauSlaWeek.reverse();
                 this.tableauManipulation1MoisSla.reverse();
                 this.tableauSysteme1MoisSla.reverse();
                 this.tableauReseau1MoisSla.reverse();
                 this.tableauAutre1MoisSla.reverse();
                 this.tableauMateriel1MoisSla.reverse();
                 this.tableauEPMairie1MoisSla.reverse();
                 this.tableauNonClassifie1MoisSla.reverse();

         this.dateForChart = this.dateToDisplay;
         this.senddateForChart.emit(this.dateForChart);
                 this.options = {
                     Chart: {
                         type: 'area',
                         height: 700
                     },
                     title: {
                         text: 'Evolution des Pannes'
                     },
                     credits: {
                         enabled: false
                     },

                     yAxis: {
                         title: {
                             enabled: true,
                             text: "Nombre de panne"

                         }
                     },
                     xAxis: {
                         categories: this.tabDate,
                         tickmarkPlacement: 'on',
                         title: {
                             enabled: true,
                             text: this.dateToDisplay
                         }
                     },
                     series: [{
                         name: this.toutProbleme,
                         visible: this.filterActivated,
                         data: this.tabAll1Mois,
                     }, {
                         name: this.manipulation,
                         visible: this.filterManipulation,
                         data: this.tableauManipulation1Mois
                     },
                     {
                         name: this.systeme,
                         visible: this.filterSysteme,
                         data: this.tableauSysteme1Mois
                     },
                     {
                         name: this.reseau,
                         visible: this.filterReseau,
                         data: this.tableauReseau1Mois
                     }, {
                         name: this.autre,
                         visible: this.filterAutre,
                         data: this.tableauAutre1Mois
                     },
                     {
                         name: this.materiel,
                         visible: this.filterMateriel,
                         data: this.tableauMateriel1Mois
                 },
                 {
                         name: this.EPMairie,
                         visible: this.filterEPMairie,
                         data: this.tableauEPMairie1Mois
                  },
                 {
                         name: this.nonClassifieTexte,
                         visible: this.nonClassifie,
                         data: this.tableauNonClassifie1Mois
                 }
                 ]}

                 Highcharts.chart('container', this.options);
             }


         }
         if (this.date == "6Mois") {
             this.getWeekForChart();
             this.getDataFor6MonthChartOld();


         }
         if (this.date == "1An") {
             console.log("1an");
             this.getDataFor1YearChartOld()
         }
         this.sendTableTickets.emit(this.tableAllTicketsForMoment);
     }

     ngOnChanges() {
         //this.numberClick = 0;
         console.log("checkedValue : ", this.checkedValue);


         this.dateTickets = this.inputTickets;
         console.log("camera", this.camera);
             if(this.checkedValue == "nouveau")
             {
                     this.isFirstConnexion();
                     this.generateChart();
             }
             else
             {
                     this.isFirstConnexionOld()
                     this.generateChartOld();
             }

     }


     ngOnInit() {
     console.log("this.checkedValue : ", this.checkedValue );
     if(this.checkedValue == "nouveau")
     {
             this.generateChart();
     }
     else
     {
             this.generateChartOld();
     }

     }



     getWeekForChart() {
         var j = 0;
         //console.log("6mois");
         //console.log("week", this.datePipeString);
         var numberWeekDate: number = +this.datePipeString!;
         numberWeekDate = numberWeekDate - (26 * this.numberClick);
         for (var i = 0; i < 26; i++) {
             if (numberWeekDate <= 0) {
                 numberWeekDate = numberWeekDate + 52;
             }
             //console.log("numberWeekDate", numberWeekDate);
             this.tab6Mois[j] = numberWeekDate;

             numberWeekDate = numberWeekDate - 1;
             j++;

         }
         this.tabDate = this.tab6Mois.reverse()
         console.log("tab6Mois", this.tab6Mois);
     }

     getDataFor6MonthChartOld() {
         this.tableauTest = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauManipulation6Mois = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauSysteme6Mois = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauReseau6Mois = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauAutre6Mois = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauMateriel6Mois = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauEPMairie6Mois = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauNonClassifie6Mois = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];


         this.tableauSlaWeek = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauManipulation6MoisSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauSysteme6MoisSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauReseau6MoisSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauAutre6MoisSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauMateriel6MoisSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauEPMairie6MoisSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauNonClassifie6MoisSla = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

         var curr = new Date; // get current date

          var first = curr.getDate() + 1 - curr.getDay(); // First day is the day of the month - the day of the week
                   console.log("firstDay", first);
                   var last = first + 6; // last day is the first day + 6
                   console.log("lastDay", last);

                 var firstday = new Date(curr.setDate(first));
                 firstday.setDate(firstday.getDate() - (182 * this.numberClick))
                 //this.tab6MoisValue[0] = firstday;
                 var lastday = new Date(curr.setDate(last));
                 lastday.setDate(lastday.getDate() - (182 * this.numberClick))

                     //lastday.setMonth(lastday.getMonth() + 1)

                 firstday.setHours(1);
                 firstday.setMinutes(1)
                 lastday.setHours(1);
                 lastday.setMinutes(1)
                 this.tab6MoisValue[0] = lastday.toISOString();
                 this.tab6MoisValue[1] = firstday.toISOString();
                 var tableauPourLeTest: any[] = [];


                 for (var i = 1; i < 26; i++) {
                     console.log("firstday", firstday.toISOString());
                     firstday.setDate(firstday.getDate() - 7 );
                     this.tab6MoisValue[i + 1] = firstday.toISOString();
                     //console.log("test", firstday);
                 }
                 console.log("this.tab6MoisValue", this.tab6MoisValue);
         this.dateForChart = "6 mois";
         this.senddateForChart.emit(this.dateForChart);
         //console.log("this.dateTickets", this.dateTickets);
         this.dateTickets.forEach((dateTransform, index) => {
             let test = new Date(dateTransform.date);
              if(dateTransform.camera == this.camera || this.camera == undefined || this.camera == "")
              {
             for (var i = 0; i < this.tab6MoisValue.length - 1; i++) {
                 let leTabDeI = new Date(this.tab6MoisValue[i]);
                 let leTabDeI2 = new Date(this.tab6MoisValue[i + 1]);
                 //console.log("test", test)

                 //console.log("valueTab", this.tab6MoisValue[i])
                 if (test < leTabDeI && test > leTabDeI2) {
                 this.getSlaValue(dateTransform.sla.slaDate.day, dateTransform.sla.slaDate.hour, i)
                   this.tableAllTicketsForMoment.push(dateTransform);
                   this.retrieveDataOld6Month(dateTransform.problemeOld, i)


                 }
             }


         }
         });
         this.calculateSlaValue(this.tab6MoisValue.length, this.tableauTest);
         this.tableauTest.reverse();
         this.tableauManipulation6Mois.reverse();
         this.tableauSysteme6Mois.reverse();
         this.tableauReseau6Mois.reverse();
         this.tableauAutre6Mois.reverse();
         this.tableauMateriel6Mois.reverse();
         this.tableauEPMairie6Mois.reverse();

         this.getPanneForReinitialisationOld()
         console.log("tableauTest", this.tableauTest);

         this.options = {
             Chart: {
                 type: 'scatter',
                 height: 700
             },
             title: {
                 text: 'Evolution des Pannes'
             },
             credits: {
                 enabled: false
             },
             yAxis: {
                 title: {
                     enabled: true,
                     text: "Nombre de panne"

                 }
             },
             xAxis: {
                 categories: this.tabDate,
                 tickmarkPlacement: 'on',
                 title: {
                     enabled: true,
                     text: 'semaine'
                 }
             },
             series: [{
                 name: 'Tout les problèmes',
                 visible: this.filterActivated,
                 data: [this.tableauTest[2], this.tableauTest[3], this.tableauTest[4], this.tableauTest[5],
                 this.tableauTest[6], this.tableauTest[7], this.tableauTest[8], this.tableauTest[9], this.tableauTest[10], this.tableauTest[11],
                 this.tableauTest[12], this.tableauTest[13], this.tableauTest[14], this.tableauTest[15], this.tableauTest[16], this.tableauTest[17],
                 this.tableauTest[18], this.tableauTest[19], this.tableauTest[20], this.tableauTest[21], this.tableauTest[22], this.tableauTest[23],
                 this.tableauTest[24], this.tableauTest[25], this.tableauTest[26], this.tableauTest[27]]

             }, {
                  name: this.manipulation,
                  visible: this.filterManipulation,
                       data: [
                       this.tableauManipulation6Mois[2], this.tableauManipulation6Mois[3], this.tableauManipulation6Mois[4], this.tableauManipulation6Mois[5],
                       this.tableauManipulation6Mois[6], this.tableauManipulation6Mois[7], this.tableauManipulation6Mois[8], this.tableauManipulation6Mois[9], this.tableauManipulation6Mois[10], this.tableauManipulation6Mois[11],
                       this.tableauManipulation6Mois[12], this.tableauManipulation6Mois[13], this.tableauManipulation6Mois[14], this.tableauManipulation6Mois[15], this.tableauManipulation6Mois[16], this.tableauManipulation6Mois[17],
                       this.tableauManipulation6Mois[18], this.tableauManipulation6Mois[19], this.tableauManipulation6Mois[20], this.tableauManipulation6Mois[21], this.tableauManipulation6Mois[22], this.tableauManipulation6Mois[23],
                       this.tableauManipulation6Mois[24], this.tableauManipulation6Mois[25], this.tableauManipulation6Mois[26], this.tableauManipulation6Mois[27]
                       ]
                   },
                   {
                       name: this.systeme,
                       visible: this.filterSysteme,
                       data: [
                       this.tableauSysteme6Mois[2], this.tableauSysteme6Mois[3], this.tableauSysteme6Mois[4], this.tableauSysteme6Mois[5],
                       this.tableauSysteme6Mois[6], this.tableauSysteme6Mois[7], this.tableauSysteme6Mois[8], this.tableauSysteme6Mois[9], this.tableauSysteme6Mois[10], this.tableauSysteme6Mois[11],
                       this.tableauSysteme6Mois[12], this.tableauSysteme6Mois[13], this.tableauSysteme6Mois[14], this.tableauSysteme6Mois[15], this.tableauSysteme6Mois[16], this.tableauSysteme6Mois[17],
                       this.tableauSysteme6Mois[18], this.tableauSysteme6Mois[19], this.tableauSysteme6Mois[20], this.tableauSysteme6Mois[21], this.tableauSysteme6Mois[22], this.tableauSysteme6Mois[23],
                       this.tableauSysteme6Mois[24], this.tableauSysteme6Mois[25], this.tableauSysteme6Mois[26], this.tableauSysteme6Mois[27]
                       ]
                   },
                   {
                       name: this.reseau,
                       visible: this.filterReseau,
                       data: [
                       this.tableauReseau6Mois[2], this.tableauReseau6Mois[3], this.tableauReseau6Mois[4], this.tableauReseau6Mois[5],
                       this.tableauReseau6Mois[6], this.tableauReseau6Mois[7], this.tableauReseau6Mois[8], this.tableauReseau6Mois[9], this.tableauReseau6Mois[10], this.tableauReseau6Mois[11],
                       this.tableauReseau6Mois[12], this.tableauReseau6Mois[13], this.tableauReseau6Mois[14], this.tableauReseau6Mois[15], this.tableauReseau6Mois[16], this.tableauReseau6Mois[17],
                       this.tableauReseau6Mois[18], this.tableauReseau6Mois[19], this.tableauReseau6Mois[20], this.tableauReseau6Mois[21], this.tableauReseau6Mois[22], this.tableauReseau6Mois[23],
                       this.tableauReseau6Mois[24], this.tableauReseau6Mois[25], this.tableauReseau6Mois[26], this.tableauReseau6Mois[27]
                       ]
                   }, {
                       name: this.autre,
                       visible: this.filterAutre,
                       data: [
                       this.tableauAutre6Mois[2], this.tableauAutre6Mois[3], this.tableauAutre6Mois[4], this.tableauAutre6Mois[5],
                       this.tableauAutre6Mois[6], this.tableauAutre6Mois[7], this.tableauAutre6Mois[8], this.tableauAutre6Mois[9], this.tableauAutre6Mois[10], this.tableauAutre6Mois[11],
                       this.tableauAutre6Mois[12], this.tableauAutre6Mois[13], this.tableauAutre6Mois[14], this.tableauAutre6Mois[15], this.tableauAutre6Mois[16], this.tableauAutre6Mois[17],
                       this.tableauAutre6Mois[18], this.tableauAutre6Mois[19], this.tableauAutre6Mois[20], this.tableauAutre6Mois[21], this.tableauAutre6Mois[22], this.tableauAutre6Mois[23],
                       this.tableauAutre6Mois[24], this.tableauAutre6Mois[25], this.tableauAutre6Mois[26], this.tableauAutre6Mois[27]

                       ]
                   },
                   {
                       name: this.materiel,
                       visible: this.filterMateriel,
                       data: [
                       this.tableauMateriel6Mois[2], this.tableauMateriel6Mois[3], this.tableauMateriel6Mois[4], this.tableauMateriel6Mois[5],
                       this.tableauMateriel6Mois[6], this.tableauMateriel6Mois[7], this.tableauMateriel6Mois[8], this.tableauMateriel6Mois[9], this.tableauMateriel6Mois[10], this.tableauMateriel6Mois[11],
                       this.tableauMateriel6Mois[12], this.tableauMateriel6Mois[13], this.tableauMateriel6Mois[14], this.tableauMateriel6Mois[15], this.tableauMateriel6Mois[16], this.tableauMateriel6Mois[17],
                       this.tableauMateriel6Mois[18], this.tableauMateriel6Mois[19], this.tableauMateriel6Mois[20], this.tableauMateriel6Mois[21], this.tableauMateriel6Mois[22], this.tableauMateriel6Mois[23],
                       this.tableauMateriel6Mois[24], this.tableauMateriel6Mois[25], this.tableauMateriel6Mois[26], this.tableauMateriel6Mois[27]
                       ]
                   },
                   {
                       name: this.EPMairie,
                       visible: this.filterEPMairie,
                       data: [
                       this.tableauEPMairie6Mois[2], this.tableauEPMairie6Mois[3], this.tableauEPMairie6Mois[4], this.tableauEPMairie6Mois[5],
                       this.tableauEPMairie6Mois[6], this.tableauEPMairie6Mois[7], this.tableauEPMairie6Mois[8], this.tableauEPMairie6Mois[9], this.tableauEPMairie6Mois[10], this.tableauEPMairie6Mois[11],
                       this.tableauEPMairie6Mois[12], this.tableauEPMairie6Mois[13], this.tableauEPMairie6Mois[14], this.tableauEPMairie6Mois[15], this.tableauEPMairie6Mois[16], this.tableauEPMairie6Mois[17],
                       this.tableauEPMairie6Mois[18], this.tableauEPMairie6Mois[19], this.tableauEPMairie6Mois[20], this.tableauEPMairie6Mois[21], this.tableauEPMairie6Mois[22], this.tableauEPMairie6Mois[23],
                       this.tableauEPMairie6Mois[24], this.tableauEPMairie6Mois[25], this.tableauEPMairie6Mois[26], this.tableauEPMairie6Mois[27]
                           ]
                       },
 {
                       name: this.nonClassifieTexte,
                       visible: this.nonClassifie,
                       data: [
                       this.tableauNonClassifie6Mois[2], this.tableauNonClassifie6Mois[3], this.tableauNonClassifie6Mois[4], this.tableauNonClassifie6Mois[5],
                       this.tableauNonClassifie6Mois[6], this.tableauNonClassifie6Mois[7], this.tableauNonClassifie6Mois[8], this.tableauNonClassifie6Mois[9], this.tableauNonClassifie6Mois[10], this.tableauNonClassifie6Mois[11],
                       this.tableauNonClassifie6Mois[12], this.tableauNonClassifie6Mois[13], this.tableauNonClassifie6Mois[14], this.tableauNonClassifie6Mois[15], this.tableauNonClassifie6Mois[16], this.tableauNonClassifie6Mois[17],
                       this.tableauNonClassifie6Mois[18], this.tableauNonClassifie6Mois[19], this.tableauNonClassifie6Mois[20], this.tableauNonClassifie6Mois[21], this.tableauNonClassifie6Mois[22], this.tableauNonClassifie6Mois[23],
                       this.tableauNonClassifie6Mois[24], this.tableauNonClassifie6Mois[25], this.tableauNonClassifie6Mois[26], this.tableauNonClassifie6Mois[27]
                       ]
                   }

                   ]
         }

         Highcharts.chart('container', this.options);

         //console.log("firstday", firstday);
         //console.log("lastday", lastday);
     }




     getDataFor1YearChartOld() {
         this.tab1YearValue = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauManipulation = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauSysteme = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauReseau = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauAutre = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauMateriel = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauEPMairie = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauSlaWeek = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         //for 1 year
         var currForMonth = new Date;
         console.log("currForMonth", currForMonth.getMonth() + 1);
         //this.tableauYearX[0] = currForMonth.getMonth() + 1
         currForMonth.setMonth(currForMonth.getMonth() + 1)
         /*for (var i = 0; i <= 12; i++) {
             currForMonth.setMonth(currForMonth.getMonth() - 1);
             if (currForMonth.getMonth() < 1) {
                 currForMonth.setMonth(currForMonth.getMonth() + 12);
             }

         }*/
         this.tableauYearX = ["1", "2","3", "4", "5","6", "7", "8", "9", "10", "11","12"];
         var newDate = new Date();
         var currentYear = newDate.getFullYear();
         var firstMonth = new Date(currentYear + '-01-01T01:00:00');
         firstMonth.setFullYear(firstMonth.getFullYear() - (1 * this.numberClick));
         //console.log("firstMonth", firstMonth)
         var currentUsedYear = firstMonth.getFullYear();
         this.dateForChart = "année : " + currentUsedYear;
         this.senddateForChart.emit(this.dateForChart);
         for (var i = 0; i <= 12; i++) {
             this.tab1YearReferenceValue[i] = firstMonth.toISOString();
             console.log("firstMonth", firstMonth.toISOString())
             firstMonth.setMonth(firstMonth.getMonth() + 1);

             //console.log("valeur du mois", currForMonthData.getMonth())
             /*if(currForMonthData.getMonth() == 1)
             {
             break;
             }*/
             //console.log("firstday", firstday.toISOString());

             //console.log("this.tab", currForMonthData.toISOString());
         }

         console.log("this.tab1YearReferenceValue", this.tab1YearReferenceValue);
         console.log("this.tableauYearX", this.tableauYearX);



         console.log("this.dateTickets", this.dateTickets);
         console.log("tab1YearReferenceValue", this.tab1YearReferenceValue);
         this.dateTickets.forEach((dateTransform, index) => {
             let test = new Date(dateTransform.date);
             if(dateTransform.camera == this.camera  || this.camera == undefined || this.camera == "")
             {
             for (var i = 0; i < this.tab1YearReferenceValue.length - 1; i++) {
                 let leTabDeI = new Date(this.tab1YearReferenceValue[i]);
                 let leTabDeI2 = new Date(this.tab1YearReferenceValue[i + 1]);
                 if (test > leTabDeI && test < leTabDeI2) {
                 this.getSlaValue(dateTransform.sla.slaDate.day, dateTransform.sla.slaDate.hour, i)
                  this.tableAllTicketsForMoment.push(dateTransform);
                 console.log("on passe par la");
                 this.retrieveDataOld1Year(dateTransform.problemeOld, i)


                 }
             }

 }
         });
         this.calculateSlaValue(this.tab1YearReferenceValue.length, this.tab1YearValue);
         console.log("this.tableauMateriel", this.tableauMateriel);
         console.log("this.tableauSysteme", this.tableauSysteme);
         this.getPanneForReinitialisationOld()
         console.log("tableauTest", this.tab1YearValue);
         this.options = {
             Chart: {
                 type: 'spline',
                 height: 700
             },
             title: {
                 text: 'Evolution des Pannes'
             },
             credits: {
                 enabled: false
             },
             yAxis: {
                 title: {
                     enabled: true,
                     text: "Nombre de panne"

                 }
             },
             xAxis: {
                 categories: this.tableauYearX,
                 tickmarkPlacement: 'on',
                 title: {
                     enabled: true,
                     text: 'année : ' + currentUsedYear
                 }
             },
             series: [{
                 name: 'Tout les problèmes',
                 visible: this.filterActivated,
                 data: this.tab1YearValue
             }, {
                 name: this.manipulation,
                 visible: this.filterManipulation,
                 data: this.tableauManipulation
             },
             {
                 name: this.systeme,
                 visible: this.filterSysteme,
                 data: this.tableauSysteme
             },
             {
                 name: this.reseau,
                 visible: this.filterReseau,
                 data: this.tableauReseau
             }, {
                 name: this.autre,
                 visible: this.filterAutre,
                 data: this.tableauAutre
             },
             {
                 name: this.materiel,
                 visible: this.filterMateriel,
                 data: this.tableauMateriel
             },
             {
                 name: this.EPMairie,
                 visible: this.filterEPMairie,
                 data: this.tableauEPMairie
             },
             {
                 name: this.nonClassifieTexte,
                 visible: this.nonClassifie,
                 data: this.tableauNonClassifie
             }

             ]
         }

         Highcharts.chart('container', this.options);

         //console.log("firstday", firstday);
         //console.log("lastday", lastday);
     }


















 getAllDayForOneMonthOld(tab: any) {

         //console.log("ticketInMethod", tab);
 var bonTableau1Mois:any = [];
         var referenceDate = this.getStringDate(this.newDate);
         var date = new Date();
         var firstDay = new Date(date.getFullYear(), date.getMonth() - this.numberClick, 1 );
         var lastDay = new Date(date.getFullYear(), date.getMonth() + 1 - this.numberClick, 0);
         var firstDayNumber = firstDay.getDate();
         var lastDayNumber = lastDay.getDate();
          console.log("lastDayNumber", lastDayNumber);
         for(var i = 1; i <= lastDayNumber + 1; i++)
         {

          console.log("firstDay8", firstDay.toISOString());

          var currentDate = new Date(date.getFullYear(), date.getMonth() - this.numberClick, 1);
          this.dateToDisplay = currentDate.toLocaleString('default', { month: 'long'}) + " " + currentDate.getFullYear();

          //currentDate.setDate(currentDate.getDate() + 1);
          currentDate.setHours(1);
          currentDate.setDate(currentDate.getDate() + i - 1);
          bonTableau1Mois[i-1] = currentDate.toISOString();
         }
                 bonTableau1Mois.reverse();
         console.log("bonTableau1Mois", bonTableau1Mois)


         console.log("firstDay", firstDayNumber);
         console.log("lastDayNumber", lastDayNumber);
         this.tabDay = [];
         for (var i = lastDayNumber; i > 0; i--) {
             var currentDate = new Date();
             //currentDate.setMonth(currentDate.getMonth() - this.numberClick);
             currentDate.setDate(currentDate.getDate() - i);
             this.tabDay[i - 1] = i;

             var currentDateModified = this.getStringDate(currentDate);

             this.tabReference[i] = currentDateModified;
         }
         this.tabAll1Mois = [];
         this.tableauManipulation1Mois = [];
         this.tableauSysteme1Mois = [];
         this.tableauReseau1Mois = [];
         this.tableauAutre1Mois = [];
         this.tableauMateriel1Mois = [];
         this.tableauEPMairie1Mois = [];
         this.tableauSlaWeek = [];

         for(var i=0; i<lastDayNumber; i++)
         {
         this.tabAll1Mois[i] = 0;
         this.tableauManipulation1Mois[i] = 0;
         this.tableauSysteme1Mois[i] = 0;
         this.tableauReseau1Mois[i] = 0;
         this.tableauAutre1Mois[i] = 0;
         this.tableauMateriel1Mois[i] = 0;
         this.tableauEPMairie1Mois[i] = 0;
         this.tableauNonClassifie1Mois[i] = 0;
         this.tableauSlaWeek[i] = 0;
         }

         console.log("this.tabAll1Mois", this.tabAll1Mois);
         //console.log("tab", tab);

         tab.forEach((dateTransform, index) => {

         let test = new Date(dateTransform.date);
         if(dateTransform.camera == this.camera  || this.camera == undefined || this.camera == "")
                     {
                     for (var i = 0; i < this.tabDay.length; i++) {
                     //console.log("new Date(bonTableau1Mois[i]", new Date(bonTableau1Mois[i]));
                         let leTabDeI = new Date(bonTableau1Mois[i]);
                         let leTabDeI2 = new Date(bonTableau1Mois[i + 1]);
                         //console.log("leTabDeI",leTabDeI);
                         //console.log("leTabDeI2",leTabDeI2);
                         //console.log("test", test)
                         if (test < leTabDeI && test > leTabDeI2) {
                         this.getSlaValue(dateTransform.sla.slaDate.day, dateTransform.sla.slaDate.hour, i)
                         console.log("on rentre par la");
                         this.tableAllTicketsForMoment.push(dateTransform);
                         this.tabAll1Mois[i]++;
                         //console.log("on rentre bien la");
                         this.retrieveDataOld1Month(dateTransform.problemeOld, i)



                         }
                     }

             }


         });
 this.calculateSlaValue(this.tabDay.length, this.tabAll1Mois);
     }










































 generateChart() {
         this.tableAllTicketsForMoment = [];
         // console.log("this.dateTickets",this.dateTickets);
         var numberWeekDate: number = +this.datePipeString!;
         var getCurrentSemaine = numberWeekDate - this.numberClick;
         if(getCurrentSemaine <= 0)
         {
         getCurrentSemaine+=52;
         }


         if (this.dateTickets != undefined) {

           if (this.date == "1Semaine") {
           this.getOneWeekNew(getCurrentSemaine)

           }


             if (this.date == "1Mois") {

                  this.tableauHorsLigne1Mois = [];
                  this.tableauImageDegrade1Mois = [];
                  this.tableauDefautEnregistrement1Mois = [];
                  this.tableauNonManipulable1Mois = [];
                  this.tableauDefautAffichageTuile1Mois = [];
                  this.tableauDefautAffichageMur1Mois = [];
                  this.tableauDefautVandalisme1Mois = [];
                  this.tableauDefautVibration1Mois = [];
                  this.tableauDefautSonore1Mois = [];
                  this.tableauDefautPoste1Mois = [];
                  this.tableauDefautIntrusion1Mois = [];
                  this.tableauSlaWeek = [];

                 this.getAllDayForOneMonth(this.dateTickets);
                 this.getPanneForReinitialisation();
                 this.tabDate.filter(n => n)
                 this.tabDate = this.tabDay;
                 console.log("this.tabDate", this.tabDate);
                 let modifiedDate = new Date()
                 let oneMonthBeforeDate = modifiedDate.setMonth(modifiedDate.getMonth() - 1);
                 console.log("this.this.tabAll1Mois", this.tabAll1Mois);
                 this.tabAll1Mois.reverse();
                 this.tableauHorsLigne1Mois.reverse();
                 this.tableauImageDegrade1Mois.reverse();
                 this.tableauDefautEnregistrement1Mois.reverse();
                 this.tableauNonManipulable1Mois.reverse();
                 this.tableauDefautAffichageTuile1Mois.reverse();
                 this.tableauDefautAffichageMur1Mois.reverse();
                 this.tableauDefautVandalisme1Mois.reverse();
                 this.tableauDefautVibration1Mois.reverse();
                 this.tableauDefautSonore1Mois.reverse();
                 this.tableauDefautPoste1Mois.reverse();
                 this.tableauDefautIntrusion1Mois.reverse();
                 this.tableauSlaWeek.reverse();

         this.dateForChart = this.dateToDisplay;
         this.senddateForChart.emit(this.dateForChart);
                 this.options = {
                     Chart: {
                         type: 'area',
                         height: 700
                     },
                     title: {
                         text: 'Evolution des Pannes'
                     },
                     credits: {
                         enabled: false
                     },

                     yAxis: {
                         title: {
                             enabled: true,
                             text: "Nombre de panne"

                         }
                     },
                     xAxis: {
                         categories: this.tabDate,
                         tickmarkPlacement: 'on',
                         title: {
                             enabled: true,
                             text: this.dateToDisplay
                         }
                     },
                     series: [{
                         name: this.toutProbleme,
                         visible: this.filterActivated,
                         data: this.tabAll1Mois,
                     }, {
                         name: this.horsLigne,
                         visible: this.filterHorsLigne,
                         data: this.tableauHorsLigne1Mois
                     },
                     {
                         name: this.imageDegrade,
                         visible: this.filterImageDegrade,
                         data: this.tableauImageDegrade1Mois
                     },
                     {
                         name: this.defautEnregistrement,
                         visible: this.filterDefautEnregistrement,
                         data: this.tableauDefautEnregistrement1Mois
                     }, {
                         name: this.nonManipulable,
                         visible: this.filterNonManipulable,
                         data: this.tableauNonManipulable1Mois
                     },
                     {
                         name: this.affichageTuile,
                         visible: this.filterAffichageTuile,
                         data: this.tableauDefautAffichageTuile1Mois
                 },
                 {
                         name: this.affichageMur,
                         visible: this.filterAffichageMur,
                         data: this.tableauDefautAffichageMur1Mois
                 },
                  {
                          name: this.vandalisme,
                          visible: this.filterVandalisme,
                          data: this.tableauDefautVandalisme1Mois
                      },
                      /*{
                          name: this.vibration,
                          visible: this.filterVibration,
                          data: this.tableauDefautVibration1Mois
                      },
                      {
                          name: this.sonore,
                          visible: this.filterSonore,
                          data: this.tableauDefautSonore1Mois
                      },*/
                      {
                          name: this.poste,
                          visible: this.filterPoste,
                          data: this.tableauDefautPoste1Mois
                  }/*,
                  {
                          name: this.intrusion,
                          visible: this.filterIntrusion,
                          data: this.tableauDefautIntrusion1Mois
                  }*/
                 ]}

                 Highcharts.chart('container', this.options);
             }


         }
         if (this.date == "6Mois") {
             this.getWeekForChart();
             this.getDataFor6MonthChart();


         }
         if (this.date == "1An") {
             console.log("1an");
             this.getDataFor1YearChart()
         }
         this.sendTableTickets.emit(this.tableAllTicketsForMoment);
     }



 getAllDayForOneMonth(tab: any) {

         //console.log("ticketInMethod", tab);
 var bonTableau1Mois:any = [];
         var referenceDate = this.getStringDate(this.newDate);
         var date = new Date();
         var firstDay = new Date(date.getFullYear(), date.getMonth() - this.numberClick, 1 );
         var lastDay = new Date(date.getFullYear(), date.getMonth() + 1 - this.numberClick, 0);
         var firstDayNumber = firstDay.getDate();
         var lastDayNumber = lastDay.getDate();
          console.log("lastDayNumber", lastDayNumber);
         for(var i = 1; i <= lastDayNumber + 1; i++)
         {

          console.log("firstDay8", firstDay.toISOString());

          var currentDate = new Date(date.getFullYear(), date.getMonth() - this.numberClick, 1);
          this.dateToDisplay = currentDate.toLocaleString('default', { month: 'long'}) + " " + currentDate.getFullYear();

          //currentDate.setDate(currentDate.getDate() + 1);
          currentDate.setHours(1);
          currentDate.setDate(currentDate.getDate() + i - 1);
          bonTableau1Mois[i-1] = currentDate.toISOString();
         }
                 bonTableau1Mois.reverse();
         console.log("bonTableau1Mois", bonTableau1Mois)


         console.log("firstDay", firstDayNumber);
         console.log("lastDayNumber", lastDayNumber);
         this.tabDay = [];
         for (var i = lastDayNumber; i > 0; i--) {
             var currentDate = new Date();
             //currentDate.setMonth(currentDate.getMonth() - this.numberClick);
             currentDate.setDate(currentDate.getDate() - i);
             this.tabDay[i - 1] = i;

             var currentDateModified = this.getStringDate(currentDate);

             this.tabReference[i] = currentDateModified;
         }
         this.tabAll1Mois = [];
         this.tableauHorsLigne1Mois = [];
         this.tableauImageDegrade1Mois = [];
         this.tableauDefautEnregistrement1Mois = [];
         this.tableauNonManipulable1Mois = [];
         this.tableauDefautAffichageTuile1Mois = [];
         this.tableauDefautAffichageMur1Mois = [];
         this.tableauDefautVandalisme1Mois = [];
         this.tableauDefautVibration1Mois = [];
         this.tableauDefautSonore1Mois = [];
         this.tableauDefautPoste1Mois = [];
         this.tableauDefautIntrusion1Mois = [];
         this.tableauSlaWeek = [];
         for(var i=0; i<lastDayNumber; i++)
         {
         this.tabAll1Mois[i] = 0;
         this.tableauHorsLigne1Mois[i] = 0;
         this.tableauImageDegrade1Mois[i] = 0;
         this.tableauDefautEnregistrement1Mois[i] = 0;
         this.tableauNonManipulable1Mois[i] = 0;
         this.tableauDefautAffichageTuile1Mois[i] = 0;
         this.tableauDefautAffichageMur1Mois[i] = 0;
         this.tableauDefautVandalisme1Mois[i] = 0;
         this.tableauDefautVibration1Mois[i] = 0;
         this.tableauDefautSonore1Mois[i] = 0;
         this.tableauDefautPoste1Mois[i] = 0;
         this.tableauDefautIntrusion1Mois[i] = 0;
         this.tableauSlaWeek[i] = 0;
         }

         console.log("this.tabAll1Mois", this.tabAll1Mois);
         //console.log("tab", tab);

         tab.forEach((dateTransform, index) => {

         let test = new Date(dateTransform.date);
         if(dateTransform.camera == this.camera  || this.camera == undefined || this.camera == "")
                     {
                     for (var i = 0; i < this.tabDay.length; i++) {
                     //console.log("new Date(bonTableau1Mois[i]", new Date(bonTableau1Mois[i]));
                         let leTabDeI = new Date(bonTableau1Mois[i]);
                         let leTabDeI2 = new Date(bonTableau1Mois[i + 1]);
                         //console.log("leTabDeI",leTabDeI);
                         //console.log("leTabDeI2",leTabDeI2);
                         //console.log("test", test)
                         if (test < leTabDeI && test > leTabDeI2) {
                         this.getSlaValue(dateTransform.sla.slaDate.day, dateTransform.sla.slaDate.hour, i)
                         console.log("on rentre par la");
                         this.tableAllTicketsForMoment.push(dateTransform);
                         this.tabAll1Mois[i]++;
                         //console.log("on rentre bien la");
                         this.retrieveDataNew1Month(dateTransform.probleme, i)



                         }
                     }

             }
                        // console.log("this.tabAll1Mois",  this.tabAll1Mois);


         });
 this.calculateSlaValue(this.tabDay.length - 1, this.tabAll1Mois);



     }



 getDataFor6MonthChart() {
         this.tableauTest = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauHorsLigne6Mois = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauImageDegrade6Mois = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauDefautEnregistrement6Mois = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauNonManipulable6Mois = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauDefautAffichageTuile6Mois = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauDefautAffichageMur6Mois = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauDefautVandalisme6Mois = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauDefautVibration6Mois = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauDefautSonore6Mois = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauDefautPoste6Mois = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauDefautIntrusion6Mois = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauSlaWeek = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         var curr = new Date; // get current date

          var first = curr.getDate() + 1 - curr.getDay(); // First day is the day of the month - the day of the week
                   console.log("firstDay", first);
                   var last = first + 6; // last day is the first day + 6
                   console.log("lastDay", last);

                 var firstday = new Date(curr.setDate(first));
                 firstday.setDate(firstday.getDate() - (182 * this.numberClick))
                 //this.tab6MoisValue[0] = firstday;
                 var lastday = new Date(curr.setDate(last));
                 lastday.setDate(lastday.getDate() - (182 * this.numberClick))

                     //lastday.setMonth(lastday.getMonth() + 1)

                 firstday.setHours(1);
                 firstday.setMinutes(1)
                 lastday.setHours(1);
                 lastday.setMinutes(1)
                 this.tab6MoisValue[0] = lastday.toISOString();
                 this.tab6MoisValue[1] = firstday.toISOString();
                 var tableauPourLeTest: any[] = [];


                 for (var i = 1; i < 26; i++) {
                     console.log("firstday", firstday.toISOString());
                     firstday.setDate(firstday.getDate() - 7 );
                     this.tab6MoisValue[i + 1] = firstday.toISOString();
                     //console.log("test", firstday);
                 }
                 console.log("this.tab6MoisValue", this.tab6MoisValue);
         this.dateForChart = "6 mois";
         this.senddateForChart.emit(this.dateForChart);
         //console.log("this.dateTickets", this.dateTickets);
         this.dateTickets.forEach((dateTransform, index) => {
             let test = new Date(dateTransform.date);
              if(dateTransform.camera == this.camera || this.camera == undefined || this.camera == "")
              {
             for (var i = 0; i < this.tab6MoisValue.length - 1; i++) {
                 let leTabDeI = new Date(this.tab6MoisValue[i]);
                 let leTabDeI2 = new Date(this.tab6MoisValue[i + 1]);
                 //console.log("test", test)

                 //console.log("valueTab", this.tab6MoisValue[i])
                 if (test < leTabDeI && test > leTabDeI2) {
                 this.getSlaValue(dateTransform.sla.slaDate.day, dateTransform.sla.slaDate.hour, i)
                   this.tableAllTicketsForMoment.push(dateTransform);
                 this.retrieveDataNew6Month(dateTransform.probleme, i)


                 }
             }


         }
         });
         this.calculateSlaValue(this.tab6MoisValue.length, this.tableauTest);
         this.tableauTest.reverse();
         this.tableauHorsLigne6Mois.reverse();
         this.tableauImageDegrade6Mois.reverse();
         this.tableauDefautEnregistrement6Mois.reverse();
         this.tableauNonManipulable6Mois.reverse();
         this.tableauDefautAffichageTuile6Mois.reverse();
         this.tableauDefautAffichageMur6Mois.reverse();
         this.tableauDefautVandalisme6Mois.reverse();
         this.tableauDefautVibration6Mois.reverse();
         this.tableauDefautSonore6Mois.reverse();
         this.tableauDefautPoste6Mois.reverse();
         this.tableauDefautIntrusion6Mois.reverse();
         this.tableauSlaWeek.reverse();


         this.getPanneForReinitialisation()
         console.log("tableauTest", this.tableauTest);

         this.options = {
             Chart: {
                 type: 'scatter',
                 height: 700
             },
             title: {
                 text: 'Evolution des Pannes'
             },
             credits: {
                 enabled: false
             },
             yAxis: {
                 title: {
                     enabled: true,
                     text: "Nombre de panne"

                 }
             },
             xAxis: {
                 categories: this.tabDate,
                 tickmarkPlacement: 'on',
                 title: {
                     enabled: true,
                     text: 'semaine'
                 }
             },
             series: [{
                 name: 'Tout les problèmes',
                 visible: this.filterActivated,
                 data: [this.tableauTest[2], this.tableauTest[3], this.tableauTest[4], this.tableauTest[5],
                 this.tableauTest[6], this.tableauTest[7], this.tableauTest[8], this.tableauTest[9], this.tableauTest[10], this.tableauTest[11],
                 this.tableauTest[12], this.tableauTest[13], this.tableauTest[14], this.tableauTest[15], this.tableauTest[16], this.tableauTest[17],
                 this.tableauTest[18], this.tableauTest[19], this.tableauTest[20], this.tableauTest[21], this.tableauTest[22], this.tableauTest[23],
                 this.tableauTest[24], this.tableauTest[25], this.tableauTest[26], this.tableauTest[27]]

             }, {
                  name: this.horsLigne,
                  visible: this.filterHorsLigne,
                       data: [
                       this.tableauHorsLigne6Mois[2], this.tableauHorsLigne6Mois[3], this.tableauHorsLigne6Mois[4], this.tableauHorsLigne6Mois[5],
                       this.tableauHorsLigne6Mois[6], this.tableauHorsLigne6Mois[7], this.tableauHorsLigne6Mois[8], this.tableauHorsLigne6Mois[9], this.tableauHorsLigne6Mois[10], this.tableauHorsLigne6Mois[11],
                       this.tableauHorsLigne6Mois[12], this.tableauHorsLigne6Mois[13], this.tableauHorsLigne6Mois[14], this.tableauHorsLigne6Mois[15], this.tableauHorsLigne6Mois[16], this.tableauHorsLigne6Mois[17],
                       this.tableauHorsLigne6Mois[18], this.tableauHorsLigne6Mois[19], this.tableauHorsLigne6Mois[20], this.tableauHorsLigne6Mois[21], this.tableauHorsLigne6Mois[22], this.tableauHorsLigne6Mois[23],
                       this.tableauHorsLigne6Mois[24], this.tableauHorsLigne6Mois[25], this.tableauHorsLigne6Mois[26], this.tableauHorsLigne6Mois[27]
                       ]
                   },
                   {
                       name: this.imageDegrade,
                       visible: this.filterImageDegrade,
                       data: [
                       this.tableauImageDegrade6Mois[2], this.tableauImageDegrade6Mois[3], this.tableauImageDegrade6Mois[4], this.tableauImageDegrade6Mois[5],
                       this.tableauImageDegrade6Mois[6], this.tableauImageDegrade6Mois[7], this.tableauImageDegrade6Mois[8], this.tableauImageDegrade6Mois[9], this.tableauImageDegrade6Mois[10], this.tableauImageDegrade6Mois[11],
                       this.tableauImageDegrade6Mois[12], this.tableauImageDegrade6Mois[13], this.tableauImageDegrade6Mois[14], this.tableauImageDegrade6Mois[15], this.tableauImageDegrade6Mois[16], this.tableauImageDegrade6Mois[17],
                       this.tableauImageDegrade6Mois[18], this.tableauImageDegrade6Mois[19], this.tableauImageDegrade6Mois[20], this.tableauImageDegrade6Mois[21], this.tableauImageDegrade6Mois[22], this.tableauImageDegrade6Mois[23],
                       this.tableauImageDegrade6Mois[24], this.tableauImageDegrade6Mois[25], this.tableauImageDegrade6Mois[26], this.tableauImageDegrade6Mois[27]
                       ]
                   },
                   {
                       name: this.defautEnregistrement,
                       visible: this.filterDefautEnregistrement,
                       data: [
                       this.tableauDefautEnregistrement6Mois[2], this.tableauDefautEnregistrement6Mois[3], this.tableauDefautEnregistrement6Mois[4], this.tableauDefautEnregistrement6Mois[5],
                       this.tableauDefautEnregistrement6Mois[6], this.tableauDefautEnregistrement6Mois[7], this.tableauDefautEnregistrement6Mois[8], this.tableauDefautEnregistrement6Mois[9], this.tableauDefautEnregistrement6Mois[10], this.tableauDefautEnregistrement6Mois[11],
                       this.tableauDefautEnregistrement6Mois[12], this.tableauDefautEnregistrement6Mois[13], this.tableauDefautEnregistrement6Mois[14], this.tableauDefautEnregistrement6Mois[15], this.tableauDefautEnregistrement6Mois[16], this.tableauDefautEnregistrement6Mois[17],
                       this.tableauDefautEnregistrement6Mois[18], this.tableauDefautEnregistrement6Mois[19], this.tableauDefautEnregistrement6Mois[20], this.tableauDefautEnregistrement6Mois[21], this.tableauDefautEnregistrement6Mois[22], this.tableauDefautEnregistrement6Mois[23],
                       this.tableauDefautEnregistrement6Mois[24], this.tableauDefautEnregistrement6Mois[25], this.tableauDefautEnregistrement6Mois[26], this.tableauDefautEnregistrement6Mois[27]
                       ]
                   }, {
                       name: this.nonManipulable,
                       visible: this.filterNonManipulable,
                       data: [
                       this.tableauNonManipulable6Mois[2], this.tableauNonManipulable6Mois[3], this.tableauNonManipulable6Mois[4], this.tableauNonManipulable6Mois[5],
                       this.tableauNonManipulable6Mois[6], this.tableauNonManipulable6Mois[7], this.tableauNonManipulable6Mois[8], this.tableauNonManipulable6Mois[9], this.tableauNonManipulable6Mois[10], this.tableauNonManipulable6Mois[11],
                       this.tableauNonManipulable6Mois[12], this.tableauNonManipulable6Mois[13], this.tableauNonManipulable6Mois[14], this.tableauNonManipulable6Mois[15], this.tableauNonManipulable6Mois[16], this.tableauNonManipulable6Mois[17],
                       this.tableauNonManipulable6Mois[18], this.tableauNonManipulable6Mois[19], this.tableauNonManipulable6Mois[20], this.tableauNonManipulable6Mois[21], this.tableauNonManipulable6Mois[22], this.tableauNonManipulable6Mois[23],
                       this.tableauNonManipulable6Mois[24], this.tableauNonManipulable6Mois[25], this.tableauNonManipulable6Mois[26], this.tableauNonManipulable6Mois[27]

                       ]
                   },
                   {
                       name: this.affichageTuile,
                       visible: this.filterAffichageTuile,
                       data: [
                       this.tableauDefautAffichageTuile6Mois[2], this.tableauDefautAffichageTuile6Mois[3], this.tableauDefautAffichageTuile6Mois[4], this.tableauDefautAffichageTuile6Mois[5],
                       this.tableauDefautAffichageTuile6Mois[6], this.tableauDefautAffichageTuile6Mois[7], this.tableauDefautAffichageTuile6Mois[8], this.tableauDefautAffichageTuile6Mois[9], this.tableauDefautAffichageTuile6Mois[10], this.tableauDefautAffichageTuile6Mois[11],
                       this.tableauDefautAffichageTuile6Mois[12], this.tableauDefautAffichageTuile6Mois[13], this.tableauDefautAffichageTuile6Mois[14], this.tableauDefautAffichageTuile6Mois[15], this.tableauDefautAffichageTuile6Mois[16], this.tableauDefautAffichageTuile6Mois[17],
                       this.tableauDefautAffichageTuile6Mois[18], this.tableauDefautAffichageTuile6Mois[19], this.tableauDefautAffichageTuile6Mois[20], this.tableauDefautAffichageTuile6Mois[21], this.tableauDefautAffichageTuile6Mois[22], this.tableauDefautAffichageTuile6Mois[23],
                       this.tableauDefautAffichageTuile6Mois[24], this.tableauDefautAffichageTuile6Mois[25], this.tableauDefautAffichageTuile6Mois[26], this.tableauDefautAffichageTuile6Mois[27]
                       ]
                   },
 {
                       name: this.affichageMur,
                       visible: this.filterAffichageMur,
                       data: [
                       this.tableauDefautAffichageMur6Mois[2], this.tableauDefautAffichageMur6Mois[3], this.tableauDefautAffichageMur6Mois[4], this.tableauDefautAffichageMur6Mois[5],
                       this.tableauDefautAffichageMur6Mois[6], this.tableauDefautAffichageMur6Mois[7], this.tableauDefautAffichageMur6Mois[8], this.tableauDefautAffichageMur6Mois[9], this.tableauDefautAffichageMur6Mois[10], this.tableauDefautAffichageMur6Mois[11],
                       this.tableauDefautAffichageMur6Mois[12], this.tableauDefautAffichageMur6Mois[13], this.tableauDefautAffichageMur6Mois[14], this.tableauDefautAffichageMur6Mois[15], this.tableauDefautAffichageMur6Mois[16], this.tableauDefautAffichageMur6Mois[17],
                       this.tableauDefautAffichageMur6Mois[18], this.tableauDefautAffichageMur6Mois[19], this.tableauDefautAffichageMur6Mois[20], this.tableauDefautAffichageMur6Mois[21], this.tableauDefautAffichageMur6Mois[22], this.tableauDefautAffichageMur6Mois[23],
                       this.tableauDefautAffichageMur6Mois[24], this.tableauDefautAffichageMur6Mois[25], this.tableauDefautAffichageMur6Mois[26], this.tableauDefautAffichageMur6Mois[27]
                       ]
                   },
                   {
                       name: this.vandalisme,
                       visible: this.filterVandalisme,
                       data: [
                       this.tableauDefautVandalisme6Mois[2], this.tableauDefautVandalisme6Mois[3], this.tableauDefautVandalisme6Mois[4], this.tableauDefautVandalisme6Mois[5],
                       this.tableauDefautVandalisme6Mois[6], this.tableauDefautVandalisme6Mois[7], this.tableauDefautVandalisme6Mois[8], this.tableauDefautVandalisme6Mois[9], this.tableauDefautVandalisme6Mois[10], this.tableauDefautVandalisme6Mois[11],
                       this.tableauDefautVandalisme6Mois[12], this.tableauDefautVandalisme6Mois[13], this.tableauDefautVandalisme6Mois[14], this.tableauDefautVandalisme6Mois[15], this.tableauDefautVandalisme6Mois[16], this.tableauDefautVandalisme6Mois[17],
                       this.tableauDefautVandalisme6Mois[18], this.tableauDefautVandalisme6Mois[19], this.tableauDefautVandalisme6Mois[20], this.tableauDefautVandalisme6Mois[21], this.tableauDefautVandalisme6Mois[22], this.tableauDefautVandalisme6Mois[23],
                       this.tableauDefautVandalisme6Mois[24], this.tableauDefautVandalisme6Mois[25], this.tableauDefautVandalisme6Mois[26], this.tableauDefautVandalisme6Mois[27]
                       ]
                   },
                   /*{
                       name: this.vibration,
                       visible: this.filterVibration,
                       data: [
                       this.tableauDefautVibration6Mois[2], this.tableauDefautVibration6Mois[3], this.tableauDefautVibration6Mois[4], this.tableauDefautVibration6Mois[5],
                       this.tableauDefautVibration6Mois[6], this.tableauDefautVibration6Mois[7], this.tableauDefautVibration6Mois[8], this.tableauDefautVibration6Mois[9], this.tableauDefautVibration6Mois[10], this.tableauDefautVibration6Mois[11],
                       this.tableauDefautVibration6Mois[12], this.tableauDefautVibration6Mois[13], this.tableauDefautVibration6Mois[14], this.tableauDefautVibration6Mois[15], this.tableauDefautVibration6Mois[16], this.tableauDefautVibration6Mois[17],
                       this.tableauDefautVibration6Mois[18], this.tableauDefautVibration6Mois[19], this.tableauDefautVibration6Mois[20], this.tableauDefautVibration6Mois[21], this.tableauDefautVibration6Mois[22], this.tableauDefautVibration6Mois[23],
                       this.tableauDefautVibration6Mois[24], this.tableauDefautVibration6Mois[25], this.tableauDefautVibration6Mois[26], this.tableauDefautVibration6Mois[27]
                       ]
                   }, {
                       name: this.sonore,
                       visible: this.filterSonore,
                       data: [
                       this.tableauDefautSonore6Mois[2], this.tableauDefautSonore6Mois[3], this.tableauDefautSonore6Mois[4], this.tableauDefautSonore6Mois[5],
                       this.tableauDefautSonore6Mois[6], this.tableauDefautSonore6Mois[7], this.tableauDefautSonore6Mois[8], this.tableauDefautSonore6Mois[9], this.tableauDefautSonore6Mois[10], this.tableauDefautSonore6Mois[11],
                       this.tableauDefautSonore6Mois[12], this.tableauDefautSonore6Mois[13], this.tableauDefautSonore6Mois[14], this.tableauDefautSonore6Mois[15], this.tableauDefautSonore6Mois[16], this.tableauDefautSonore6Mois[17],
                       this.tableauDefautSonore6Mois[18], this.tableauDefautSonore6Mois[19], this.tableauDefautSonore6Mois[20], this.tableauDefautSonore6Mois[21], this.tableauDefautSonore6Mois[22], this.tableauDefautSonore6Mois[23],
                       this.tableauDefautSonore6Mois[24], this.tableauDefautSonore6Mois[25], this.tableauDefautSonore6Mois[26], this.tableauDefautSonore6Mois[27]

                       ]
                   },*/
                   {
                       name: this.poste,
                       visible: this.filterPoste,
                       data: [
                       this.tableauDefautPoste6Mois[2], this.tableauDefautPoste6Mois[3], this.tableauDefautPoste6Mois[4], this.tableauDefautPoste6Mois[5],
                       this.tableauDefautPoste6Mois[6], this.tableauDefautPoste6Mois[7], this.tableauDefautPoste6Mois[8], this.tableauDefautPoste6Mois[9], this.tableauDefautPoste6Mois[10], this.tableauDefautPoste6Mois[11],
                       this.tableauDefautPoste6Mois[12], this.tableauDefautPoste6Mois[13], this.tableauDefautPoste6Mois[14], this.tableauDefautPoste6Mois[15], this.tableauDefautPoste6Mois[16], this.tableauDefautPoste6Mois[17],
                       this.tableauDefautPoste6Mois[18], this.tableauDefautPoste6Mois[19], this.tableauDefautPoste6Mois[20], this.tableauDefautPoste6Mois[21], this.tableauDefautPoste6Mois[22], this.tableauDefautPoste6Mois[23],
                       this.tableauDefautPoste6Mois[24], this.tableauDefautPoste6Mois[25], this.tableauDefautPoste6Mois[26], this.tableauDefautPoste6Mois[27]
                       ]
                   }/*,
 {
                       name: this.intrusion,
                       visible: this.filterIntrusion,
                       data: [
                       this.tableauDefautIntrusion6Mois[2], this.tableauDefautIntrusion6Mois[3], this.tableauDefautIntrusion6Mois[4], this.tableauDefautIntrusion6Mois[5],
                       this.tableauDefautIntrusion6Mois[6], this.tableauDefautIntrusion6Mois[7], this.tableauDefautIntrusion6Mois[8], this.tableauDefautIntrusion6Mois[9], this.tableauDefautIntrusion6Mois[10], this.tableauDefautIntrusion6Mois[11],
                       this.tableauDefautIntrusion6Mois[12], this.tableauDefautIntrusion6Mois[13], this.tableauDefautIntrusion6Mois[14], this.tableauDefautIntrusion6Mois[15], this.tableauDefautIntrusion6Mois[16], this.tableauDefautIntrusion6Mois[17],
                       this.tableauDefautIntrusion6Mois[18], this.tableauDefautIntrusion6Mois[19], this.tableauDefautIntrusion6Mois[20], this.tableauDefautIntrusion6Mois[21], this.tableauDefautIntrusion6Mois[22], this.tableauDefautIntrusion6Mois[23],
                       this.tableauDefautIntrusion6Mois[24], this.tableauDefautIntrusion6Mois[25], this.tableauDefautIntrusion6Mois[26], this.tableauDefautIntrusion6Mois[27]
                       ]
                   }*/
                   ]
         }

         Highcharts.chart('container', this.options);

         //console.log("firstday", firstday);
         //console.log("lastday", lastday);
     }



 getDataFor1YearChart() {
         this.tab1YearValue = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauHorsLigne = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauImageDegrade = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauDefautEnregistrement = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauNonManipulable = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauDefautAffichageTuile = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauDefautAffichageMur = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauDefautVandalisme = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauDefautVibration = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauDefautSonore = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauDefautPoste = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauDefautIntrusion = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         this.tableauSlaWeek = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
         //for 1 year
         var currForMonth = new Date;
         console.log("currForMonth", currForMonth.getMonth() + 1);
         //this.tableauYearX[0] = currForMonth.getMonth() + 1
         currForMonth.setMonth(currForMonth.getMonth() + 1)
         /*for (var i = 0; i <= 12; i++) {
             currForMonth.setMonth(currForMonth.getMonth() - 1);
             if (currForMonth.getMonth() < 1) {
                 currForMonth.setMonth(currForMonth.getMonth() + 12);
             }

         }*/
         this.tableauYearX = ["1", "2","3", "4", "5","6", "7", "8", "9", "10", "11","12"];
         var newDate = new Date();
         var currentYear = newDate.getFullYear();
         var firstMonth = new Date(currentYear + '-01-01T01:00:00');
         firstMonth.setFullYear(firstMonth.getFullYear() - (1 * this.numberClick));
         //console.log("firstMonth", firstMonth)
         var currentUsedYear = firstMonth.getFullYear();
         this.dateForChart = "année : " + currentUsedYear;
         this.senddateForChart.emit(this.dateForChart);
         for (var i = 0; i <= 12; i++) {
             this.tab1YearReferenceValue[i] = firstMonth.toISOString();
             console.log("firstMonth", firstMonth.toISOString())
             firstMonth.setMonth(firstMonth.getMonth() + 1);

             //console.log("valeur du mois", currForMonthData.getMonth())
             /*if(currForMonthData.getMonth() == 1)
             {
             break;
             }*/
             //console.log("firstday", firstday.toISOString());

             //console.log("this.tab", currForMonthData.toISOString());
         }

         console.log("this.tab1YearReferenceValue", this.tab1YearReferenceValue);
         console.log("this.tableauYearX", this.tableauYearX);



         console.log("this.dateTickets", this.dateTickets);
         console.log("tab1YearReferenceValue", this.tab1YearReferenceValue);
         this.dateTickets.forEach((dateTransform, index) => {
             let test = new Date(dateTransform.date);
             if(dateTransform.camera == this.camera  || this.camera == undefined || this.camera == "")
             {
             for (var i = 0; i < this.tab1YearReferenceValue.length - 1; i++) {
                 let leTabDeI = new Date(this.tab1YearReferenceValue[i]);
                 let leTabDeI2 = new Date(this.tab1YearReferenceValue[i + 1]);
                 if (test > leTabDeI && test < leTabDeI2) {
                 this.getSlaValue(dateTransform.sla.slaDate.day, dateTransform.sla.slaDate.hour, i)
                  this.tableAllTicketsForMoment.push(dateTransform);
                 console.log("on passe par la");
                 this.retrieveDataNew1Year(dateTransform.probleme, i)


                 }
             }

 }
         }
         );
         this.calculateSlaValue(11, this.tab1YearValue, currentUsedYear);
         console.log("this.tableauMateriel", this.tableauMateriel);
         console.log("this.tableauSysteme", this.tableauSysteme);
         this.getPanneForReinitialisationOld()
         console.log("tableauTest", this.tab1YearValue);
         this.options = {
             Chart: {
                 type: 'spline',
                 height: 700
             },
             title: {
                 text: 'Evolution des Pannes'
             },
             credits: {
                 enabled: false
             },
             yAxis: {
                 title: {
                     enabled: true,
                     text: "Nombre de panne"

                 }
             },
             xAxis: {
                 categories: this.tableauYearX,
                 tickmarkPlacement: 'on',
                 title: {
                     enabled: true,
                     text: 'année : ' + currentUsedYear
                 }
             },
             series: [{
                 name: 'Tout les problèmes',
                 visible: this.filterActivated,
                 data: this.tab1YearValue
             }, {
                 name: this.horsLigne,
                 visible: this.filterHorsLigne,
                 data: this.tableauHorsLigne
             },
             {
                 name: this.imageDegrade,
                 visible: this.filterImageDegrade,
                 data: this.tableauImageDegrade
             },
             {
                 name: this.defautEnregistrement,
                 visible: this.filterDefautEnregistrement,
                 data: this.tableauDefautEnregistrement
             }, {
                 name: this.nonManipulable,
                 visible: this.filterNonManipulable,
                 data: this.tableauNonManipulable
             },
             {
                 name: this.affichageTuile,
                 visible: this.filterAffichageTuile,
                 data: this.tableauDefautAffichageTuile
             },
             {
                 name: this.affichageMur,
                 visible: this.filterAffichageMur,
                 data: this.tableauDefautAffichageMur
             },
             {
                 name: this.vandalisme,
                 visible: this.filterVandalisme,
                 data: this.tableauDefautVandalisme
             },
             /*{
                 name: this.vibration,
                 visible: this.filterVibration,
                 data: this.tableauDefautVibration
             }, {
                 name: this.sonore,
                 visible: this.filterSonore,
                 data: this.tableauDefautSonore
             },*/
             {
                 name: this.poste,
                 visible: this.filterPoste,
                 data: this.tableauDefautPoste
             }/*,
             {
                 name: this.intrusion,
                 visible: this.filterIntrusion,
                 data: this.tableauDefautIntrusion
             }*/
             ]
         }

         Highcharts.chart('container', this.options);

         //console.log("firstday", firstday);
         //console.log("lastday", lastday);
     }


 getOneWeekNew(getCurrentSemaine:any)
 {
           this.tableau1SemaineAll = [0, 0, 0, 0, 0, 0, 0];
           this.tableauHorsLigne1Semaine = [0, 0, 0, 0, 0, 0, 0];
           this.tableauImageDegrade1Semaine = [0, 0, 0, 0, 0, 0, 0];
           this.tableauDefautEnregistrement1Semaine = [0, 0, 0, 0, 0, 0, 0];
           this.tableauNonManipulable1Semaine = [0, 0, 0, 0, 0, 0, 0];
           this.tableauDefautAffichageTuile1Semaine = [0, 0, 0, 0, 0, 0, 0];
           this.tableauDefautAffichageMur1Semaine = [0, 0, 0, 0, 0, 0, 0];
           this.tableauDefautVandalisme1Semaine = [0, 0, 0, 0, 0, 0, 0];
           this.tableauDefautVibration1Semaine = [0, 0, 0, 0, 0, 0, 0];
           this.tableauDefautSonore1Semaine = [0, 0, 0, 0, 0, 0, 0];
           this.tableauDefautPoste1Semaine = [0, 0, 0, 0, 0, 0, 0];
           this.tableauDefautIntrusion1Semaine = [0, 0, 0, 0, 0, 0, 0];
           this.tableauSlaWeek = [0, 0, 0, 0, 0, 0, 0];
           var curr = new Date; // get current date
           var first = curr.getDate() + 1 - curr.getDay(); // First day is the day of the month - the day of the week
           console.log("firstDay", first);
           var last = first + 6; // last day is the first day + 6
           console.log("lastDay", last);
           var b = 0;
           var tempDate;
           var testYear;
           for(var k = first; k <= last; k++)
           {

           tempDate = new Date();
           tempDate.setDate(k - this.numberClick * 7)
           //console.log("tempDate", tempDate);
           if(k == first)
           {
           testYear = tempDate.getFullYear();
           }
           //.setDate()
           var dateString = this.getStringDate(tempDate);
           this.tableau1SemaineX[b] = dateString;
           b++
           }
           this.dateForChart = "semaine : " + getCurrentSemaine + " année " + testYear;
           //this.tableau1SemaineX = [first, first + 1, first + 2,first + 3, first + 4, first + 5, first + 6]
           console.log("tableau1SemaineX", this.tableau1SemaineX);
           this.senddateForChart.emit(this.dateForChart);


           this.isFirstConnexion();
           this.tableau1SemaineXDay = ["lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi", "dimanche"];
           this.dateTickets.forEach((dateTransform, index) => {
           var dateTickets = dateTransform.date;
           var dateTicketsString = this.getStringDate(new Date(dateTickets));
           for(var i=0; i< this.tableau1SemaineX.length; i++)
           {
               if(dateTicketsString == this.tableau1SemaineX[i])
               {

               this.tableAllTicketsForMoment.push(dateTransform);
               this.getSlaValue(dateTransform.sla.slaDate.day, dateTransform.sla.slaDate.hour, i)

               this.tableau1SemaineAll[i]++;
               this.retrieveDataNewWeek(dateTransform.probleme, i);


               }


           }


           });
 this.calculateSlaValue(6, this.tableau1SemaineAll);
 this.getPanneForReinitialisation()
           this.options = {
                               Chart: {
                                   type: 'area',
                                   height: 700
                               },
                               title: {
                                   text: 'Evolution des Pannes'
                               },
                               credits: {
                                   enabled: false
                               },
                               yAxis: {
                                   title: {
                                       enabled: true,
                                       text: "Nombre de panne"

                                   }
                               },
                               xAxis: {
                                   categories: this.tableau1SemaineXDay,
                                   tickmarkPlacement: 'on',
                                   title: {
                                       enabled: true,
                                       text: this.dateForChart

                                   }
                               },
                               series: [{
                                   name: this.toutProbleme,
                                   visible: this.filterActivated,
                                   data: this.tableau1SemaineAll
                               }, {
                                   name: this.horsLigne,
                                   visible: this.filterHorsLigne,
                                   data: this.tableauHorsLigne1Semaine
                               },
                               {
                                   name: this.imageDegrade,
                                   visible: this.filterImageDegrade,
                                   data:
                                   this.tableauImageDegrade1Semaine
                               },
                               {
                                   name: this.defautEnregistrement,
                                   visible: this.filterDefautEnregistrement,
                                   data: this.tableauDefautEnregistrement1Semaine
                               }, {
                                   name: this.nonManipulable,
                                   visible: this.filterNonManipulable,
                                   data: this.tableauNonManipulable1Semaine
                               },
                               {
                                   name: this.affichageTuile,
                                   visible: this.filterAffichageTuile,
                                   data: this.tableauDefautAffichageTuile1Semaine
                           },
                            {
                                     name: this.affichageMur,
                                     visible: this.filterAffichageMur,
                                     data: this.tableauDefautAffichageMur1Semaine
                             },
           {
                                     name: this.vandalisme,
                                     visible: this.filterVandalisme,
                                     data: this.tableauDefautVandalisme1Semaine
                                 },
                                 /*{
                                     name: this.vibration,
                                     visible: this.filterVibration,
                                     data: this.tableauDefautVibration1Semaine
                                 },
                                 {
                                     name: this.sonore,
                                     visible: this.filterSonore,
                                     data: this.tableauDefautSonore1Semaine
                                 },*/
                                 {
                                     name: this.poste,
                                     visible: this.filterPoste,
                                     data: this.tableauDefautPoste1Semaine
                             }/*,
                              {
                                       name: this.intrusion,
                                       visible: this.filterIntrusion,
                                       data: this.tableauDefautIntrusion1Semaine
                               }*/


                           ]}

                           Highcharts.chart('container', this.options);
 }


 getSlaValue(day:number, hour:number, i:number)
 {
     var slaWeekValue:number = 0;
     slaWeekValue = (day * 24 + hour) / 24
     console.log("this.tableauSlaWeek[i] : ", this.tableauSlaWeek[i])
     console.log("slaWeekValue : ", slaWeekValue + " i : " + i)
     this.tableauSlaWeek[i] = this.tableauSlaWeek[i] + slaWeekValue
     console.log("this.tableauSlaWeek : ", this.tableauSlaWeek)


 }

 calculateSlaValue(i:number, tab:number[], date?:number)
 {
 console.log("this.tableauSlaWeek : ", this.tableauSlaWeek);
           for(var t=0; t<= i; t++)
           {
            console.log("this.tableau1SemaineAll : ", tab[t]);
            if(tab[t] != 0)
            {
                this.tableauSlaWeek[t] = this.tableauSlaWeek[t] / tab[t]
            }
           }
           console.log("this.tableauSlaWeek : ", this.tableauSlaWeek)
           if(this.date == "6Mois" || this.date == "1Mois" )
           {
               this.tableauSlaWeek.reverse();
           }

 if(this.date == "1Semaine")
 {
  this.options = {
                               Chart: {
                                   type: 'area',
                                   height: 700
                               },
                               title: {
                                   text: 'Sla une semaine'
                               },
                               credits: {
                                   enabled: false
                               },
                               yAxis: {
                                   title: {
                                       enabled: true,
                                       text: "Nombre de panne"

                                   }
                               },
                               xAxis: {
                                   categories: this.tableau1SemaineXDay,
                                   tickmarkPlacement: 'on',
                                   title: {
                                       enabled: true,
                                       text: this.dateForChart

                                   }
                               },
                               series: [{
                                   name: "sla",
                                   visible: true,
                                   data: this.tableauSlaWeek
                               }

                           ]}
 }
 if(this.date == "1Mois")
 {
 this.options = {
                     Chart: {
                         type: 'area',
                         height: 700
                     },
                     title: {
                         text: 'Sla un mois'
                     },
                     credits: {
                         enabled: false
                     },

                     yAxis: {
                         title: {
                             enabled: true,
                             text: "Nombre de panne"

                         }
                     },
                     xAxis: {
                         categories: this.tabDate,
                         tickmarkPlacement: 'on',
                         title: {
                             enabled: true,
                             text: this.dateToDisplay
                         }
                     },
                     series: [
                 {
                         name: "sla",
                         visible: true,
                         data: this.tableauSlaWeek
                 }
                 ]}
 }
 if(this.date == "6Mois")
 {
 this.options = {
             Chart: {
                 type: 'scatter',
                 height: 700
             },
             title: {
                 text: 'Sla sur 6 mois par semaine'
             },
             credits: {
                 enabled: false
             },
             yAxis: {
                 title: {
                     enabled: true,
                     text: "Nombre de panne"

                 }
             },
             xAxis: {
                 categories: this.tabDate,
                 tickmarkPlacement: 'on',
                 title: {
                     enabled: true,
                     text: 'semaine'
                 }
             },
             series: [
                    {
                        name: "sla",
                        visible: true,
                         data: [
                          this.tableauSlaWeek[2], this.tableauSlaWeek[3], this.tableauSlaWeek[4], this.tableauSlaWeek[5],
                          this.tableauSlaWeek[6], this.tableauSlaWeek[7], this.tableauSlaWeek[8], this.tableauSlaWeek[9], this.tableauSlaWeek[10], this.tableauSlaWeek[11],
                          this.tableauSlaWeek[12], this.tableauSlaWeek[13], this.tableauSlaWeek[14], this.tableauSlaWeek[15], this.tableauSlaWeek[16], this.tableauSlaWeek[17],
                          this.tableauSlaWeek[18], this.tableauSlaWeek[19], this.tableauSlaWeek[20], this.tableauSlaWeek[21], this.tableauSlaWeek[22], this.tableauSlaWeek[23],
                          this.tableauSlaWeek[24], this.tableauSlaWeek[25], this.tableauSlaWeek[26], this.tableauSlaWeek[27]
                          ]
                    }
                   ]
         }
 }
 if(this.date == "1An")
 {
        this.options = {
             Chart: {
                 type: 'spline',
                 height: 700
             },
             title: {
                 text: 'Evolution des Pannes'
             },
             credits: {
                 enabled: false
             },
             yAxis: {
                 title: {
                     enabled: true,
                     text: "Nombre de panne"

                 }
             },
             xAxis: {
                 categories: this.tableauYearX,
                 tickmarkPlacement: 'on',
                 title: {
                     enabled: true,
                     text: 'année : ' + date
                 }
             },
             series: [
              {
                  name: "sla",
                  visible: true,
                  data: this.tableauSlaWeek
              }
             ]
         }
 }



         Highcharts.chart('container3', this.options);



 }

 getNewData()
 {

 }

  retrieveDataOldWeek(problemeOld:string, i:number)
     {
         if (problemeOld == "Problème de manipulation") {
           var laValeurManipulation = this.tableauManipulation1Semaine[i];
           laValeurManipulation = laValeurManipulation + 1;
           this.tableauManipulation1Semaine[i] = laValeurManipulation;

       }

       if (problemeOld == 'Problème système') {
           var laValeurSysteme = this.tableauSysteme1Semaine[i];
           laValeurSysteme = laValeurSysteme + 1;
           this.tableauSysteme1Semaine[i] = laValeurSysteme;

            var laValeurSystemeSla = this.tableauSysteme1SemaineSla[i];
            laValeurSystemeSla = laValeurSystemeSla + 1;
            this.tableauSysteme1SemaineSla[i] = laValeurSystemeSla;
       }

       if (problemeOld == 'Problème réseau') {
           var laValeurReseau = this.tableauReseau1Semaine[i];
           laValeurReseau = laValeurReseau + 1;
           this.tableauReseau1Semaine[i] = laValeurReseau;

           var laValeurReseauSla = this.tableauReseau1SemaineSla[i];
           laValeurReseauSla = laValeurReseauSla + 1;
           this.tableauReseau1SemaineSla[i] = laValeurReseauSla;
       }

       if (problemeOld == 'Autre') {
           var laValeurAutre = this.tableauAutre1Semaine[i];
           laValeurAutre = laValeurAutre + 1;
           this.tableauAutre1Semaine[i] = laValeurAutre;

           var laValeurAutreSla = this.tableauAutre1SemaineSla[i];
           laValeurAutreSla = laValeurAutreSla + 1;
           this.tableauAutre1SemaineSla[i] = laValeurAutreSla;
       }

       if (problemeOld == 'Problème matériel') {
           var laValeurMateriel = this.tableauMateriel1Semaine[i];
           laValeurMateriel = laValeurMateriel + 1;
           this.tableauMateriel1Semaine[i] = laValeurMateriel;

           var laValeurMaterielSla = this.tableauMateriel1SemaineSla[i];
           laValeurMaterielSla = laValeurMaterielSla + 1;
           this.tableauMateriel1SemaineSla[i] = laValeurMaterielSla;
       }
       if (problemeOld == 'Problème EP Mairie') {
           var laValeurEPMairie = this.tableauEPMairie1Semaine[i];
           laValeurEPMairie = laValeurEPMairie + 1;
           this.tableauEPMairie1Semaine[i] = laValeurEPMairie;

           var laValeurEPMairieSla = this.tableauEPMairie1SemaineSla[i];
           laValeurEPMairieSla = laValeurEPMairieSla + 1;
           this.tableauEPMairie1SemaineSla[i] = laValeurEPMairieSla;
       }
       if (problemeOld == 'Non Classifié') {
           var laValeurMateriel = this.tableauNonClassifie1Semaine[i];
           laValeurMateriel = laValeurMateriel + 1;
           this.tableauNonClassifie1Semaine[i] = laValeurMateriel;

           var laValeurMaterielSla = this.tableauNonClassifie1SemaineSla[i];
           laValeurMaterielSla = laValeurMaterielSla + 1;
           this.tableauNonClassifie1SemaineSla[i] = laValeurMaterielSla;
       }
     }

     retrieveDataOld1Month(problemeOld:string, i:number)
     {
                             if (problemeOld == "Problème de manipulation") {
                                 var laValeurManipulation = this.tableauManipulation1Mois[i];
                                 laValeurManipulation = laValeurManipulation + 1;
                                 this.tableauManipulation1Mois[i] = laValeurManipulation;
                             }

                             if (problemeOld == 'Problème système') {
                                 var laValeurSysteme = this.tableauSysteme1Mois[i];
                                 laValeurSysteme = laValeurSysteme + 1;
                                 this.tableauSysteme1Mois[i] = laValeurSysteme;
                             }

                             if (problemeOld == 'Problème réseau') {
                                 var laValeurReseau = this.tableauReseau1Mois[i];
                                 laValeurReseau = laValeurReseau + 1;
                                 this.tableauReseau1Mois[i] = laValeurReseau;
                             }

                             if (problemeOld == 'Autre') {
                                 var laValeurAutre = this.tableauAutre1Mois[i];
                                 laValeurAutre = laValeurAutre + 1;
                                 this.tableauAutre1Mois[i] = laValeurAutre;
                             }

                             if (problemeOld == 'Problème matériel') {
                                 var laValeurMateriel = this.tableauMateriel1Mois[i];
                                 laValeurMateriel = laValeurMateriel + 1;
                                 this.tableauMateriel1Mois[i] = laValeurMateriel;
                             }

                             if (problemeOld == 'Problème EP Mairie') {
                                 var laValeurEPMairie = this.tableauEPMairie1Mois[i];
                                 laValeurEPMairie = laValeurEPMairie + 1;
                                 this.tableauEPMairie1Mois[i] = laValeurEPMairie;
                             }

                             if (problemeOld == 'Non Classifié') {
                                 var laValeurMateriel = this.tableauNonClassifie1Mois[i];
                                 laValeurMateriel = laValeurMateriel + 1;
                                 this.tableauNonClassifie1Mois[i] = laValeurMateriel;
                             }
     }

     retrieveDataOld6Month(problemeOld:string, i:number)
     {
                 if (problemeOld == "Problème de manipulation") {
                     var laValeurManipulation = this.tableauManipulation6Mois[i];
                     laValeurManipulation = laValeurManipulation + 1;
                     this.tableauManipulation6Mois[i] = laValeurManipulation;
                 }

                 if (problemeOld == 'Problème système') {
                     var laValeurSysteme = this.tableauSysteme6Mois[i];
                     laValeurSysteme = laValeurSysteme + 1;
                     this.tableauSysteme6Mois[i] = laValeurSysteme;
                 }

                 if (problemeOld == 'Problème réseau') {
                     var laValeurReseau = this.tableauReseau6Mois[i];
                     laValeurReseau = laValeurReseau + 1;
                     this.tableauReseau6Mois[i] = laValeurReseau;
                 }

                 if (problemeOld == 'Autre') {
                     var laValeurAutre = this.tableauAutre6Mois[i];
                     laValeurAutre = laValeurAutre + 1;
                     this.tableauAutre6Mois[i] = laValeurAutre;

                 }

                 if (problemeOld == 'Problème matériel') {
                     var laValeurMateriel = this.tableauMateriel6Mois[i];
                     laValeurMateriel = laValeurMateriel + 1;
                     this.tableauMateriel6Mois[i] = laValeurMateriel;

                 }

                 if (problemeOld == 'Problème EP Mairie') {
                     var laValeurEPMairie = this.tableauEPMairie6Mois[i];
                     laValeurEPMairie = laValeurEPMairie + 1;
                     this.tableauEPMairie6Mois[i] = laValeurEPMairie;

                 }

                 if (problemeOld == 'Non Classifié') {
                     var laValeurMateriel = this.tableauNonClassifie6Mois[i];
                     laValeurMateriel = laValeurMateriel + 1;
                     this.tableauNonClassifie6Mois[i] = laValeurMateriel;

                 }

                 var laValeur = this.tableauTest[i];
                 laValeur = laValeur + 1;
                 this.tableauTest[i] = laValeur;
                 }

      retrieveDataOld1Year(problemeOld:string, i:number)
      {
           if (problemeOld == "Problème de manipulation") {
                                   var laValeurManipulation = this.tableauManipulation[i];
                                   laValeurManipulation = laValeurManipulation + 1;
                                   this.tableauManipulation[i] = laValeurManipulation;
                               }

                               if (problemeOld == 'Problème système') {
                                   var laValeurSysteme = this.tableauSysteme[i];
                                   laValeurSysteme = laValeurSysteme + 1;
                                   this.tableauSysteme[i] = laValeurSysteme;
                               }

                               if (problemeOld == 'Problème réseau') {
                                   var laValeurReseau = this.tableauReseau[i];
                                   laValeurReseau = laValeurReseau + 1;
                                   this.tableauReseau[i] = laValeurReseau;
                               }

                               if (problemeOld == 'Autre') {
                                   var laValeurAutre = this.tableauAutre[i];
                                   laValeurAutre = laValeurAutre + 1;
                                   this.tableauAutre[i] = laValeurAutre;
                               }

                               if (problemeOld == 'Problème matériel') {
                                   var laValeurMateriel = this.tableauMateriel[i];
                                   laValeurMateriel = laValeurMateriel + 1;
                                   this.tableauMateriel[i] = laValeurMateriel;
                               }

                               if (problemeOld == 'Problème EP Mairie') {
                                   var laValeurEPMairie = this.tableauEPMairie[i];
                                   laValeurEPMairie = laValeurEPMairie + 1;
                                   this.tableauEPMairie[i] = laValeurEPMairie;
                               }

                               if (problemeOld == 'Non Classifié') {
                                   var laValeurMateriel = this.tableauNonClassifie[i];
                                   laValeurMateriel = laValeurMateriel + 1;
                                   this.tableauNonClassifie[i] = laValeurMateriel;
                               }

                               var laValeur = this.tab1YearValue[i];
                               laValeur = laValeur + 1;
                               this.tab1YearValue[i] = laValeur;
      }

      retrieveDataNewWeek(problemeNew:string, i:number)
      {
       if (problemeNew == "Hors ligne") {
                         var laValeurHorsLigne = this.tableauHorsLigne1Semaine[i];
                         laValeurHorsLigne = laValeurHorsLigne + 1;
                         this.tableauHorsLigne1Semaine[i] = laValeurHorsLigne;
                     }

                     if (problemeNew == 'Image dégradée / intermittente') {
                         var laValeurImageDegrade = this.tableauImageDegrade1Semaine[i];
                         laValeurImageDegrade = laValeurImageDegrade + 1;
                         this.tableauImageDegrade1Semaine[i] = laValeurImageDegrade;
                     }

                     if (problemeNew == 'Défaut enregistrement') {
                         var laValeurDefautEnregistrement = this.tableauDefautEnregistrement1Semaine[i];
                         laValeurDefautEnregistrement = laValeurDefautEnregistrement + 1;
                         this.tableauDefautEnregistrement1Semaine[i] = laValeurDefautEnregistrement;
                     }

                     if (problemeNew == 'Difficilement / Non manipulable') {
                         var laValeurNonManipulable = this.tableauNonManipulable1Semaine[i];
                         laValeurNonManipulable = laValeurNonManipulable + 1;
                         this.tableauNonManipulable1Semaine[i] = laValeurNonManipulable;
                     }

                     if (problemeNew == 'Défaut affichage Tuile / Tache') {
                         var laValeurDefautAffichageTuile = this.tableauDefautAffichageTuile1Semaine[i];
                         laValeurDefautAffichageTuile = laValeurDefautAffichageTuile + 1;
                         this.tableauDefautAffichageTuile1Semaine[i] = laValeurDefautAffichageTuile;
                     }
                     if (problemeNew == 'Défaut affichage mur d’image') {
                         var laValeurDefautAffichageMur = this.tableauDefautAffichageMur1Semaine[i];
                         laValeurDefautAffichageMur = laValeurDefautAffichageMur + 1;
                         this.tableauDefautAffichageMur1Semaine[i] = laValeurDefautAffichageMur;
                     }
                     if (problemeNew == 'Caméra Vandalisée') {
                          var laValeurDefautVandalisme = this.tableauDefautVandalisme1Semaine[i];
                          laValeurDefautVandalisme = laValeurDefautVandalisme + 1;
                          this.tableauDefautVandalisme1Semaine[i] = laValeurDefautVandalisme;
                     }

                     if (problemeNew == 'Défaut Capteur Vibration') {
                         var laValeurDefautVibration = this.tableauDefautVibration1Semaine[i];
                         laValeurDefautVibration = laValeurDefautVibration + 1;
                         this.tableauDefautVibration1Semaine[i] = laValeurDefautVibration;
                     }
                     if (problemeNew == 'Défaut Micro / Haut-parleur') {
                         var laValeurDefautSonore = this.tableauDefautSonore1Semaine[i];
                         laValeurDefautSonore = laValeurDefautSonore + 1;
                         this.tableauDefautSonore1Semaine[i] = laValeurDefautSonore;
                     }

                     if (problemeNew == 'Défaut poste opérateur') {
                         var laValeurDefautPoste = this.tableauDefautPoste1Semaine[i];
                         laValeurDefautPoste = laValeurDefautPoste + 1;
                         this.tableauDefautPoste1Semaine[i] = laValeurDefautPoste;
                     }
                     if (problemeNew == 'Défaut alarme intrusion local technique') {
                         var laValeurDefautIntrusion = this.tableauDefautIntrusion1Semaine[i];
                         laValeurDefautIntrusion = laValeurDefautIntrusion + 1;
                         this.tableauDefautIntrusion1Semaine[i] = laValeurDefautIntrusion;
                     }
      }

      retrieveDataNew1Month(problemeNew:string, i:number)
      {
 if (problemeNew == "Hors ligne") {
                               var laValeurHorsLigne = this.tableauHorsLigne1Mois[i];
                               laValeurHorsLigne = laValeurHorsLigne + 1;
                               this.tableauHorsLigne1Mois[i] = laValeurHorsLigne;
                           }

                           if (problemeNew == 'Image dégradée / intermittente') {
                               var laValeurImageDegrade = this.tableauImageDegrade1Mois[i];
                               laValeurImageDegrade = laValeurImageDegrade + 1;
                               this.tableauImageDegrade1Mois[i] = laValeurImageDegrade;
                           }

                           if (problemeNew == 'Défaut enregistrement') {
                               var laValeurDefautEnregistrement = this.tableauDefautEnregistrement1Mois[i];
                               laValeurDefautEnregistrement = laValeurDefautEnregistrement + 1;
                               this.tableauDefautEnregistrement1Mois[i] = laValeurDefautEnregistrement;
                           }

                           if (problemeNew == 'Difficilement / Non manipulable') {
                               var laValeurNonManipulable = this.tableauNonManipulable1Mois[i];
                               laValeurNonManipulable = laValeurNonManipulable + 1;
                               this.tableauNonManipulable1Mois[i] = laValeurNonManipulable;
                           }

                           if (problemeNew == 'Défaut affichage Tuile / Tache') {
                               var laValeurDefautAffichageTuile = this.tableauDefautAffichageTuile1Mois[i];
                               laValeurDefautAffichageTuile = laValeurDefautAffichageTuile + 1;
                               this.tableauDefautAffichageTuile1Mois[i] = laValeurDefautAffichageTuile;
                           }
                           if (problemeNew == 'Défaut affichage mur d’image') {
                               var laValeurDefautAffichageMur = this.tableauDefautAffichageMur1Mois[i];
                               laValeurDefautAffichageMur = laValeurDefautAffichageMur + 1;
                               this.tableauDefautAffichageMur1Mois[i] = laValeurDefautAffichageMur;
                           }
                           if (problemeNew == 'Caméra Vandalisée') {
                                var laValeurDefautVandalisme = this.tableauDefautVandalisme1Mois[i];
                                laValeurDefautVandalisme = laValeurDefautVandalisme + 1;
                                this.tableauDefautVandalisme1Mois[i] = laValeurDefautVandalisme;
                           }

                           if (problemeNew == 'Défaut Capteur Vibration') {
                               var laValeurDefautVibration = this.tableauDefautVibration1Mois[i];
                               laValeurDefautVibration = laValeurDefautVibration + 1;
                               this.tableauDefautVibration1Mois[i] = laValeurDefautVibration;
                           }

                           if (problemeNew == 'Défaut Micro / Haut-parleur') {
                               var laValeurDefautSonore = this.tableauDefautSonore1Mois[i];
                               laValeurDefautSonore = laValeurDefautSonore + 1;
                               this.tableauDefautSonore1Mois[i] = laValeurDefautSonore;
                           }

                           if (problemeNew == 'Défaut poste opérateur') {
                               var laValeurDefautPoste = this.tableauDefautPoste1Mois[i];
                               laValeurDefautPoste = laValeurDefautPoste + 1;
                               this.tableauDefautPoste1Mois[i] = laValeurDefautPoste;
                           }
                           if (problemeNew == 'Défaut alarme intrusion local technique') {
                               var laValeurDefautIntrusion = this.tableauDefautIntrusion1Mois[i];
                               laValeurDefautIntrusion = laValeurDefautIntrusion + 1;
                               this.tableauDefautIntrusion1Mois[i] = laValeurDefautIntrusion;
                           }
      }

      retrieveDataNew6Month(problemeNew:string, i:number)
      {
 if (problemeNew == "Hors ligne") {
                     var laValeurHorsLigne = this.tableauHorsLigne6Mois[i];
                     laValeurHorsLigne = laValeurHorsLigne + 1;
                     this.tableauHorsLigne6Mois[i] = laValeurHorsLigne;
                 }

                 if (problemeNew == 'Image dégradée / intermittente') {
                     var laValeurImageDegrade = this.tableauImageDegrade6Mois[i];
                     laValeurImageDegrade = laValeurImageDegrade + 1;
                     this.tableauImageDegrade6Mois[i] = laValeurImageDegrade;
                 }

                 if (problemeNew == 'Défaut enregistrement') {
                     var laValeurDefautEnregistrement = this.tableauDefautEnregistrement6Mois[i];
                     laValeurDefautEnregistrement = laValeurDefautEnregistrement + 1;
                     this.tableauDefautEnregistrement6Mois[i] = laValeurDefautEnregistrement;
                 }

                 if (problemeNew == 'Difficilement / Non manipulable') {
                     var laValeurNonManipulable = this.tableauNonManipulable6Mois[i];
                     laValeurNonManipulable = laValeurNonManipulable + 1;
                     this.tableauNonManipulable6Mois[i] = laValeurNonManipulable;
                 }

                 if (problemeNew == 'Défaut affichage Tuile / Tache') {
                     var laValeurDefautAffichageTuile = this.tableauDefautAffichageTuile6Mois[i];
                     laValeurDefautAffichageTuile = laValeurDefautAffichageTuile + 1;
                     this.tableauDefautAffichageTuile6Mois[i] = laValeurDefautAffichageTuile;
                 }
                 if (problemeNew == 'Défaut affichage mur d’image') {
                     var laValeurDefautAffichageMur = this.tableauDefautAffichageMur6Mois[i];
                     laValeurDefautAffichageMur = laValeurDefautAffichageMur + 1;
                     this.tableauDefautAffichageMur6Mois[i] = laValeurDefautAffichageMur;
                 }
                 if (problemeNew == 'Caméra Vandalisée') {
                      var laValeurDefautVandalisme = this.tableauDefautVandalisme6Mois[i];
                      laValeurDefautVandalisme = laValeurDefautVandalisme + 1;
                      this.tableauDefautVandalisme6Mois[i] = laValeurDefautVandalisme;
                 }

                 if (problemeNew == 'Défaut Capteur Vibration') {
                     var laValeurDefautVibration = this.tableauDefautVibration6Mois[i];
                     laValeurDefautVibration = laValeurDefautVibration + 1;
                     this.tableauDefautVibration6Mois[i] = laValeurDefautVibration;
                 }

                 if (problemeNew == 'Défaut Micro / Haut-parleur') {
                     var laValeurDefautSonore = this.tableauDefautSonore6Mois[i];
                     laValeurDefautSonore = laValeurDefautSonore + 1;
                     this.tableauDefautSonore6Mois[i] = laValeurDefautSonore;
                 }

                 if (problemeNew == 'Défaut poste opérateur') {
                     var laValeurDefautPoste = this.tableauDefautPoste6Mois[i];
                     laValeurDefautPoste = laValeurDefautPoste + 1;
                     this.tableauDefautPoste6Mois[i] = laValeurDefautPoste;
                 }
                 if (problemeNew == 'Défaut alarme intrusion local technique') {
                     var laValeurDefautIntrusion = this.tableauDefautIntrusion6Mois[i];
                     laValeurDefautIntrusion = laValeurDefautIntrusion + 1;
                     this.tableauDefautIntrusion6Mois[i] = laValeurDefautIntrusion;
                 }

                 var laValeur = this.tableauTest[i];
                 laValeur = laValeur + 1;
                 this.tableauTest[i] = laValeur;
      }
      retrieveDataNew1Year(problemeNew:string, i:number)
      {
 if (problemeNew == "Hors ligne") {
                       var laValeurHorsLigne = this.tableauHorsLigne[i];
                       laValeurHorsLigne = laValeurHorsLigne + 1;
                       this.tableauHorsLigne[i] = laValeurHorsLigne;
                   }

                   if (problemeNew == 'Image dégradée / intermittente') {
                       var laValeurImageDegrade = this.tableauImageDegrade[i];
                       laValeurImageDegrade = laValeurImageDegrade + 1;
                       this.tableauImageDegrade[i] = laValeurImageDegrade;
                   }

                   if (problemeNew == 'Défaut enregistrement') {
                       var laValeurDefautEnregistrement = this.tableauDefautEnregistrement[i];
                       laValeurDefautEnregistrement = laValeurDefautEnregistrement + 1;
                       this.tableauDefautEnregistrement[i] = laValeurDefautEnregistrement;
                   }

                   if (problemeNew == 'Difficilement / Non manipulable') {
                       var laValeurNonManipulable = this.tableauNonManipulable[i];
                       laValeurNonManipulable = laValeurNonManipulable + 1;
                       this.tableauNonManipulable[i] = laValeurNonManipulable;
                   }

                   if (problemeNew == 'Défaut affichage Tuile / Tache') {
                       var laValeurDefautAffichageTuile = this.tableauDefautAffichageTuile[i];
                       laValeurDefautAffichageTuile = laValeurDefautAffichageTuile + 1;
                       this.tableauDefautAffichageTuile[i] = laValeurDefautAffichageTuile;
                   }
                   if (problemeNew == 'Défaut affichage mur d’image') {
                       var laValeurDefautAffichageMur = this.tableauDefautAffichageMur[i];
                       laValeurDefautAffichageMur = laValeurDefautAffichageMur + 1;
                       this.tableauDefautAffichageMur[i] = laValeurDefautAffichageMur;
                   }
                   if (problemeNew == 'Caméra Vandalisée') {
                        var laValeurDefautVandalisme = this.tableauDefautVandalisme[i];
                        laValeurDefautVandalisme = laValeurDefautVandalisme + 1;
                        this.tableauDefautVandalisme[i] = laValeurDefautVandalisme;
                   }

                   if (problemeNew == 'Défaut Capteur Vibration') {
                       var laValeurDefautVibration = this.tableauDefautVibration[i];
                       laValeurDefautVibration = laValeurDefautVibration + 1;
                       this.tableauDefautVibration[i] = laValeurDefautVibration;
                   }

                   if (problemeNew == 'Défaut Micro / Haut-parleur') {
                       var laValeurDefautSonore = this.tableauDefautSonore[i];
                       laValeurDefautSonore = laValeurDefautSonore + 1;
                       this.tableauDefautSonore[i] = laValeurDefautSonore;
                   }

                   if (problemeNew == 'Défaut poste opérateur') {
                       var laValeurDefautPoste = this.tableauDefautPoste[i];
                       laValeurDefautPoste = laValeurDefautPoste + 1;
                       this.tableauDefautPoste[i] = laValeurDefautPoste;
                   }
                   if (problemeNew == 'Défaut alarme intrusion local technique') {
                       var laValeurDefautIntrusion = this.tableauDefautIntrusion[i];
                       laValeurDefautIntrusion = laValeurDefautIntrusion + 1;
                       this.tableauDefautIntrusion[i] = laValeurDefautIntrusion;
                   }

                     var laValeur = this.tab1YearValue[i];
                     laValeur = laValeur + 1;
                     this.tab1YearValue[i] = laValeur;
      }


}
