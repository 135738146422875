
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule } from '@angular/material/card';
import { SearchTicketComponent } from './Toulouse/mainPageDirectory/search-ticket/search-ticket.component';
import { SearchTicketComponentOld } from './Toulouse/mainPageDirectory/search-ticket-old/search-ticket-old.component';
import { DisplayTicketComponent } from './Toulouse/mainPageDirectory/display-ticket/display-ticket.component';
import { DisplayTicketOldComponent } from './Toulouse/mainPageDirectory/display-ticket-old/display-ticket-old.component';

import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { SearchCamAndProbComponent } from './Toulouse/mainPageDirectory/search-cam-and-prob/search-cam-and-prob.component';
import { SearchCamAndProbComponentOld } from './Toulouse/mainPageDirectory/search-cam-and-prob-old/search-cam-and-prob-old.component';

import { HttpClientModule } from '@angular/common/http';

import { BandeauTitreComponent } from './Toulouse/mainPageDirectory/bandeau-titre/bandeau-titre.component';

import { FormsModule } from '@angular/forms';
import { ResponsableComponent } from './Toulouse/mainPageDirectory/responsable/responsable.component';
import { ResponsableComponentOld } from './Toulouse/mainPageDirectory/responsable-old/responsable-old.component';
import { ResponsableComponentBlagnacOld } from './Blagnac/mainPageDirectory/responsable-old/responsable-old.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { PageConnexionComponent } from './page-connexion/page-connexion.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RapportPageComponent } from './Toulouse/rapportPageDirectory/rapport-page/rapport-page.component';
import { MainPageComponent } from './Toulouse/mainPageDirectory/main-page/main-page.component';
import { MainPageComponentOld } from './Toulouse/mainPageDirectory/main-page-old/main-page-old.component';
import { HeaderRapportComponent } from './Toulouse/rapportPageDirectory/header-rapport/header-rapport.component';
import { DisplayRapportsComponent } from './Toulouse/rapportPageDirectory/display-rapports/display-rapports.component';
import { DisplayCompteurComponent } from './Toulouse/rapportPageDirectory/display-compteur/display-compteur.component';
import { DatePipe } from '@angular/common';
import { DataService } from './data.service';
// import { SearchCamAndProbOldComponent } from './Toulouse/mainPageDirectory/search-cam-and-prob-old/search-cam-and-prob-old.component';
// import { SearchTicketOldComponent } from './Toulouse/mainPageDirectory/search-ticket-old/search-ticket-old.component';
import { MainPageOperatorComponent } from './Toulouse/mainPageDirectory/main-page-operator/main-page-operator.component'
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';

// blagnac
import { SearchTicketComponentBlagnac } from './Blagnac/mainPageDirectory/search-ticket/search-ticket.component';
import { SearchTicketComponentBlagnacOld } from './Blagnac/mainPageDirectory/search-ticket-old/search-ticket-old.component';
import { DisplayTicketComponentBlagnac } from './Blagnac/mainPageDirectory/display-ticket/display-ticket.component';
import { ResponsableComponentBlagnac } from './Blagnac/mainPageDirectory/responsable/responsable.component';
import { MainPageComponentBlagnac } from './Blagnac/mainPageDirectory/main-page/main-page.component';
import { MainPageComponentBlagnacOld } from './Blagnac/mainPageDirectory/main-page-old/main-page-old.component';
import { MainPageOperatorComponentBlagnac } from './Blagnac/mainPageDirectory/main-page-operator/main-page-operator.component'
import { BandeauTitreComponentBlagnac } from './Blagnac/mainPageDirectory/bandeau-titre/bandeau-titre.component';

import { SearchCamAndProbComponentBlagnac } from './Blagnac/mainPageDirectory/search-cam-and-prob/search-cam-and-prob.component';
import { SearchCamAndProbComponentBlagnacOld } from './Blagnac/mainPageDirectory/search-cam-and-prob-old/search-cam-and-prob-old.component';
import { RapportPageComponentBlagnac } from './Blagnac/rapportPageDirectory/rapport-page/rapport-page.component';
import { HeaderRapportComponentBlagnac } from './Blagnac/rapportPageDirectory/header-rapport/header-rapport.component';
import { DisplayRapportsComponentBlagnac } from './Blagnac/rapportPageDirectory/display-rapports/display-rapports.component';
import { DisplayCompteurComponentBlagnac } from './Blagnac/rapportPageDirectory/display-compteur/display-compteur.component';

import { SearchTicketComponentMiramas } from './Miramas/mainPageDirectory/search-ticket/search-ticket.component';
import { DisplayTicketComponentMiramas } from './Miramas/mainPageDirectory/display-ticket/display-ticket.component';
import { ResponsableComponentMiramas } from './Miramas/mainPageDirectory/responsable/responsable.component';
import { MainPageComponentMiramas } from './Miramas/mainPageDirectory/main-page/main-page.component';
import { MainPageOperatorComponentMiramas } from './Miramas/mainPageDirectory/main-page-operator/main-page-operator.component'
import { BandeauTitreComponentMiramas } from './Miramas/mainPageDirectory/bandeau-titre/bandeau-titre.component';
import { SearchCamAndProbComponentMiramas } from './Miramas/mainPageDirectory/search-cam-and-prob/search-cam-and-prob.component';
import { RapportPageComponentMiramas } from './Miramas/rapportPageDirectory/rapport-page/rapport-page.component';
import { HeaderRapportComponentMiramas } from './Miramas/rapportPageDirectory/header-rapport/header-rapport.component';
import { DisplayRapportsComponentMiramas } from './Miramas/rapportPageDirectory/display-rapports/display-rapports.component';
import { DisplayCompteurComponentMiramas } from './Miramas/rapportPageDirectory/display-compteur/display-compteur.component';
import { InstallationListComponent } from './Blagnac/rapportPageDirectory/installation-list/installation-list.component';
import { DisplayTicketOldComponentBlagnac } from './Blagnac/mainPageDirectory/display-ticket-old/display-ticket-old.component';

@NgModule({
  declarations: [
    AppComponent,
    SearchTicketComponent,
    SearchTicketComponentOld,
    DisplayTicketComponent,
    DisplayTicketOldComponent,
    SearchCamAndProbComponent,
    SearchCamAndProbComponentOld,
    BandeauTitreComponent,
    ResponsableComponent,
    ResponsableComponentOld,

    PageConnexionComponent,
    RapportPageComponent,
    MainPageComponent,
    MainPageComponentOld,
    HeaderRapportComponent,
    DisplayRapportsComponent,
    DisplayCompteurComponent,
    MainPageOperatorComponent,
    SearchTicketComponentBlagnac,
    SearchTicketComponentBlagnacOld,
    DisplayTicketComponentBlagnac,
    DisplayTicketOldComponentBlagnac,
    MainPageComponentBlagnac,
    MainPageComponentBlagnacOld,
    MainPageOperatorComponentBlagnac,
    BandeauTitreComponentBlagnac,
    SearchCamAndProbComponentBlagnac,
    SearchCamAndProbComponentBlagnacOld,
    RapportPageComponentBlagnac,
    HeaderRapportComponentBlagnac,
    DisplayRapportsComponentBlagnac,
    DisplayCompteurComponentBlagnac,
    ResponsableComponentBlagnac,
    ResponsableComponentBlagnacOld,
    SearchTicketComponentMiramas,
    DisplayTicketComponentMiramas,
    MainPageComponentMiramas,
    MainPageOperatorComponentMiramas,
    BandeauTitreComponentMiramas,
    SearchCamAndProbComponentMiramas,
    RapportPageComponentMiramas,
    HeaderRapportComponentMiramas,
    DisplayRapportsComponentMiramas,
    DisplayCompteurComponentMiramas,
    ResponsableComponentMiramas,
    InstallationListComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatCardModule,
    DropDownsModule,
    HttpClientModule,
    FormsModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
  ],
  providers: [DatePipe, DataService, HeaderRapportComponent, PageConnexionComponent,
   DisplayTicketComponent, SearchCamAndProbComponent,SearchCamAndProbComponentOld ,HeaderRapportComponentBlagnac,DisplayTicketComponentBlagnac,DisplayTicketOldComponentBlagnac, SearchCamAndProbComponentBlagnac,SearchCamAndProbComponentBlagnacOld 
    ,HeaderRapportComponentMiramas,DisplayTicketComponentMiramas, SearchCamAndProbComponentMiramas],
  bootstrap: [AppComponent]

})
export class AppModule {


 }
