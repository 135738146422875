<div class="fullContainer">
  <h1>Connexion Solution GMAO</h1>
<div class="mainContainer">
  <div *ngIf="problemeServer == 'yes'" class="probleme">
    Erreur lors de la connexion au serveur
  </div>

  <!-- <h2>Photo</h2>
   <div><img [src]="url" *ngIf="url"></div>
   <div id="upload" class="uploadImage" style="text-align:center">

     <p>
       <input type="file" (change)="selectFile($event)" >
     </p>
   </div>-->
  <h2>Nom d'utilisateur</h2>

  <form class="example-form">
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Nom d'utilisateur</mat-label>
      <input [(ngModel)]="email" matInput name="test" placeholder="Nom d'utilisateur" autocomplete="off">

    </mat-form-field>
  </form>
    <h2>Mot de passe</h2>
  <mat-form-field class="input1" appearance="fill" >
    <mat-label>Mot de passe</mat-label>
    <input [(ngModel)]="pwd" matInput autocomplete="off">
  </mat-form-field>
  <div>
  <button (click)="connexion()">Connexion</button>
  </div>
  <p class="error" *ngIf="error == 'error'">L'identifiant ou le mot de passe n'est pas correct</p>
</div>

</div>

