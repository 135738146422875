import { Component, OnInit } from '@angular/core';
import * as installJson from '../../../../assets/installation.json';

@Component({
  selector: 'app-installation-list',
  templateUrl: './installation-list.component.html',
  styleUrls: ['./installation-list.component.scss']
})
export class InstallationListComponent implements OnInit {
configGmao:any = installJson;
installation:any = this.configGmao.default;
  constructor() { }

  ngOnInit(): void {
  console.log("configGmaoInstall : ", this.installation);
  }

}
