import { Injectable, OnInit, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { interval } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class DataService implements OnInit {
  private apiUrl = 'https://sippro-gmao.fr:8443/displayTicketsCsv';
  private apiUrl2 = 'https://sippro-gmao.fr:8443/displayTicketsCsvBLC';

  tickets: any = [];
  isConnected = false;
  serviceUpdated = new EventEmitter();
  serviceUpdatedMessage = new EventEmitter();
  isConnectedEvent = new EventEmitter();
  getAllTickets() {
    console.log('pouloulouloulouloulouluoulou : ', this.tickets);

    this.http
      .get('https://sippro-gmao.fr:8443/getTicketsForRapportsFull')
      .subscribe((dataFull) => {
        this.tickets = dataFull;
        console.log('tickets fullData', this.tickets);
        this.serviceUpdated.emit(this.tickets);
        this.serviceUpdatedMessage.emit('');
      });

    interval(300000).subscribe((test) => {
      console.log('Service Called');
      this.getFullTickets();
    });

    return this.tickets;
  }

  getTicketsValue() {
    return this.tickets;
  }

  sendIsConnected() {
    this.isConnectedEvent.emit('operator');
  }
  getFullTickets() {
    this.http
      .get('https://sippro-gmao.fr:8443/getTicketsForRapportsFull')
      .subscribe((data) => {
        this.tickets = data;
        console.log('tickets fullData Called', this.tickets);
        this.serviceUpdated.emit(this.tickets);
        this.serviceUpdatedMessage.emit('');
      });
  }

  constructor(private http: HttpClient) {}

  getData() {
    return this.http.get(this.apiUrl);
  }

  getData2() {
    return this.http.get(this.apiUrl2);
  }

  ngOnInit(): void {}
}
