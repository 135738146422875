<div class="bandeauTitre">
  <div *ngIf="role != 'operateur'">
    <img
      class="image"
      src="../../../assets/LogoBlancSippro.png"
      alt="Logo Toulouse"
      style="width: 12%; margin-top: -1.8vh"
    />
  </div>
  <div *ngIf="role == 'operateur'">
    <img
      class="image"
      src="../../../assets/Logo_Toulouse.png"
      alt="Logo Toulouse"
      style="width: 6%"
    />
  </div>
  <h1>Espace GMAO {{ configGmao.default.projectName }}</h1>

  <h3>compte : {{ accountName }}</h3>
  <div *ngIf="role != 'operateu'">
    <button class="buttonDeconnexion" (click)="test()">Deconnexion</button>
  </div>
</div>
