import { Injectable, OnInit, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { interval } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class DataMiramasService {
  tickets: any = [];
  isConnected = false;
  serviceUpdatedMiramas = new EventEmitter();
  serviceUpdatedMessageMiramas = new EventEmitter();
  isConnectedEventMiramas = new EventEmitter();

  getAllTicketsMiramas() {
    console.log('pouloulouloulouloulouluoulouMiramas : ', this.tickets);

    this.http
      .get('https://sippro-gmao.fr:8443/getTicketsForRapportsFullMiramas')
      .subscribe((dataFull) => {
        this.tickets = dataFull;
        console.log('tickets fullData', this.tickets);
        this.serviceUpdatedMiramas.emit(this.tickets);
        this.serviceUpdatedMessageMiramas.emit('');
      });

    interval(300000).subscribe((test) => {
      console.log('Service Called');
      this.getFullTicketsMiramas();
    });

    return this.tickets;
  }

  getTicketsValueMiramas() {
    return this.tickets;
  }

  sendIsConnectedMiramas() {
    this.isConnectedEventMiramas.emit('operator');
  }
  getFullTicketsMiramas() {
    this.http
      .get('https://sippro-gmao.fr:8443/getTicketsForRapportsFullMiramas')
      .subscribe((data) => {
        this.tickets = data;
        console.log('tickets fullData Called', this.tickets);
        this.serviceUpdatedMiramas.emit(this.tickets);
        this.serviceUpdatedMessageMiramas.emit('');
      });
  }

  constructor(private http: HttpClient) {}

  ngOnInit(): void {}
}
