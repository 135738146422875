import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GuardService implements CanActivate {
accesGranted:boolean = false;
accountName:string = "";
  constructor(private router: Router) { }

setAccountValue(value:string)
{
this.accountName = value;
}

getAccountValue()
{
return this.accountName
}

setAuthorizationTrue()
{
this.accesGranted = true;
console.log("this.accesGranted", this.accesGranted);
}

setAuthorizationFalse()
{
this.accesGranted = false;
console.log("this.accesGranted", this.accesGranted);
}

canActivate():boolean
{
if(this.accesGranted == false)
{
this.router.navigateByUrl('/pageConnexion')
}
return this.accesGranted;
}
}

@Injectable({
  providedIn: 'root'
})
export class GuardServiceAdmin implements CanActivate {
accesGranted:boolean = false;
  constructor(private router: Router) { }


setAuthorizationTrueAdmin()
{
this.accesGranted = true;
console.log("this.accesGranted", this.accesGranted);
}



setAuthorizationFalseAdmin()
{
this.accesGranted = false;
console.log("this.accesGranted", this.accesGranted);
}

canActivate():boolean
{
if(this.accesGranted == false)
{
this.router.navigateByUrl('/pageConnexion')
}
return this.accesGranted;
}
}

