import { Injectable, OnInit, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { interval } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataBlagnacService {
  tickets: any = [];
  isConnected = false;
  serviceUpdatedBlagnac = new EventEmitter();
  serviceUpdatedMessageBlagnac = new EventEmitter();
  isConnectedEventBlagnac = new EventEmitter();

  getAllTicketsBlagnac() {
    console.log('pouloulouloulouloulouluoulouBlagnac : ', this.tickets);

    this.http
      .get('https://sippro-gmao.fr:8443/getTicketsForRapportsFullBlagnac')
      .subscribe((dataFull) => {
        this.tickets = dataFull;
        console.log('tickets fullData', this.tickets);
        this.serviceUpdatedBlagnac.emit(this.tickets);
        this.serviceUpdatedMessageBlagnac.emit('');
      });

    interval(300000).subscribe((test) => {
      console.log('Service Called');
      this.getFullTicketsBlagnac();
    });

    return this.tickets;
  }

  getTicketsValueBlagnac() {
    return this.tickets;
  }

  sendIsConnectedBlagnac() {
    this.isConnectedEventBlagnac.emit('operator');
  }
  getFullTicketsBlagnac() {
    this.http
      .get('https://sippro-gmao.fr:8443/getTicketsForRapportsFullBlagnac')
      .subscribe((data) => {
        this.tickets = data;
        console.log('tickets fullData Called', this.tickets);
        this.serviceUpdatedBlagnac.emit(this.tickets);
        this.serviceUpdatedMessageBlagnac.emit('');
      });
  }

  constructor(private http: HttpClient) {}

  ngOnInit(): void {}
}
