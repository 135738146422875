import { Component, OnInit } from '@angular/core';
import * as configJson from '../../../../assets/data.json';
import { Router } from '@angular/router'; // import router from angular router
import { PageConnexionComponent } from '../../../page-connexion/page-connexion.component';
import { GuardService, GuardServiceAdmin } from '../../../guard.service';

@Component({
  selector: 'app-bandeau-titre',
  templateUrl: './bandeau-titre.component.html',
  styleUrls: ['./bandeau-titre.component.scss'],
})
export class BandeauTitreComponent implements OnInit {
  configGmao: any = configJson;
  accountName: string = '';
  role: string | null = '';
  constructor(
    private route: Router,
    private guard: GuardService,
    private guardAdmin: GuardServiceAdmin,
    private connexion: PageConnexionComponent,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.role = localStorage.getItem('role' || '{}');
    console.log('configGmao', this.configGmao);
    this.accountName = this.guard.getAccountValue();
    console.log('connexion.account : ', this.accountName);
  }

  test() {
    localStorage.clear();
    this.guard.setAuthorizationFalse();
    this.guardAdmin.setAuthorizationFalseAdmin();
    this.route.navigate(['/']);
  }

  
}
