<div *ngIf="isAuthorized == true">

<app-header-rapport-blagnac (EventValueDate)="getDate($event)" (newItemEventCamera)="addCamera($event)"
                    (newItemEventProblem)="addProbleme($event)" (EventChecked)="getCheckedValue($event)" >

</app-header-rapport-blagnac>
  <app-installation-list></app-installation-list>
<app-display-compteur-blagnac (allTickets)="getTickets($event)" [camera]="cameraForDisplay" [panne]="probleme"
                      [dateForChart]="dateForChart" [getAllTicketsForMomentTab]="getAllTicketsForMomentTab" [checkedValue]="checkedValue">

</app-display-compteur-blagnac>

<app-display-rapports-blagnac >

</app-display-rapports-blagnac>

</div>
