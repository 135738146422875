

  <app-bandeau-titre></app-bandeau-titre>

  <div>
    <app-search-ticket-old [poste]="display" (newItemEventCamera)="addCamera($event)" (newItemEventProblem)="addProbleme($event)"  (newItemEventResponsible)="addResponsible($event)" (newItemEventInput)="eventInput($event)" (newAnnulerTout)="eventAnnuler($event)" (newCreateTicketEvent)="eventCreateTickets($event)"></app-search-ticket-old>
  </div>
  <div>
    <app-display-ticket-old [poste]="display" [item]="responsable" [camera]="camera" [problem]="problem" [responsible]="responsible" [LaunchDisplayTicket]="createTicket" (problemeSipproEvent)="getProblemSippro($event)" (problemeSnefEvent)="getProblemSnef($event)" (problemeMairieEvent)="getProblemMairie($event)" (problemeMaintenanceZefilEvent)="getProblemMaintenanceZefil($event)" (problemeTousEvent)="getProblemTous($event)" ></app-display-ticket-old>
  </div>
  <app-responsable-old (newItemEvent)="addItem($event)" [annulerTOUS]="annulerTout" [allResponsableInput]="allResponsable" [problemTous]="problemTous" [problemSippro]="problemSippro" [problemSnef]="problemSnef" [problemMairie]="problemMairie" [problemMaintenanceZefil]="problemMaintenanceZefil"></app-responsable-old>



  <div id="loading-maskTickets">
    <p class="textCreation">Mise a jour des pannes</p>
    <div  class="d-flex justify-content-center">
      <div class="spinner-border" role="status">
        <span  class="sr-only" id="loading"></span>
      </div>
    </div>
  </div>
