import { Component, VERSION } from '@angular/core';
import { RouterModule } from '@angular/router';


@Component({
    selector: 'app-display-rapports-blagnac',
    templateUrl: './display-rapports.component.html',
    styleUrls: ['./display-rapports.component.scss', '../../../../assets/scss/fleches.scss' ],

})
export class DisplayRapportsComponentBlagnac {
  
  linechart: any = {
    series: [
      {
        data: [1, 2, 3],
      },
    ],
    chart: {
      type: 'line',
    },
    title: {
      text: 'linechart',
    },
  };
}
