<div class="container">
  <img class="image "src="../../assets/LogoBlancSippro.png" alt="Logo Sippro">
  <h1>Rapports GMAO </h1><p>{{completeData}}</p>
  <button (click)="sendDisplayValue()" class="buttonLink">← Retour</button>

  <div class="textInput">
    <p class="cameraText" >Caméra</p>
    <p class="PanneText">Panne</p>
  </div>
  <div class="menuDéroulant">
    <div class="listeCamera" id="listeCamera">
      <kendo-autocomplete  class="kendoEquipement" [(ngModel)]="cameraName" (ngModelChange)="onChangeCamera($event)" #autocomplete (click)="openInput()" [suggest]="true" [data]="listCamera" placeholder="ID_camera_TOUL" ></kendo-autocomplete>
    </div>

    <div class="listeProbleme" id="listePanne">
      <kendo-autocomplete class="kendoEquipement" [(ngModel)]="problemName" (ngModelChange)="onChangeProblem($event)" (click)="openInput2()" #autocomplete2 [suggest]="true" [data]="listProbleme" placeholder="Cause Panne"></kendo-autocomplete>
    </div>
  </div>
  <section class="sectionCheckBox">
    <div class="buttonToggleOld">
      <mat-button-toggle-group [(ngModel)]="checked" >
        <mat-button-toggle (click)="annulerTicket()" (click)="getCheckedValue('nouveau')" class="radioButton" value="nouveau">Nouveau</mat-button-toggle>
        <mat-button-toggle (click)="annulerTicket()" (click)="getCheckedValue('ancien')" class="radioButton" value="ancien">Ancien</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </section>
  <div class="buttonToggleDate">
    <mat-button-toggle-group [(ngModel)]="valueButtonDate" (ngModelChange)="onChangeDate($event)" name="fontStyle" aria-label="Font Style">
      <mat-button-toggle class="px-4, rounded-full, place-items-center" value="1Semaine">1 Semaine</mat-button-toggle>
      <mat-button-toggle class="px-4, rounded-full, place-items-center" value="1Mois">1 Mois</mat-button-toggle>
      <mat-button-toggle class="px-4, rounded-full, place-items-center" value="6Mois">6 Mois</mat-button-toggle>
      <mat-button-toggle class="px-4, rounded-full, place-items-center" value="1An">1 An</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>





