import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Output, EventEmitter } from '@angular/core';
import { DataBlagnacService } from '../../../data-blagnac.service';
import { Router, RouterModule } from '@angular/router';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
export var sep = true;

@Component({
  selector: 'app-header-rapport-blagnac',
  templateUrl: './header-rapport.component.html',
  styleUrls: ['./header-rapport.component.scss'],
})
export class HeaderRapportComponentBlagnac implements OnInit {
  camera: string = '';
  probleme: string = '';
  checked: string = 'nouveau';
  listCamera: Array<string> = [];
  listProblemOld: Array<string> = [];
  listeProblemNew: Array<string> = [];
  listProbleme: Array<string> = [];
  panneEtCamera: any = [];
  problemeServer: string = 'no;';
  causePanne: string = '';
  missData: string = '';
  errorValue: any = [];
  finalError: string = '';
  cameraName: string = '';
  problemName: string = '';
  valueButton: string = '';
  valueButtonDate: string = '1Mois';
  @Output() newItemEventCamera = new EventEmitter<string>();
  @Output() newItemEventProblem = new EventEmitter<string>();
  @Output() EventValueDate = new EventEmitter<string>();
  @Output() EventChecked = new EventEmitter<string>();
  completeData: string = '';
  returnEvent = new EventEmitter<string>();
  returnButton: boolean = true;

  @ViewChild('autocomplete', { static: true }) public autocomplete: any;
  @ViewChild('autocomplete2', { static: true }) public autocomplete2: any;
  getItemAndPanne() {
    this.http
      .get('https://sippro-gmao.fr:8443/getValueForRapportsBlagnac')
      .subscribe((data) => {
        console.log('dataRapport', data);
        this.panneEtCamera = data;

        this.listCamera = this.panneEtCamera.camera;
        console.log('this.listCamera', this.listCamera);
        this.listProbleme = this.panneEtCamera.panne;
        this.listeProblemNew = this.panneEtCamera.panne;
      });
  }

  annulerTicket() {
    this.problemName = '';
    this.newItemEventProblem.emit('');
  }

  getCheckedValue(value: string) {
    this.checked = value;

    this.listProbleme = this.listeProblemNew;

    this.EventChecked.emit(this.checked);
  }

  constructor(
    private http: HttpClient,
    private list: DataBlagnacService,
    private router: Router
  ) {
    var messageData;

    messageData = this.list.serviceUpdatedMessageBlagnac.subscribe({
      next: (messageData) => {
        console.log(`test j'ai bien recu`, messageData);
        this.completeData = messageData;
      },
    });
    console.log('messageData', messageData);
  }

  ngOnInit(): void {
    this.getItemAndPanne();
    this.EventValueDate.emit('1Mois');
  }

  sendDisplayValue() {
    console.log("j'ai cliqué");
    this.list.sendIsConnectedBlagnac();
    this.router.navigate(['/mainBlagnac']);
  }

  openInput() {
    this.autocomplete.toggle(true);
    //this.eventOpenInput.emit("TOUS");
    this.finalError = '';
  }

  /*displayEquipement()
  {
   let panne = document.getElementById('listePanne');
        panne!.style.display = "none";
    let camera = document.getElementById('listeCamera');
    camera!.style.display = "block";
  }

  displayPanne()
  {
  let camera = document.getElementById('listeCamera');
      camera!.style.display = "none";
      let panne = document.getElementById('listePanne');
      panne!.style.display = "block";
  }*/

  openInput2() {
    this.autocomplete2.toggle(true);
    //this.eventOpenInput.emit("TOUS");
    this.finalError = '';
  }

  onChange(newValue) {
    console.log(newValue);
    this.valueButton = newValue;
  }

  onChangeDate(newValue) {
    this.valueButtonDate = newValue;
    this.EventValueDate.emit(newValue);
  }

  onChangeCamera(value: string) {
    this.newItemEventCamera.emit(value);
  }

  onChangeProblem(value: string) {
    this.newItemEventProblem.emit(value);
  }
}
