import { Component, OnInit } from '@angular/core';
import { Output, EventEmitter, Input } from '@angular/core';
import { SearchCamAndProbComponentBlagnac } from '../search-cam-and-prob/search-cam-and-prob.component';

@Component({
  selector: 'app-search-ticket-blagnac',
  templateUrl: './search-ticket.component.html',
  styleUrls: ['./search-ticket.component.scss']
})


export class SearchTicketComponentBlagnac {
 camera:string = "";
 probleme:string = "";
 status:string = "";

@Output() newItemEventCamera = new EventEmitter<string>();
@Output() newItemEventProblem = new EventEmitter<string>();
@Output() newItemEventStatus = new EventEmitter<string>();
@Output() newItemEventInput = new EventEmitter<string>();
@Output() newAnnulerTout = new EventEmitter<string>();
@Output() newCreateTicketEvent = new EventEmitter<string>();
@Input() poste = "";

 addCamera(newItem: string) {
    this.camera = newItem;
    this.newItemEventCamera.emit(newItem);
  }
  addProbleme(newItem: string) {
     this.probleme = newItem;
     this.newItemEventProblem.emit(newItem);

   }

   addStatus(newItem:string){
    this.status = newItem;
    this.newItemEventStatus.emit(newItem)
   }

  getOpenInput(newItem: string) {
     this.newItemEventInput.emit(newItem);

   }
  getAnnulerTout(newItem: string) {
     this.newAnnulerTout.emit(newItem);

   }
getCreateTicket(newEvent: string) {
     this.newCreateTicketEvent.emit(newEvent);

   }


  constructor(private camProb:SearchCamAndProbComponentBlagnac) { }


}
