<div id="containerInstallation">
<div class="tab" >
  <div class="titreCamera">
  <h1>Numéro caméra</h1>
  <h1>Nom de la caméra</h1>
  <h1>Date d'installation</h1>
  </div>
  <mat-card *ngFor="let install of installation">

<h1>{{install.numero}}</h1>
<h1>{{install.nom}}</h1>
<h1>{{install.date}}</h1>
  </mat-card>
</div>
</div>
