import { Component, OnInit, Input } from '@angular/core';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { Output, EventEmitter } from '@angular/core';
import { Injectable } from '@angular/core';


export var authorized = false;
@Component({
  selector: 'app-responsable-blagnac',
  templateUrl: './responsable.component.html',
  styleUrls: ['./responsable.component.scss']
})
export class ResponsableComponentBlagnac implements OnInit {
@Output() newItemEvent = new EventEmitter<string>();
  constructor() { }
@Input() problemTous = "";
@Input() problemSippro = "";
@Input() problemSnef = "";
@Input() problemMairie = "";
@Input() allResponsableInput = "";
@Input() annulerTOUS = "";
poste:string | null = ""
valueButton:string = "";
public value:string = "";
  ngOnInit(): void {
 this.poste = localStorage.getItem('role');
  }

getValue(value:string)
{
this.newItemEvent.emit(value);
console.log("value : ", value);
}

ngOnChanges() {
    this.testButton(this.allResponsableInput);
  }

testButton(value:string)
{
this.valueButton = "TOUS";
this.newItemEvent.emit("TOUS");
}

changeRoute()
{
authorized = true;
}

gotoGmao() {
  window.open('https://dashboardblc.sippro-gmao.fr', '_blank');
}
gotoAnomalies() {
  window.open('https://blcanomalies.sippro-gmao.fr', '_blank');
}

}
