<div >
  
    <div  class="container">
      <!-- <div class="warningText" *ngIf="testif == true && colorText == 'orange'" style="color:orange">⚠ Attention une panne existe deja avec cette caméra ⚠</div>
      <div class="warningText" *ngIf="testif == true && colorText == 'red'" style="color:red">⚠ Attention une panne existe déjà avec cet équipement et ce problème. Création de ticket impossible. Cliquez sur annuler. ⚠</div> -->
    
      <div class="bandeauTitre">
        <div>
        <!-- <img class="image "src="../../../assets/LogoBlancSippro.png" alt="Logo Toulouse" style="width:12%; margin-top:-4.5vh;"> -->
        </div>
      
    
      </div>
    
      <mat-card id="allTheCard" class="zone-card">
    
        
    
          <mat-card-title id="titleWarning" class="title" >Pannes Clôturée</mat-card-title>
        <div id="idTexte" class="champTexte">
    
          <p class="texte">Equip. concerné</p>
          <p class="texte">Type de panne</p>
          <p class="texte">Clé de la panne</p>
          <p class="texte">Statut panne</p>
          <p class="texte">date de création</p>
          <p class="texte">Temps Ecoulé</p>
          <p class="texte">Responsable</p>
        </div>
        <div *ngIf="problemeServer == 'yes'" class="probleme">
          Impossible de récupérer les données et d'afficher les pannes
          <br/>{{causePanne}}
        </div>
        <div  class="allTickets">
    <mat-card id="matCardColor" class="ticketMatCard" *ngFor="let ticket of tickets" >
        <div id="popup2{{ticket.compteur}}" >
          <button mat-raised-button (click)="closePopup2(ticket.compteur)" class="buttonClose">X</button>
    
          <div class="nom">
    
          </div>
          <p class="cameraTexte">caméra concernée : {{ticket.camera}} </p>
          <p class="panneTexte">Type de panne :  {{ticket.probleme}}</p>
          <p  class="statutTexte">Statut du ticket : {{ticket.status}}</p>
          <p [ngStyle]=" ticket.ResponsableTimeelapsed=='NA'?{'background-color':'white'} :ticket.ResponsableTimebreach ?{'background-color': 'red'} :{'background-color': 'green'}" class="ResponsableTexte3" class="statutTexte1">Temps de prise en compte : {{ticket.timeOfFirstResponseelapsed}}</p>
          <p class="nomTexte" >Nom du ticket : {{ticket.key}}</p>
          <p  class="ResponsableTexte2">Responsable du ticket : {{ticket.reporter}}</p>
          <p [ngStyle]=" ticket.ResponsableTimeelapsed=='NA'?{'background-color':'white'} :ticket.ResponsableTimebreach ?{'background-color': 'red'} :{'background-color': 'green'}" class="ResponsableTexte3">Temps Ecoulé : {{ticket.ResponsableTimeelapsed}}</p>
          
          <div class="description">
            <p>Description</p>
            <p class="texteDescritpion">{{ticket.description}}</p></div>
          <div class="note">
            <p>Dernière Note</p>
            <p>{{ticket.noteValue}}</p>
            <p>{{ticket.reporter}}</p>
            <p>{{ticket.noteDate}}</p>
          </div>
          
        </div>
    
      <div  (click)="displayDetails2(ticket.compteur)"  id="enableBlur{{ticket.compteur}}" class="champTexte2">
    
        <p class="texte2">{{ticket.camera}}</p>
        <p class="texte2">{{ticket.probleme}}</p>
        <p *ngIf="ticket.reporter == 'snef'" class="texte2" style="background-color : coral">{{ticket.key}}</p>
        <p *ngIf="ticket.reporter == 'sippro'" class="texte2" style="background-color : olive">{{ticket.key}}</p>
        <p *ngIf="ticket.reporter == 'mairie'" class="texte2" style="background-color : teal">{{ticket.key}}</p>
        <p *ngIf="ticket.reporter == 'Non assigné'" class="texte2">{{ticket.key}}</p>
        <p *ngIf="ticket.reporter == 'jean-louis Polley'" class="texte2">{{ticket.key}}</p>
        <p class="texte2">{{ticket.status}}</p>
    
        <p class="texte2">{{ticket.date}} </p>
        <p [ngStyle]=" ticket.ResponsableTimeelapsed=='NA'?{'background-color':'white'} :ticket.ResponsableTimebreach ?{'background-color': 'red'} :{'background-color': 'green'}" class="texte2">{{ticket.ResponsableTimeelapsed}} </p>
        <p class="texte2">{{ticket.noteCreator}} </p>
        
    </div>
    
    </mat-card>
        </div>
    
      </mat-card>
    </div>
    </div>
    
    
    
    <!--<div *ngIf="poste == 'operateur'">
      <div  class="container1">
        <div class="warningText" *ngIf="testif == true && colorText == 'orange'" style="color:orange">⚠ Attention une panne existe deja avec cette caméra ⚠</div>
        <div class="warningText" *ngIf="testif == true && colorText == 'red'" style="color:red">⚠ Attention une panne existe déjà avec cet équipement et ce problème. Création de ticket impossible. Cliquez sur annuler. ⚠</div>
    
        <mat-card id="allTheCard" class="zone-card">
    
          <mat-card-title id="titleWarning" class="title" >Panne active</mat-card-title>
          <div id="idTexte" class="champTexte">
    
            <p class="texte">Equip. concerné</p>
            <p class="texte">Type de panne</p>
            <p class="texte">Clé de la panne</p>
            <p class="texte">Statut panne</p>
    
            <p class="texte">date de création</p>
    
          </div>
          <div *ngIf="problemeServer == 'yes'" class="probleme">
            Impossible de récupérer les données et d'afficher les pannes
            <br/>{{causePanne}}
          </div>
          <div  class="allTickets">
            <mat-card id="matCardColor" class="ticketMatCard" *ngFor="let ticket of tickets" >
              <div id="popup{{ticket.compteur}}" >
                <button mat-raised-button (click)="closePopup(ticket.compteur)" class="buttonClose">X</button>
    
                <div class="nom">
                  <p class="nomTexte" >Nom du ticket : {{ticket.key}}</p>
                </div>
                <p class="cameraTexte">caméra concernée : {{ticket.camera}} </p>
                <p class="panneTexte">Type de panne :  {{ticket.probleme}}</p>
                <p  class="statutTexte">Statut du ticket : {{ticket.status}}</p>
               <p  class="ResponsableTexte">Responsable du ticket : {{ticket.reporter}}</p>
                <div class="description">
                  <p>Description</p>
                  <p class="texteDescritpion">{{ticket.description}}</p></div>
                <div class="note">
                  <p>Dernière Note</p>
                  <p>{{ticket.noteValue}}</p>
                  <p>{{ticket.noteCreator}}</p>
                  <p>{{ticket.noteDate}}</p>
                </div>
    
              </div>
    
              <div  (click)="displayDetails(ticket.compteur)"  id="enableBlur{{ticket.compteur}}" id="enableBlur2{{ticket.compteur}}" class="champTexte3">
    
                <p class="texte3">{{ticket.camera}}</p>
                <p class="texte3">{{ticket.probleme}}</p>-->
                <!--<p *ngIf="ticket.reporter == 'snef'" class="texte3" style="background-color : coral">{{ticket.key}}</p>
                <p *ngIf="ticket.reporter == 'sippro'" class="texte3" style="background-color : olive">{{ticket.key}}</p>
                <p *ngIf="ticket.reporter == 'mairie'" class="texte3" style="background-color : teal">{{ticket.key}}</p>-->
    <!--<p class="texte3">{{ticket.key}}</p>
     *ngIf="ticket.reporter == 'Non assigné'"
                <p class="texte3">{{ticket.status}}</p>
                <p *ngIf="ticket.date.day < 3" class="texte3">{{ticket.date.day}} jours et {{ticket.date.hour}} heures</p>
                <p *ngIf="ticket.date.day > 3 && ticket.date.day < 7" class="texte3" style="background-color : orange">{{ticket.date.day}} jours et {{ticket.date.hour}} heures</p>
                <p *ngIf="ticket.date.day >= 7" class="texte3" style="background-color : red">{{ticket.date.day}} jours et {{ticket.date.hour}} heures</p>
    
              </div>
    
            </mat-card>
          </div>
    
        </mat-card>
      </div>
    </div>
    
    -->
    
    
    
    