
  <app-bandeau-titre-miramas></app-bandeau-titre-miramas>

  <div>
    <app-search-ticket-miramas [poste]="display" (newItemEventCamera)="addCamera($event)" (newItemEventProblem)="addProbleme($event)" (newItemEventInput)="eventInput($event)" (newAnnulerTout)="eventAnnuler($event)" (newCreateTicketEvent)="eventCreateTickets($event)"></app-search-ticket-miramas>
  </div>
  <div class="displayTicket">
    <app-display-ticket-miramas [poste]="display" [item]="responsable" [camera]="camera" [problem]="problem" [LaunchDisplayTicket]="createTicket" (problemeSipproEvent)="getProblemSippro($event)" (problemeBBTPEvent)="getProblemBBTP($event)" (problemeMairieEvent)="getProblemMairie($event)" (problemeTousEvent)="getProblemTous($event)" ></app-display-ticket-miramas>
  </div>
  <app-responsable-miramas (newItemEvent)="addItem($event)" [annulerTOUS]="annulerTout" [allResponsableInput]="allResponsable" [problemTous]="problemTous" [problemSippro]="problemSippro" [problemBBTP]="problemBBTP" [problemMairie]="problemMairie"></app-responsable-miramas>


  <div id="loading-maskTickets">
    <p class="textCreation">Mise a jour des pannes</p>
    <div  class="d-flex justify-content-center">
      <div class="spinner-border" role="status">
        <span  class="sr-only" id="loading"></span>
      </div>
    </div>
  </div>
