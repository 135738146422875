<app-bandeau-titre></app-bandeau-titre>

<div>
  <app-search-ticket
    [poste]="display"
    (newItemEventCamera)="addCamera($event)"
    (newItemEventProblem)="addProbleme($event)"
    (newItemEventStatus)="addStatus($event)"
    (newItemEventPriority)="addPriority($event)"
    (newItemEventInput)="eventInput($event)"
    (newAnnulerTout)="eventAnnuler($event)"
    (newCreateTicketEvent)="eventCreateTickets($event)"
  ></app-search-ticket>
</div>
<div class="displayTicket">
  <app-display-ticket
    [poste]="display"
    [item]="responsable"
    [camera]="camera"
    [problem]="problem"
    [status]="status"
    [LaunchDisplayTicket]="createTicket"
    (problemeSipproEvent)="getProblemSippro($event)"
    (problemeSnefEvent)="getProblemSnef($event)"
    (problemeMairieEvent)="getProblemMairie($event)"
    (problemeTousEvent)="getProblemTous($event)"
  ></app-display-ticket>
</div>

<div id="loading-maskTickets-operator">
  <p class="textCreation">Mise a jour des pannes</p>
  <div class="d-flex justify-content-center">
    <div class="spinner-border" role="status">
      <span class="sr-only" id="loading-operator"></span>
    </div>
  </div>
</div>
