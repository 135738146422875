import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RapportPageComponent } from './Toulouse/rapportPageDirectory/rapport-page/rapport-page.component';
import { MainPageComponent } from './Toulouse/mainPageDirectory/main-page/main-page.component';
import { MainPageComponentOld } from './Toulouse/mainPageDirectory/main-page-old/main-page-old.component';
import { MainPageOperatorComponent } from './Toulouse/mainPageDirectory/main-page-operator/main-page-operator.component';
import { MainPageComponentBlagnac } from './Blagnac/mainPageDirectory/main-page/main-page.component';
import { MainPageComponentBlagnacOld } from './Blagnac/mainPageDirectory/main-page-old/main-page-old.component';
import { MainPageOperatorComponentBlagnac } from './Blagnac/mainPageDirectory/main-page-operator/main-page-operator.component';
import { PageConnexionComponent } from './page-connexion/page-connexion.component';
import { GuardService, GuardServiceAdmin } from './guard.service';
import { RapportPageComponentBlagnac } from './Blagnac/rapportPageDirectory/rapport-page/rapport-page.component';
import { DisplayTicketOldComponent } from './Toulouse/mainPageDirectory/display-ticket-old/display-ticket-old.component';
import { RapportPageComponentMiramas } from './Miramas/rapportPageDirectory/rapport-page/rapport-page.component';
import { MainPageComponentMiramas } from './Miramas/mainPageDirectory/main-page/main-page.component';
import { MainPageOperatorComponentMiramas } from './Miramas/mainPageDirectory/main-page-operator/main-page-operator.component';
import { DisplayTicketOldComponentBlagnac } from './Blagnac/mainPageDirectory/display-ticket-old/display-ticket-old.component';

const routes: Routes = [
 { path: '',   redirectTo: '/pageConnexion', pathMatch: 'full' }, // redirect to
{ path: 'rapportToulouse', component: RapportPageComponent , canActivate: [GuardServiceAdmin] },
{ path: 'pageConnexion', component: PageConnexionComponent},
{ path: 'mainToulouse', component: MainPageComponent, canActivate: [GuardServiceAdmin]},
{ path: 'oldUrl', component: MainPageComponentOld, canActivate: [GuardServiceAdmin]},
{ path: 'mainOperatorToulouse', component: MainPageOperatorComponent, canActivate: [GuardService]},

{ path: 'mainBlagnac', component: MainPageComponentBlagnac, canActivate: [GuardServiceAdmin]},
{ path: 'oldUrlB', component: MainPageComponentBlagnacOld, canActivate: [GuardServiceAdmin]},
{ path: 'mainOperatorBlagnac', component: MainPageOperatorComponentBlagnac, canActivate: [GuardService]},
{ path: 'rapportBlagnac', component: RapportPageComponentBlagnac , canActivate: [GuardServiceAdmin] },

{ path: 'rapportMiramas', component: RapportPageComponentMiramas , canActivate: [GuardServiceAdmin] },
{ path: 'mainMiramas', component: MainPageComponentMiramas, canActivate: [GuardServiceAdmin]},
{ path: 'mainOperatorMiramas', component: MainPageOperatorComponentMiramas, canActivate: [GuardService]},


 ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
