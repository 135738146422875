<div class="selectCamera">
  <div class="searchCamAndProb">
    <div id="popupCreation"><p>Ticket en cours de création</p></div>

    <div *ngIf="problemeServer == 'yes'" class="probleme">
      Impossible de récupérer les données et de créer le ticket, problème de
      connnexion au serveur
      <br />{{ causePanne }}
    </div>
    <div class="champTexte">
      <p class="texte">Equipement</p>
      <p class="texte">Type de panne</p>
      <p class="texte">Statut panne</p>
      <p class="texte">Priorité</p>
      <p class="texteDescription">Description</p>
    </div>
    <div class="menuDéroulant">
      <div class="listeCamera">
        <kendo-autocomplete
          [(ngModel)]="cameraName"
          (ngModelChange)="onChangeCamera($event)"
          (click)="openInput()"
          #autocomplete
          [suggest]="true"
          [data]="listCamera"
          placeholder="ID_camera_TOUL"
        ></kendo-autocomplete>
        <!--<kendo-autocomplete *ngIf="mobile" [readonly]="true" [(ngModel)]="cameraName" (ngModelChange)="onChangeCamera($event)" (click)="openInput()" #autocomplete  [suggest]="true" [data]="listCamera" placeholder="ID_camera_TOUL" ></kendo-autocomplete>-->
      </div>

      <div class="listeProbleme">
        <kendo-autocomplete
          [(ngModel)]="problemName"
          (ngModelChange)="onChangeProblem($event)"
          (click)="openInput2()"
          #autocomplete2
          [suggest]="true"
          [data]="listProbleme"
          placeholder="Cause Panne"
        ></kendo-autocomplete>
      </div>
      <div class="listeProbleme">
        <kendo-autocomplete
          [(ngModel)]="statusName"
          (ngModelChange)="onChangeStatus($event)"
          (click)="openInput3()"
          #autocomplete3
          [suggest]="true"
          [data]="listStatus"
          placeholder="Statut panne"
        ></kendo-autocomplete>
      </div>
      <div class="listeProbleme">
        <kendo-autocomplete
          [(ngModel)]="priorityName"
          (ngModelChange)="onChangePriority($event)"
          (click)="openInput4()"
          #autocomplete4
          [suggest]="true"
          [data]="listPriority"
          placeholder="Priorite"
        ></kendo-autocomplete>
      </div>

      <div class="description">
        <input
          [(ngModel)]="descriptionName"
          class="inputDescription"
          placeholder="Description"
        />
      </div>
    </div>
    <div *ngIf="missData == 'toute'" class="probleme">
      Choississez des valeurs pour la camera et la cause de la panne
    </div>
    <div *ngIf="missData == 'camera'" class="probleme">
      Choississez une valeur pour la caméra en panne
    </div>
    <div *ngIf="missData == 'probleme'" class="probleme">
      Choississez une valeur pour la cause de la panne
    </div>
    <div *ngIf="finalError != ''" class="probleme">
      {{ finalError }}
    </div>
    <div class="SearchButtons">
      <button
        (click)="annulerTicket()"
        (click)="annulerTout()"
        (click)="justeAnnulerErreur()"
        class="buttonTicketAnnuler"
      >
        Annuler
      </button>
      <button id="disabled" (click)="addNewItemCamera()" class="buttonTicket">
        Créer
      </button>
      <button id="disabled" (click)="reloadCurrentPage()" class="buttonTicket2">
        Rafraichir
      </button>
    </div>
  </div>
</div>

<div id="loading-mask2">
  <div class="d-flex justify-content-center">
    <p class="textCreation">creation en cours</p>
    <div class="spinner-border" role="status">
      <span n class="sr-only" id="loading2"></span>
    </div>
  </div>
</div>

<div id="disablingTicket">
  <div class="d-flex justify-content-center">
    <p class="textCreationDisabling"><span></span></p>
  </div>
</div>
